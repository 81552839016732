import React, { useRef, useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Image } from 'components';
import { paymentMethod } from 'utils/illustration';

type Props = {
    isOctopus: boolean;
    isTapAndGo: boolean;
    isAlipay: boolean;
    isWechatPay: boolean;
    isBocPay: boolean;
    isPayme: boolean;
};
export default (props: Props) => {
    const {
        isOctopus,
        isTapAndGo,
        isAlipay,
        isWechatPay,
        isBocPay,
        isPayme,
    } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles({ theme });

    if (
        !isOctopus &&
        !isTapAndGo &&
        !isAlipay &&
        !isWechatPay &&
        !isBocPay &&
        !isPayme
    ) {
        return null;
    }
    return (
        <div className={`columns is-mobile is-variable is-2`}>
            {isOctopus === true && (
                <div className={`column is-narrow`}>
                    <div className={classes.paymentImg}>
                        <Image
                            src={paymentMethod.octopus}
                            height="20"
                            width="20"
                        />
                    </div>
                </div>
            )}
            {isTapAndGo === true && (
                <div className={`column is-narrow`}>
                    <div className={classes.paymentImg}>
                        <Image
                            src={paymentMethod.tapAndGo}
                            height="20"
                            width="20"
                        />
                    </div>
                </div>
            )}
            {isAlipay === true && (
                <div className={`column is-narrow`}>
                    <div className={classes.paymentImg}>
                        <Image
                            src={paymentMethod.alipay}
                            height="20"
                            width="20"
                        />
                    </div>
                </div>
            )}
            {isWechatPay === true && (
                <div className={`column is-narrow`}>
                    <div className={classes.paymentImg}>
                        <Image
                            src={paymentMethod.wechatPay}
                            height="20"
                            width="20"
                        />
                    </div>
                </div>
            )}
            {isBocPay === true && (
                <div className={`column is-narrow`}>
                    <div className={classes.paymentImg}>
                        <Image
                            src={paymentMethod.bocPay}
                            height="20"
                            width="20"
                        />
                    </div>
                </div>
            )}
            {isPayme === true && (
                <div className={`column is-narrow`}>
                    <div className={classes.paymentImg}>
                        <Image
                            src={paymentMethod.payme}
                            height="20"
                            width="20"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const useStyles = createUseStyles((theme) => ({
    paymentImg: {
        width: 20,
        height: 20,
    },
}));
