import React, { FC, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
    LazyLoadComponent,
    LazyLoadImageProps,
} from 'react-lazy-load-image-component';
import _ from 'lodash';
import { ReactSVG } from 'react-svg';

interface Props extends LazyLoadImageProps {
    layout?: 'fixed-height' | 'intrinsic' | 'responsive';
    lazyLoad?: boolean;
    width: string;
    height: string;
    fill?: string;
    stroke?: string;
    enableWebp?: boolean;
}

const useStyles = createUseStyles({
    image: {
        objectFit: 'cover',
    },
    imageContainer: {
        width: (props: Props) => {
            if (props.layout === 'responsive') {
                return '100%';
            }
            return `${props.width}px`;
        },
    },
    fixedImage: {
        height: (props: Props) => {
            if (Number.isNaN(_.toNumber(props.height))) {
                return `${props.height}`;
            }
            return `${props.height}px`;
        },
    },
    svg: {
        width: (props: Props) => `${props.width}px`,
        height: (props: Props) => `${props.height}px`,
        display: 'flex',
        '& .injected-svg': {
            width: (props: Props) => `${props.width}px`,
            height: (props: Props) => `${props.height}px`,
        },
        '@global': {
            path: {
                fill: (props: Props) => {
                    if (props.fill) {
                        return props.fill;
                    }
                    return undefined;
                },
                stroke: (props: Props) => {
                    if (props.stroke) {
                        return props.stroke;
                    }
                    return undefined;
                },
            },
        },
    },
});

const Image: FC<Props> = (props) => {
    const { layout, lazyLoad, src, className, enableWebp } = props;
    const lazyLoadProps = _.omit(props, ['layout', 'lazyLoad', 'enableWebp']);

    // hooks
    const classes = useStyles(props);
    const [loadError, setLoadError] = useState<boolean>(false);

    // UI event function

    // logic function
    const getImageContainerClassName = () => {
        return layout === 'fixed-height'
            ? `${classes.imageContainer} ${classes.fixedImage} loko-img ${
                  className || ''
              }`
            : `${classes.imageContainer} loko-img ${className || ''}`;
    };

    const getImgClassName = () => {
        return layout === 'fixed-height'
            ? `${classes.image} ${classes.fixedImage} loko-img`
            : `${classes.image} loko-img`;
    };

    // render function
    if (
        _.isString(src) &&
        (src as string).includes('.svg') &&
        layout === 'fixed-height'
    ) {
        return (
            <ReactSVG
                src={src}
                className={`${classes.svg} ${
                    className != null ? className : ''
                }`}
                wrapper="div"
            />
        );
    }

    return (
        <LazyLoadComponent {...lazyLoadProps} visibleByDefault={!lazyLoad}>
            <div className={getImageContainerClassName()}>
                {loadError === true || enableWebp === false ? (
                    <img
                        className={getImgClassName()}
                        width="100%"
                        src={`${src}`}
                        alt=""
                    />
                ) : (
                    <picture>
                        <source
                            srcSet={src
                                ?.toLowerCase()
                                .replace(/.png$/, '.webp')}
                            type="image/webp"
                        />
                        <img
                            className={getImgClassName()}
                            width="100%"
                            src={`${src}`}
                            alt=""
                            onError={() => {
                                setLoadError(true);
                            }}
                        />
                    </picture>
                )}
            </div>
        </LazyLoadComponent>
    );
};

Image.defaultProps = {
    layout: 'fixed-height',
    lazyLoad: false,
    enableWebp: false,
};

export default Image;
