import React, { FC, HTMLAttributes } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import {
    MerchantsSnapScroll,
    MerchantsSnapScrollProps,
    Image,
} from 'components';
import { BaseLayout } from 'layouts';
import { CircularProgress, useMediaQuery } from '@mui/material';

export interface Props extends HTMLAttributes<HTMLDivElement> {
    bannerUrl: string;
    subHeader: string;
    merchantsCollection?: MerchantsSnapScrollProps[];
    remarkTitle?: string;
    remark?: string;
}

const useStyles = createUseStyles((theme) => ({
    loadingContainer: {
        paddingTop: theme.spacing.spacing128,
        paddingBottom: theme.spacing.spacing128,
    },
    subHeaderContainer: {
        marginTop: theme.spacing.spacing32,
        marginBottom: theme.spacing.spacing32,
        [`${theme.mediaQueries.mobile}`]: {
            marginLeft: theme.spacing.spacing16,
            marginRight: theme.spacing.spacing16,
        },
        '& p': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: theme.fontSize.size16,
            lineHeight: theme.lineHeight.size24,
            whiteSpace: 'pre-wrap',
            color: theme.color.textPrimary,
        },
    },
    sectionContainer: {
        marginBottom: theme.spacing.spacing48,
        [`${theme.mediaQueries.mobile}`]: {
            marginBottom: theme.spacing.spacing40,
        },
    },
    remarkContainer: {
        marginTop: theme.spacing.spacing32,
        marginBottom: theme.spacing.spacing72,
        [`${theme.mediaQueries.mobile}`]: {
            marginLeft: theme.spacing.spacing16,
            marginRight: theme.spacing.spacing16,
        },
        '& p': {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: theme.fontSize.size12,
            lineHeight: theme.lineHeight.size16,
            color: theme.color.textPrimaryOp1,
            whiteSpace: 'pre-wrap',
        },
    },
}));

const MerchantsCollection: FC<Props> = (props) => {
    const {
        bannerUrl,
        subHeader,
        merchantsCollection,
        remarkTitle,
        remark,
    } = props;
    // hooks

    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });
    const isMobile = useMediaQuery(
        `(max-width:${theme.breakpoints.tablet - 1}px)`,
    );

    // UI event function

    // logic function

    // render function
    if (merchantsCollection == null || merchantsCollection.length === 0) {
        return (
            <BaseLayout>
                <div
                    className={`loko-container is-loading has-text-centered ${classes.loadingContainer}`}
                >
                    <CircularProgress />
                </div>
            </BaseLayout>
        );
    }

    return (
        <BaseLayout>
            <div className="loko-container">
                <Image
                    width="100%"
                    height={isMobile ? '200' : '400'}
                    layout="fixed-height"
                    lazyLoad
                    src={bannerUrl}
                />
                <div className={classes.subHeaderContainer}>
                    <p>{subHeader}</p>
                </div>
                {merchantsCollection.map((merchants) => {
                    if (merchants.merchants.length === 0) {
                        return null;
                    }
                    return (
                        <MerchantsSnapScroll
                            {...merchants}
                            key={`merchants-snap-scroll-${merchants.sectionTitle}`}
                            className={classes.sectionContainer}
                        />
                    );
                })}
                {remarkTitle && remark ? (
                    <div className={classes.remarkContainer}>
                        <p>{remarkTitle}</p>
                        <p>&nbsp;</p>
                        <p>{remark}</p>
                    </div>
                ) : null}
            </div>
        </BaseLayout>
    );
};

export default MerchantsCollection;
