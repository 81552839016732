import React, { FC, useState, useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Image,
    LocaleLink,
    DrawerCategoryBtn,
    SocialPlatformBtn,
    JoinUs,
} from 'components';
import * as _ from 'lodash';
import type { CategoryIndex, LocationIndex } from 'apis/merchant-algolia.d';
import { generateUrl, logo } from 'utils/i18n';
import urlEncode from 'utils/url-encode';
import assetsPath from 'utils/assets-path';

type Props = {
    isLogined: boolean;
    onClickDismiss?: React.MouseEventHandler<HTMLAnchorElement>;
    categories: CategoryIndex[];
    locations: LocationIndex[];
    onSignInClicked?: React.MouseEventHandler<HTMLAnchorElement>;
    onSignOutOutClicked?: React.MouseEventHandler<HTMLAnchorElement>;
    onRegisterClicked?: React.MouseEventHandler<HTMLAnchorElement>;
};

type Menu = CategoryIndex | LocationIndex;

type State = {
    currentPage: 'main' | 'sec';
    selectedMenu?: Menu | null;
    prevMenu?: Menu | null;
};

const useStyles = createUseStyles((theme) => ({
    menuContainer: {
        position: 'relative',
    },
    headerContainer: {
        height: 56,
        width: '100%',
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'sticky',
        left: 0,
        top: 0,
        zIndex: 30,
        backgroundColor: theme.color.bgSecondary,
    },
    dismissBtn: {
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    catItemContainer: {
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing16,
        marginBottom: theme.spacing.spacing32,
        '& $catItem': {
            marginBottom: theme.spacing.spacing8,
            '&:last-child': {
                marginBottom: 0,
            },
        },
    },
    catItem: {},
    separateLine: {
        height: 1,
        marginLeft: theme.spacing.spacing16,
        marginRight: theme.spacing.spacing16,
        backgroundColor: theme.color.borderPrimaryOp4,
    },
    accountContainer: {
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing16,
        marginTop: theme.spacing.spacing24,
        marginBottom: theme.spacing.spacing24,
        display: 'flex',
        flexDirection: 'column',
    },
    loginBtnContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing.spacing16,
    },
    loginBtn: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        textDecoration: 'underline',
        color: theme.color.textPrimary,
        marginRight: theme.spacing.spacing16,
    },
    menuLink: {
        fontSize: theme.fontSize.size18,
        lineHeight: theme.lineHeight.size32,
        color: theme.color.textPrimary,
        marginBottom: theme.spacing.spacing16,
    },
    menuLinkLast: {
        extend: 'menuLink',
        marginBottom: 0,
    },
    aboutUsContainer: {
        extend: 'accountContainer',
    },
    socialContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing.spacing32,
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing16,
    },
    joinUsContainer: {
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing16,
        marginTop: theme.spacing.spacing24,
        marginBottom: theme.spacing.spacing24,
        display: 'flex',
        flexDirection: 'column',
    },
    privacyContainer: {
        marginTop: theme.spacing.spacing24,
        marginBottom: theme.spacing.spacing24,
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing16,
        display: 'flex',
        flexDirection: 'row',
    },
    privacyBtn: {
        extend: 'loginBtn',
    },
    socialBtn: {
        marginRight: theme.spacing.spacing16,
    },
}));

const useSecLvStyles = createUseStyles((theme) => ({
    headerContainer: {
        height: 56,
        width: '100%',
        paddingLeft: theme.spacing.spacing8,
        paddingRight: theme.spacing.spacing8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'sticky',
        left: 0,
        top: 0,
        zIndex: 30,
        backgroundColor: theme.color.bgSecondary,
    },
    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.bold,
        color: theme.color.textPrimary,
        flex: 1.0,
        marginLeft: theme.spacing.spacing8,
    },
    backBtn: {
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const DrawerMenu: FC<Props> = (props) => {
    const {
        onClickDismiss,
        categories,
        locations,
        onRegisterClicked,
        onSignInClicked,
        onSignOutOutClicked,
        isLogined,
    } = props;
    // hooks
    const theme = useTheme();
    const classes = useStyles({ theme });
    const navigation = useNavigate();
    const secLvClasses = useSecLvStyles({ theme });
    const { t } = useTranslation();

    const TopLocation: LocationIndex = {
        type: 'area',
        name: t('common.location'),
        parent: undefined,
        objectID: 'all-location',
        keywords: [],
        icon: 'location',
    };

    const [state, setState] = useState<State>({
        currentPage: 'main',
        selectedMenu: null,
    });

    const [subMenus, setSubMenus] = useState<Menu[]>([]);

    const [topLevelCategories, setTopLevelCategories] = React.useState<
        CategoryIndex[]
    >([]);

    // UI event function
    const onClickBackBtn = () => {
        const currentMenu = state.selectedMenu;
        if (!currentMenu) {
            return;
        }
        if (
            currentMenu.objectID === TopLocation.objectID ||
            currentMenu.type === 'categories_1st'
        ) {
            //Go back top level
            setState((prevState) => {
                return {
                    ...prevState,
                    currentPage: 'main',
                    selectedMenu: null,
                };
            });
        } else {
            setState((prevState) => {
                return {
                    ...prevState,
                    currentPage: 'sec',
                    selectedMenu: _.clone(state.prevMenu),
                    prevMenu: null,
                };
            });
        }
    };

    const onMenuBtnClicked = (menu: Menu) => {
        const prevMenu = _.clone(state.selectedMenu);
        setState((prevState) => {
            return {
                ...prevState,
                selectedMenu: menu,
                currentPage: 'sec',
                prevMenu,
            };
        });
    };

    useEffect(() => {
        if (!state.selectedMenu) {
            return;
        }
        let newSubMenus: Menu[] = [];
        const isLoctionMenu = ['area', 'district'].includes(
            state.selectedMenu.type,
        );
        if (state.selectedMenu.objectID === TopLocation.objectID) {
            newSubMenus = _.filter(locations, (c) => c.type === 'area');
        } else if (isLoctionMenu) {
            newSubMenus = _.filter(
                locations,
                (c) => c.parent === state.selectedMenu?.objectID,
            );
        } else {
            newSubMenus = _.filter(
                categories,
                (c) => c.parent === state.selectedMenu?.objectID,
            );
        }
        if (_.isEmpty(newSubMenus)) {
            if (isLoctionMenu && state.selectedMenu.parent != null) {
                navigation(
                    generateUrl(
                        `district/${urlEncode(
                            state.selectedMenu.parent,
                        )}/${urlEncode(state.selectedMenu.objectID)}`,
                    ),
                );
            } else {
                navigation(
                    generateUrl(
                        `${urlEncode(
                            `${state.selectedMenu.parent}`,
                        )}/${urlEncode(state.selectedMenu.objectID)}`,
                    ),
                );
            }
        } else {
            setSubMenus(newSubMenus);
        }
    }, [state.selectedMenu, categories]);

    useEffect(() => {
        setTopLevelCategories(
            _.filter(categories, (c) => c.type === 'categories_1st'),
        );
    }, [categories]);

    // logic function

    // render function

    return (
        <div className={classes.menuContainer}>
            {state.currentPage === 'main' ? (
                <>
                    <div className={`${classes.headerContainer}`}>
                        {logo()}
                        <a
                            className={classes.dismissBtn}
                            role="button"
                            tabIndex={0}
                            onClick={onClickDismiss}
                        >
                            <Image
                                width="24"
                                height="24"
                                src={assetsPath('cross')}
                            />
                        </a>
                    </div>
                    <div className={classes.catItemContainer}>
                        {topLevelCategories.map((topLevelCategory) => (
                            <DrawerCategoryBtn
                                key={`drawer-menu-${topLevelCategory.objectID}`}
                                className={classes.catItem}
                                iconSrc={
                                    topLevelCategory.icon
                                        ? assetsPath(topLevelCategory.icon)
                                        : null
                                }
                                categoryTitle={topLevelCategory.name}
                                to={''}
                                onClick={() => {
                                    onMenuBtnClicked(topLevelCategory);
                                    return false;
                                }}
                            />
                        ))}
                        <DrawerCategoryBtn
                            key={`drawer-menu-all-location`}
                            className={classes.catItem}
                            iconSrc={assetsPath('location')}
                            categoryTitle={t('common.location')}
                            to={''}
                            onClick={() => {
                                onMenuBtnClicked(TopLocation);
                                return false;
                            }}
                        />
                    </div>
                    <div className={classes.separateLine} />
                    <div className={classes.accountContainer}>
                        <div className={classes.loginBtnContainer}>
                            {!isLogined && (
                                <LocaleLink
                                    to={'#'}
                                    onClick={onRegisterClicked}
                                    className={classes.loginBtn}
                                >
                                    {t('mobile-drawer.register')}
                                </LocaleLink>
                            )}

                            {!isLogined && (
                                <LocaleLink
                                    to={'#'}
                                    onClick={onSignInClicked}
                                    className={classes.loginBtn}
                                >
                                    {t('mobile-drawer.login')}
                                </LocaleLink>
                            )}
                            {isLogined && (
                                <LocaleLink
                                    to={'#'}
                                    onClick={onSignOutOutClicked}
                                    className={classes.loginBtn}
                                >
                                    {t('mobile-drawer.sign-out')}
                                </LocaleLink>
                            )}
                        </div>
                        <LocaleLink
                            to={'/profile'}
                            className={classes.menuLink}
                        >
                            {t('footer.my-ac')}
                        </LocaleLink>
                        <LocaleLink
                            to={'/fav-merchants'}
                            className={classes.menuLinkLast}
                        >
                            {t('footer.my-fav')}
                        </LocaleLink>
                    </div>
                    <div className={classes.separateLine} />
                    <div className={classes.aboutUsContainer}>
                        <LocaleLink
                            to={'/about-us'}
                            className={classes.menuLink}
                        >
                            {t('footer.about-us')}
                        </LocaleLink>
                        <LocaleLink
                            to={'/standard'}
                            className={classes.menuLink}
                        >
                            {t('footer.rules')}
                        </LocaleLink>
                        {/* <LocaleLink to={'#'} className={classes.menuLinkLast}>
                            {t('footer.event')}
                        </LocaleLink> */}
                    </div>
                    <div className={classes.socialContainer}>
                        <SocialPlatformBtn
                            className={classes.socialBtn}
                            btnSize="big"
                            socialPlatform="fb"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.facebook.com/loko.hk"
                        />
                        <SocialPlatformBtn
                            className={classes.socialBtn}
                            btnSize="big"
                            socialPlatform="ig"
                            target="_blank"
                            rel="noreferrer"
                            href="https://www.instagram.com/loko.hk/"
                        />
                        <SocialPlatformBtn
                            className={classes.socialBtn}
                            btnSize="big"
                            socialPlatform="wtsapp"
                            target="_blank"
                            rel="noreferrer"
                            href="https://wa.me/85264507502"
                        />
                    </div>
                    <div className={classes.separateLine} />
                    <div className={classes.joinUsContainer}>
                        <JoinUs isDrawer />
                    </div>
                    <div className={classes.separateLine} />
                    <div className={classes.privacyContainer}>
                        <LocaleLink to={'tnc'} className={classes.privacyBtn}>
                            {t('footer.law')}
                        </LocaleLink>
                        <LocaleLink
                            to={'privacy-policy'}
                            className={classes.privacyBtn}
                        >
                            {t('footer.privacy')}
                        </LocaleLink>
                    </div>
                </>
            ) : (
                <>
                    <div className={secLvClasses.headerContainer}>
                        <a
                            className={secLvClasses.backBtn}
                            onClick={onClickBackBtn}
                            role="button"
                            tabIndex={0}
                        >
                            <Image
                                width="24"
                                height="24"
                                src={assetsPath('back')}
                            />
                        </a>
                        <p className={secLvClasses.title}>
                            {state.selectedMenu?.name}
                        </p>
                        <a
                            className={classes.dismissBtn}
                            role="button"
                            tabIndex={0}
                            onClick={onClickDismiss}
                        >
                            <Image
                                width="24"
                                height="24"
                                src={assetsPath('cross')}
                            />
                        </a>
                    </div>
                    <div className={classes.catItemContainer}>
                        {subMenus.map((sc) => {
                            return (
                                <DrawerCategoryBtn
                                    key={`drawer-menu-${sc.objectID}`}
                                    className={classes.catItem}
                                    iconSrc={sc.icon ? assetsPath(sc.icon) : ''}
                                    categoryTitle={sc.name}
                                    to={'#'}
                                    onClick={() => {
                                        onMenuBtnClicked(sc);
                                        return false;
                                    }}
                                />
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};

export default DrawerMenu;
