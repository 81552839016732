import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Image } from 'components';
import assetsPath from 'utils/assets-path';

type Props = {
    title: string;
    onClickRemoveBtn?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
};

const useStyles = createUseStyles((theme) => ({
    tagContainer: {
        paddingLeft: theme.spacing.spacing8,
        paddingRight: theme.spacing.spacing4,
        paddingTop: theme.spacing.spacing4,
        paddingBottom: theme.spacing.spacing4,
        background: theme.color.btnSecondaryOp4,
        borderRadius: theme.borderRadius.size8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        color: theme.color.textPrimary,
        marginRight: theme.spacing.spacing4,
    },
}));

const FilterTag: FC<Props> = (props) => {
    // hooks
    const { title, onClickRemoveBtn } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });

    // UI event function

    // logic function

    // render function

    return (
        <div className={classes.tagContainer}>
            <p className={classes.title}>{title}</p>
            <a onClick={onClickRemoveBtn} role="button" tabIndex={0}>
                <Image src={assetsPath('cross-small')} width="24" height="24" />
            </a>
        </div>
    );
};

export default FilterTag;
