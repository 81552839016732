import * as _ from 'lodash';

const KEY = 'recent-searches';
const MAX_SEARCHES = 10;
export const getRecentSearches: () => string[] = () => {
    const s = window.localStorage.getItem(KEY);
    try {
        return JSON.parse(s || '[]');
    } catch (e) {
        console.warn('load recent search error');
    }
    return [];
};

export const addRecentSearchers: (newSearch: string) => string[] = (
    newSearch: string,
) => {
    const existing = getRecentSearches();
    const newSearches = _.chain(existing)
        .concat(newSearch)
        .uniq()
        .reverse()
        .take(MAX_SEARCHES)
        .value();
    window.localStorage.setItem(KEY, JSON.stringify(newSearches));
    return newSearches;
};
