import React, { useState, useEffect, useCallback, FC } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { Trans, useTranslation } from 'react-i18next';
import { Standards } from 'utils/illustration';
import * as _ from 'lodash';
import { Image, StaticContent } from 'components';
import assetsPath from 'utils/assets-path';
import ReactHtmlParser from 'react-html-parser';
import LogoSvg from 'assets/ups-standard/logo.svg';
import Character1Svg from 'assets/ups-standard/character-1.svg';
import Character2Svg from 'assets/ups-standard/character-2.svg';
import Character3Svg from 'assets/ups-standard/character-3.svg';
import System1Img from 'assets/ups-standard/system-1.webp';
import System2Img from 'assets/ups-standard/system-2.webp';

const useStyles = createUseStyles((theme) => ({
    container: {
        maxWidth: 800,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.spacing80,
        [`${theme.mediaQueries.mobile}`]: {
            paddingLeft: theme.spacing.spacing56,
            paddingRight: theme.spacing.spacing56,
        },
    },
    title: {
        textAlign: 'center',
        '& .title': {
            marginTop: theme.spacing.spacing24,
            marginBottom: theme.spacing.spacing80,
            fontWeight: theme.fontWeight.normal,
            fontSize: theme.fontSize.size16,
            lineHeight: theme.lineHeight.size24,
            color: theme.color.textPrimary,
        },
    },
    character: {
        marginBottom: theme.spacing.spacing80,
        '& .header': {
            textAlign: 'center',
            [`${theme.mediaQueries.mobile}`]: {
                textAlign: 'left',
            },
            fontWeight: theme.fontWeight.bold,
            fontSize: 48,
            lineHeight: theme.lineHeight.size56,
            color: theme.color.textPrimary,
            marginBottom: theme.spacing.spacing32,
        },
        '& .column': {
            textAlign: 'center',
            [`${theme.mediaQueries.mobile}`]: {
                textAlign: 'left',
            },
            '& .title': {
                marginTop: theme.spacing.spacing16,
                fontWeight: theme.fontWeight.bold,
                fontSize: theme.fontSize.size24,
                lineHeight: theme.lineHeight.size32,
                color: theme.color.textPrimary,
            },
            '& .description': {
                marginTop: theme.spacing.spacing8,
                fontWeight: theme.fontWeight.normal,
                fontSize: theme.fontSize.size16,
                lineHeight: theme.lineHeight.size24,
                color: theme.color.textPrimary,
            },
        },
        '& .image': {
            [`${theme.mediaQueries.desktop}`]: {
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
    },
    verification: {
        '& .header': {
            textAlign: 'center',
            [`${theme.mediaQueries.mobile}`]: {
                marginBottom: theme.spacing.spacing32,
                textAlign: 'left',
                fontSize: theme.fontSize.size40,
            },
            fontWeight: theme.fontWeight.bold,
            fontSize: 48,
            lineHeight: theme.lineHeight.size56,
            color: theme.color.textPrimary,
            marginBottom: theme.spacing.spacing64,
        },
        '& .column': {
            '& .title': {
                fontWeight: theme.fontWeight.bold,
                fontSize: theme.fontSize.size24,
                lineHeight: theme.lineHeight.size32,
                color: theme.color.textPrimary,
            },
            '& .description': {
                marginTop: theme.spacing.spacing8,
                fontWeight: theme.fontWeight.normal,
                fontSize: theme.fontSize.size16,
                lineHeight: theme.lineHeight.size24,
                color: theme.color.textPrimary,
            },
        },
    },
    standard: {
        marginTop: theme.spacing.spacing80,
        '& .header': {
            textAlign: 'center',
            [`${theme.mediaQueries.mobile}`]: {
                textAlign: 'left',
            },
            marginBottom: theme.spacing.spacing32,
            '& .description': {
                marginTop: theme.spacing.spacing16,
            },
        },
        '& .title': {
            [`${theme.mediaQueries.mobile}`]: {
                fontSize: theme.fontSize.size40,
            },
            fontWeight: theme.fontWeight.bold,
            fontSize: 48,
            lineHeight: theme.lineHeight.size56,
            color: theme.color.textPrimary,
        },
        '& .description': {
            fontWeight: theme.fontWeight.normal,
            fontSize: theme.fontSize.size16,
            lineHeight: theme.lineHeight.size24,
            color: theme.color.textPrimary,
        },
        '& .subtitle': {
            fontWeight: theme.fontWeight.bold,
            fontSize: theme.fontSize.size24,
            lineHeight: theme.lineHeight.size32,
            color: theme.color.textPrimary,
            marginBottom: theme.spacing.spacing16,
        },
        '& .fab-section': {
            marginTop: theme.spacing.spacing32,
        },
        '& .product-section,.fab-section': {
            textAlign: 'center',
            '& .name': {
                fontWeight: theme.fontWeight.bold,
                fontSize: theme.fontSize.size16,
                lineHeight: theme.lineHeight.size24,
                color: theme.color.textPrimary,
            },
            '& .column': {
                textAlign: 'center',
                '& .background-container': {
                    backgroundColor: 'rgba(44, 45, 37, 0.05)',
                    borderRadius: theme.borderRadius.size8,
                    margin: theme.spacing.spacing4,
                    paddingTop: theme.spacing.spacing16,
                    paddingBottom: theme.spacing.spacing16,
                },
            },
        },
    },

    remark: {
        marginTop: theme.spacing.spacing80,
        marginBottom: theme.spacing.spacing80,
        fontSize: theme.fontSize.size12,
        lineHeight: theme.lineHeight.size16,
        color: theme.color.textPrimaryOp1,
        '& p': {
            marginBottom: theme.spacing.spacing8,
        },
    },
}));

type Rule = { icon: string; ruleTitle: string };
const UpsStandard = () => {
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });
    const { t } = useTranslation();
    const [rules1, setRules1] = useState<Rule[]>([]);
    const [rules2, setRules2] = useState<Rule[]>([]);

    useEffect(() => {
        setRules1([
            {
                icon: Standards.company,
                ruleTitle: t('rules.rules1.rule1'),
            },
            {
                icon: Standards.design,
                ruleTitle: t('rules.rules1.rule2'),
            },
            {
                icon: Standards.made,
                ruleTitle: t('rules.rules1.rule3'),
            },
            {
                icon: Standards.source,
                ruleTitle: t('rules.rules1.rule4'),
            },
        ]);

        setRules2([
            {
                icon: Standards.feature,
                ruleTitle: t('rules.rules2.rule1'),
            },
            {
                icon: Standards.conscience,
                ruleTitle: t('rules.rules2.rule2'),
            },
            {
                icon: Standards.kindness,
                ruleTitle: t('rules.rules2.rule3'),
            },
            {
                icon: Standards.brand,
                ruleTitle: t('rules.rules2.rule4'),
            },
            {
                icon: Standards.environment,
                ruleTitle: t('rules.rules2.rule5'),
            },
            {
                icon: Standards.ingredient,
                ruleTitle: t('rules.rules2.rule6'),
            },
        ]);
    }, [t]);

    // data for standard

    return (
        <div className={`${classes.container}   `}>
            <div className={classes.title}>
                <div
                    className={`logo is-flex is-justify-content-center is-align-items-center`}
                >
                    <Image src={LogoSvg} width="200" height="75" />
                </div>

                <div className="title">
                    <p>{t('ups-standard.title')}</p>
                </div>
            </div>
            <div className={`${classes.character}`}>
                <div className="header">
                    <p>{t('ups-standard.character.header')}</p>
                </div>
                <div
                    className={`is-flex is-justify-content-center is-align-items-flex-start is-flex-wrap-wrap columns`}
                >
                    <div className="column is-one-third-desktop is-full-mobile">
                        <Image
                            className="image"
                            src={Character1Svg}
                            width="160"
                            height="200"
                        />
                        <p className="title">
                            {t('ups-standard.character.character1.title')}
                        </p>
                        <p className="description">
                            {t('ups-standard.character.character1.description')}
                        </p>
                    </div>
                    <div className="column is-one-third-desktop is-full-mobile">
                        <Image
                            className="image"
                            src={Character2Svg}
                            width="160"
                            height="200"
                        />
                        <p className="title">
                            {t('ups-standard.character.character2.title')}
                        </p>
                        <p className="description">
                            {t('ups-standard.character.character2.description')}
                        </p>
                    </div>
                    <div className="column is-one-third-desktop is-full-mobile">
                        <Image
                            className="image"
                            src={Character3Svg}
                            width="160"
                            height="200"
                        />
                        <p className="title">
                            {t('ups-standard.character.character3.title')}
                        </p>
                        <p className="description">
                            {t('ups-standard.character.character3.description')}
                        </p>
                    </div>
                </div>
            </div>

            <div className={`${classes.verification}`}>
                <div className="header">
                    <p>{t('ups-standard.verification-system.header')}</p>
                </div>
                <div
                    className={`is-flex is-justify-content-center is-align-items-flex-start
                    is-flex-wrap-wrap columns`}
                >
                    <div className="column is-5-desktop is-full-mobile first">
                        <p className="title">
                            {t(
                                'ups-standard.verification-system.system1.title',
                            )}
                        </p>
                        <p className="description">
                            {ReactHtmlParser(
                                t(
                                    'ups-standard.verification-system.system1.content',
                                ),
                            )}
                        </p>
                    </div>
                    <div className="column is-full-mobile   ">
                        <Image
                            lazyLoad
                            src={System1Img}
                            width="232"
                            height="168"
                        />
                        <p className="description">
                            {t('ups-standard.verification-system.system2')}
                        </p>
                    </div>
                    <div className="column is-full-mobile is-3-desktop ">
                        <Image
                            lazyLoad
                            src={System2Img}
                            width="160"
                            height="168"
                        />
                        <p className="description">
                            {t('ups-standard.verification-system.system3')}
                        </p>
                    </div>
                </div>
            </div>

            <div className={`${classes.standard}`}>
                <div className="header">
                    <p className="title">{t('ups-standard.standard.header')}</p>
                    <p className="description">
                        {t('ups-standard.standard.description')}
                    </p>
                </div>

                <div className="product-section">
                    <p className="subtitle">
                        {t('ups-standard.standard.product')}
                    </p>
                    <div
                        className={`columns is-gapless is-flex is-justify-content-center 
                        is-flex-wrap-wrap is-align-items-flex-start`}
                    >
                        {rules1.map((rule) => {
                            return (
                                <div
                                    key={`ups-standard-rules1-${rule.ruleTitle}`}
                                    className="rule column is-one-quarter-desktop is-half-mobile"
                                >
                                    <div className="background-container">
                                        <Image
                                            src={rule.icon}
                                            width="80"
                                            height="80"
                                            lazyLoad
                                        />
                                        <p className="name">{rule.ruleTitle}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="fab-section">
                    <p className="subtitle">{t('ups-standard.standard.fab')}</p>
                    <div
                        className={`is-gapless is-flex is-justify-content-center
                         is-align-items-flex-start is-flex-wrap-wrap columns`}
                    >
                        {rules2.map((rule) => {
                            return (
                                <div
                                    key={`ups-standard-rules1-${rule.ruleTitle}`}
                                    className="rule is-one-third-desktop is-half-mobile column"
                                >
                                    <div className="background-container">
                                        <Image
                                            lazyLoad
                                            src={rule.icon}
                                            width="80"
                                            height="80"
                                        />
                                        <p className="name">{rule.ruleTitle}</p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

            <div className={classes.remark}>
                <p>{t('rules.remark1')}</p>
                <p>{t('rules.remark2')}</p>
                <p>{t('ups-standard.remark')}</p>
            </div>
        </div>
    );
};

export default UpsStandard;
