import { illustrationPath } from 'utils/assets-path';

export const logo = {
    logoEn: illustrationPath('logo-icon-en.png'),
    logoZh: illustrationPath('logo-icon-zh.png'),
};

export const Standards = {
    company: illustrationPath('standard-company.png'),
    design: illustrationPath('standard-design.png'),
    made: illustrationPath('standard-made.png'),
    source: illustrationPath('standard-source.png'),
    feature: illustrationPath('standard-feature.png'),
    kindness: illustrationPath('standard-kindness.png'),
    conscience: illustrationPath('standard-conscience.png'),
    brand: illustrationPath('standard-brand.png'),
    environment: illustrationPath('standard-environment.png'),
    ingredient: illustrationPath('standard-ingredient.png'),
};

export const JoinUs = illustrationPath('merchant-join.png');

export const Perks = {
    publicity: illustrationPath('merchant-perk-publicity.png'),
    collaboration: illustrationPath('merchant-perk-collaboration.png'),
    community: illustrationPath('merchant-perk-community.png'),
};

export const badge = {
    sourceOn: illustrationPath('standard-source-on.png'),
    madeOn: illustrationPath('standard-made-on.png'),
    kindnessOn: illustrationPath('standard-kindness-on.png'),
    ingredientOn: illustrationPath('standard-ingredient-on.png'),
    featureOn: illustrationPath('standard-feature-on.png'),
    environmentOn: illustrationPath('standard-environment-on.png'),
    designOn: illustrationPath('standard-design-on.png'),
    conscienceOn: illustrationPath('standard-conscience-on.png'),
    companyOn: illustrationPath('standard-company-on.png'),
    brandOn: illustrationPath('standard-brand-on.png'),
    sourceOff: illustrationPath('standard-source-off.png'),
    madeOff: illustrationPath('standard-made-off.png'),
    kindnessOff: illustrationPath('standard-kindness-off.png'),
    ingredientOff: illustrationPath('standard-ingredient-off.png'),
    featureOff: illustrationPath('standard-feature-off.png'),
    environmentOff: illustrationPath('standard-environment-off.png'),
    designOff: illustrationPath('standard-design-off.png'),
    conscienceOff: illustrationPath('standard-conscience-off.png'),
    companyOff: illustrationPath('standard-company-off.png'),
    brandOff: illustrationPath('standard-brand-off.png'),
};

export const notFound = {
    notFound: illustrationPath('404.png'),
};

export const paymentMethod = {
    octopus: illustrationPath('payment-octopus.png'),
    tapAndGo: illustrationPath('payment-tapgo.png'),
    alipay: illustrationPath('payment-alipay.png'),
    wechatPay: illustrationPath('payment-wechat.png'),
    bocPay: illustrationPath('payment-bocpay.png'),
    payme: illustrationPath('payment-payme.png'),
};

export const merchantRating = {
    ig: illustrationPath('rating-instagram.png'),
    openrice: illustrationPath('rating-openrice.png'),
};
