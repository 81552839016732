import React, { FC, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Image } from 'components';
import assetsPath from 'utils/assets-path';
import _ from 'lodash';

interface Props extends React.HTMLProps<HTMLAnchorElement> {
    socialPlatform:
        | 'fb'
        | 'ig'
        | 'wtsapp'
        | 'mewe'
        | 'pinkoi'
        | 'signal'
        | 'openrice';
    btnSize: 'big' | 'small';
    alwaysSelected?: boolean;
}

const useStyles = createUseStyles((theme) => ({
    socialBtn: {
        width: (props: Props) => (props.btnSize === 'big' ? 56 : 40),
        height: (props: Props) => (props.btnSize === 'big' ? 56 : 40),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 80,
        background: theme.color.btnSecondaryOp4,
    },
    fbBtn: {
        background: '#3B5998',
    },
    igBtn: {
        background:
            // eslint-disable-next-line max-len
            'radial-gradient(105% 105% at 22.5% 105%, #FFDC80 0%, #FCAF45 17.38%, #F77737 28.65%, rgba(245, 96, 64, 0.8) 36.89%, rgba(253, 29, 29, 0.5) 46.71%, rgba(253, 29, 29, 0) 68.94%), linear-gradient(169.38deg, #405DE6 3.16%, #5851DB 8.72%, #833AB4 22.56%, #C13584 54.32%, #E1306C 83.84%)',
    },
    wtsappBtn: {
        background: '#25D366',
    },
    meweBtn: {
        background: '#25D366',
    },
    signalBtn: {
        background: '#3A76F0',
    },
    pinkoiBtn: {
        background: '#DD847E',
    },
    openriceBtn: {
        background: '#4E3C2E',
    },
}));

const Component: FC<Props> = (props) => {
    const { socialPlatform, className, btnSize, alwaysSelected } = props;

    // hooks
    const theme = useTheme();
    const classes = useStyles({ theme, ...props });
    const [isSelected, setIsSelected] = useState<boolean>(false);

    // UI event function
    const onMouseEnter = () => {
        setIsSelected(true);
    };

    const onMouseLeave = () => {
        setIsSelected(false);
    };

    // logic function

    // render function

    let cn;
    let asset;
    if (socialPlatform === 'fb') {
        cn =
            isSelected === true || alwaysSelected === true
                ? `${classes.socialBtn} ${classes.fbBtn}`
                : classes.socialBtn;
        asset = 'facebook';
    } else if (socialPlatform === 'ig') {
        cn =
            isSelected === true || alwaysSelected === true
                ? `${classes.socialBtn} ${classes.igBtn}`
                : classes.socialBtn;
        asset = 'instagram';
    } else if (socialPlatform === 'wtsapp') {
        cn =
            isSelected === true || alwaysSelected === true
                ? `${classes.socialBtn} ${classes.wtsappBtn}`
                : classes.socialBtn;
        asset = 'whatsapp';
    } else if (socialPlatform === 'mewe') {
        cn =
            isSelected === true || alwaysSelected === true
                ? `${classes.socialBtn} ${classes.meweBtn}`
                : classes.socialBtn;
        asset = 'mewe';
    } else if (socialPlatform === 'pinkoi') {
        cn =
            isSelected === true || alwaysSelected === true
                ? `${classes.socialBtn} ${classes.pinkoiBtn}`
                : classes.socialBtn;
        asset = 'pinkoi';
    } else if (socialPlatform === 'openrice') {
        cn =
            isSelected === true || alwaysSelected === true
                ? `${classes.socialBtn} ${classes.openriceBtn}`
                : classes.socialBtn;
        asset = 'openrice-color';
    } else {
        cn =
            isSelected === true || alwaysSelected === true
                ? `${classes.socialBtn} ${classes.signalBtn}`
                : classes.socialBtn;
        asset = 'signal';
    }

    let fill = 'undefined';
    if (socialPlatform !== 'openrice') {
        if (isSelected === true || alwaysSelected === true) {
            fill = 'white';
        } else {
            fill = 'black';
        }
    }

    return (
        <a
            {..._.omit(props, ['socialPlatform', 'btnSize', 'alwaysSelected'])}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={`${cn} ${className != null ? className : ''}`}
        >
            <Image
                width={btnSize === 'big' ? '32' : '24'}
                height={btnSize === 'big' ? '32' : '24'}
                fill={fill}
                src={assetsPath(asset)}
            />
        </a>
    );
};

export default Component;
