import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Image, LocaleLink } from 'components';
import type { CategoryIndex } from 'apis/merchant-algolia.d';
import assetsPath from 'utils/assets-path';

const useStyles = createUseStyles((theme) => ({
    container: {
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing16,
        marginBottom: theme.spacing.spacing8,
    },
    categories: {},
    category: {
        display: 'flex',
        borderRadius: theme.borderRadius.size8,
        background: theme.color.borderPrimaryOp4,
        height: 48,
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing.spacing8,
        '& > img': {
            paddingRight: theme.spacing.spacing8,
        },
        '& > div.name': {
            flex: 1,
            textAlign: 'center',
            textOverflow: 'ellipsis',
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
        },
    },
}));

type Props = {
    categories: CategoryIndex[];
    onClicked: (category: CategoryIndex) => void;
};
const HottestCategories = (props: Props) => {
    const { t } = useTranslation();
    const { categories, onClicked } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <div className={classes.container}>
            <div
                className={`${classes.categories} columns is-mobile is-variable is-4 is-multiline`}
            >
                {categories.map((category) => (
                    <a
                        className="column is-one-third-mobile  "
                        onClick={() => onClicked(category)}
                        key={`hottest-categories-${category.objectID}`}
                        role="button"
                        tabIndex={0}
                    >
                        <div className={classes.category}>
                            {category.icon ? (
                                <Image
                                    src={assetsPath(category.icon)}
                                    height="32"
                                    width="32"
                                />
                            ) : null}
                            <div className="name">{category.name}</div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default HottestCategories;
