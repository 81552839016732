import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles, useTheme } from 'react-jss';
import { Image } from 'components';
import ArrowRightImg from 'assets/arrow-right.svg';

const useStyles = createUseStyles((theme) => ({
    sectionHeader: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        padding: `${theme.spacing.spacing8}px ${theme.spacing.spacing16}px`,
        fontWeight: theme.fontWeight.bold,
        fontSize: theme.fontSize.size12,
        justifyContent: 'space-between',
        '& > a': {
            display: 'flex',
            alignitems: 'center',
            justifyContent: 'center',
            fontSize: theme.fontSize.size12,
            lineHeight: theme.lineHeight.size16,
            color: theme.color.textSecondary,
        },
    },
}));

type Props = {
    title: string;
    hasShowAll?: boolean;
    onShowAllClicked?: () => void;
};
const SectionHeader = (props: Props) => {
    const { t } = useTranslation();
    const { title, hasShowAll, onShowAllClicked } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <div className={classes.sectionHeader}>
            {title}
            {hasShowAll ? (
                <a onClick={onShowAllClicked} role="link" tabIndex={0}>
                    {t('search-bar.show-all')}
                    <Image src={ArrowRightImg} height="16" width="16" />
                </a>
            ) : null}
        </div>
    );
};

export default SectionHeader;
