import { initializeApp } from 'firebase/app';
import {
    getAuth,
    Auth,
    EmailAuthProvider,
    User as FirebaseUser,
    browserLocalPersistence,
    updatePassword as firebaseUpdatePassword,
    reauthenticateWithCredential as firebaseReauthenticateWithCredential,
    createUserWithEmailAndPassword as firebaseCreateUserWithEmailAndPassword,
    sendEmailVerification as firebaseSendEmailVerification,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
    sendPasswordResetEmail as firebaseSendPasswordResetEmail,
    onAuthStateChanged,
    onIdTokenChanged,
    NextOrObserver,
} from 'firebase/auth';

export const firebaseConfig = JSON.parse(
    `${process.env.REACT_APP_FIREBASE_CONFIG}`,
);

export const onFirebaseAuthStateChanged = (cb: NextOrObserver<FirebaseUser>) =>
    onAuthStateChanged(getAuth(), cb);
export const onFirebaseIdTokenChanged = (cb: NextOrObserver<FirebaseUser>) =>
    onIdTokenChanged(getAuth(), cb);
export const currentUser = () => getAuth().currentUser;

const firebaseApp = initializeApp(firebaseConfig);

let firebaseAuth: Auth;
export const getFirebaseAuth = () => {
    if (!firebaseAuth) {
        firebaseAuth = getAuth(firebaseApp);
        firebaseAuth.setPersistence(browserLocalPersistence);
        firebaseAuth.useDeviceLanguage();
    }
    return firebaseAuth;
};

export const updatePassword = async (newPassword: string) => {
    const user = getFirebaseAuth().currentUser;
    if (user) {
        return firebaseUpdatePassword(user, newPassword);
    }
    return null;
};

export const verifyPassword = async (user: FirebaseUser, password: string) => {
    if (!user.email) {
        return false;
    }
    const credential = await EmailAuthProvider.credential(user.email, password);
    try {
        await firebaseReauthenticateWithCredential(user, credential);
        return true;
    } catch (e) {
        return false;
    }
};

export const signUpWithEmail = async (email: string, password: string) => {
    const auth = getAuth();
    const user = auth.currentUser;
    const userCredential = await firebaseCreateUserWithEmailAndPassword(
        auth,
        email,
        password,
    );
    if (user) {
        await firebaseSendEmailVerification(user);
    }

    return userCredential;
};

export const connectGoogle = async () => {
    return signInWithPopup(getAuth(), new GoogleAuthProvider());
};

export const connectFacebook = async () => {
    return signInWithPopup(getAuth(), new FacebookAuthProvider());
};

export const signOut = async () => {
    return getFirebaseAuth().signOut();
};

export const loginWithEmail = async (email: string, password: string) => {
    return firebaseSignInWithEmailAndPassword(getAuth(), email, password);
};

export const sendPasswordResetEmail = async (email: string) => {
    return firebaseSendPasswordResetEmail(getAuth(), email);
};
