import create from 'zustand';
import { getMerchants } from 'apis/merchant-algolia';
import type { MerchantIndex, SearchParams } from 'apis/merchant-algolia.d';
import { uniqBy, omit } from 'lodash';
import { patchEmptyLogoAndCover } from 'utils/merchant';

type State = {
    merchants: MerchantIndex[];
    totalHits: number;
    page: number;
    totalPages: number;
    hitsPerPage: number;
    lastQueryId?: string;
    lastSearchParams?: any;
    fetch: (params: SearchParams, objectIds?: string[]) => void;
};

export const useSearchStore = create<State>((set, get) => ({
    merchants: [],
    totalHits: 0,
    page: 0,
    totalPages: 0,
    hitsPerPage: 0,
    lastQueryId: undefined,
    fetch: async (params, objectIds) => {
        const { merchants, merchantState, queryId } = await getMerchants(
            params,
            objectIds,
        );
        const patchedMerchants = merchants.map(patchEmptyLogoAndCover);
        const newMerchants =
            merchantState.page > 0
                ? uniqBy(
                      get().merchants.concat(patchedMerchants),
                      (m) => m.objectID,
                  )
                : patchedMerchants;
        set({
            lastQueryId: queryId,
            merchants: newMerchants,
            lastSearchParams: omit(params, 'page'),
            ...merchantState,
        });
    },
}));
