import React from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import getLocale from 'universal-locale';
import * as _ from 'lodash';
import numeral from 'numeral';
import { logo as logoIcon } from 'utils/illustration';
import { Image } from 'components';
import zhJson from 'locales/zh.json';
import enJson from 'locales/en.json';

const LOCALSTORAGE_LOCALE_KEY = 'locale';
const SUPPPORTED_LOCALES = ['en', 'zh'];
export const DEFAULT_LOCALE = 'zh';

i18n.use(initReactI18next).init({
    resources: {
        zh: { translation: zhJson },
        en: { translation: enJson },
    },
    fallbackLng: DEFAULT_LOCALE,
    debug: process.env.NODE_ENV !== 'production',

    keySeparator: '.', // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ',',
        format(value, format, lng) {
            if (format === 'bigNum') return numeral(value).format('0,0');
            return value;
        },
    },
    react: {
        useSuspense: false,
    },
});
i18n.on('languageChanged', (lng) => {
    localStorage.setItem(LOCALSTORAGE_LOCALE_KEY, lng);
});

export default i18n;

export const logo = () => {
    if (i18n.language === 'zh') {
        return (
            <Image
                style={{ objectFit: 'inherit' }}
                src={logoIcon.logoZh}
                width="180"
                height="24"
                layout="intrinsic"
            />
        );
    }
    return (
        <Image
            style={{ objectFit: 'inherit' }}
            src={logoIcon.logoEn}
            width="105"
            height="24"
            layout="intrinsic"
        />
    );
};

export const getUserDefaultLocale = () => {
    const storedLocale = localStorage.getItem(LOCALSTORAGE_LOCALE_KEY) || '';
    if (SUPPPORTED_LOCALES.includes(storedLocale)) {
        return storedLocale;
    }

    const browserLocale = getLocale() || '';
    const detectedLocale = _.find(SUPPPORTED_LOCALES, (key: string) =>
        browserLocale.includes(key),
    );
    if (detectedLocale) {
        return detectedLocale;
    }

    return DEFAULT_LOCALE;
};

export const isSupportedLocale = (locale: string) =>
    SUPPPORTED_LOCALES.includes(locale.toLowerCase());

export const generateLocaleUrl = (locale: string, path: string) => {
    let newPath = path;

    if (!path.startsWith('/')) {
        newPath = `/${path}`;
    }

    if (
        _.some(SUPPPORTED_LOCALES, (sl) =>
            newPath.toLowerCase().startsWith(`/${sl}`),
        )
    ) {
        //remove the locale prefix
        const x = newPath.split('/');
        x.splice(0, 2);
        newPath = `/${x.join('/')}`;
    }

    return `/${locale}${newPath}`;
};
export const generateUrl = (path: string) => {
    return generateLocaleUrl(i18n.language, path);
};
