import type { MenuFeed } from 'components/filter-menu/filter-menu';
import type { CategoryIndex, LocationIndex } from 'apis/merchant-algolia.d';
import _ from 'lodash';
import queryString from 'query-string';
import type { Search, Keyword } from 'apis/featured';
import urlEncode from 'utils/url-encode';

type Params = {
    keyword?: string | null;
    location?: MenuFeed[];
    category?: MenuFeed[];
    consumptionVoucherMethod?: MenuFeed[];
    takeawayMethod?: MenuFeed[];
    doorToDoorService?: MenuFeed[];
    sorting?: string;
};

const merchantPath = (newParams: Params) => {
    const {
        keyword,
        location,
        category,
        consumptionVoucherMethod,
        takeawayMethod,
        doorToDoorService,
        sorting,
    } = newParams;

    const qp = {
        keyword: keyword || undefined,
        sorting: sorting || undefined,
        consumptionVoucherMethod:
            consumptionVoucherMethod?.map((menu) => menu.id).join(',') ||
            undefined,
        takeawayMethod:
            takeawayMethod?.map((menu) => menu.id).join(',') || undefined,
        doorToDoorService:
            doorToDoorService?.map((menu) => menu.id).join(',') || undefined,
    };

    const qpString = queryString.stringify(qp);

    //1 location only
    if (category == null || category.length === 0) {
        if (location != null && location.length === 1) {
            const locationString = location[0].id;
            const parentId = location[0].parent;

            if (!parentId) {
                if (qpString != null && qpString.length > 0) {
                    return `/district/${locationString}?${qpString}`;
                }
                return `/district/${locationString}`;
            }
            if (qpString != null && qpString.length > 0) {
                return `/district/${parentId}/${locationString}?${qpString}`;
            }
            return `/district/${parentId}/${locationString}`;
        }
    }

    //1 category only
    if (location == null || location.length === 0) {
        if (category != null && category.length === 1) {
            const categoryString = category[0].id;
            const parentId = category[0].parent;

            //Category with keyword
            if (!parentId) {
                if (keyword) {
                    //if has keyword , need extra /search
                    return `/${categoryString}/search?${qpString}`;
                }
                return `/${categoryString}${
                    qpString.length > 0 ? `?${qpString}` : ''
                }`;
            }
            if (keyword) {
                //if has keyword , need extra /search
                return `/${parentId}/${categoryString}/search?${qpString}`;
            }
            return `/${parentId}/${categoryString}${
                qpString.length > 0 ? `?${qpString}` : ''
            }`;
        }
    }

    //Keyword only
    if (
        (location == null || location.length === 0) &&
        (category == null || category.length === 0) &&
        keyword
    ) {
        return `/search?${qpString}`;
    }

    // other case
    const locationString =
        _.chain(location)
            .map((mf) => mf.id)
            .join(',')
            .value() || undefined;

    const categoryString =
        _.chain(category)
            .map((mf) => mf.id)
            .join(',')
            .value() || undefined;

    const allQp = {
        category: categoryString,
        location: locationString,
        consumptionVoucherMethod:
            consumptionVoucherMethod?.map((menu) => menu.id).join(',') ||
            undefined,
        takeawayMethod:
            takeawayMethod?.map((menu) => menu.id).join(',') || undefined,
        doorToDoorService:
            doorToDoorService?.map((menu) => menu.id).join(',') || undefined,
        keyword: keyword || undefined,
        sorting: sorting || undefined,
    };

    const allQpString = queryString.stringify(allQp);

    return `/merchant/view-all?${allQpString}`;
};

export const merchantPathBySearch = (search: Search) => {
    if (search.type === 'category') {
        const data = search.data as CategoryIndex;
        if (data.type === 'categories_1st' || data.type === 'categories_2nd') {
            return `/${urlEncode(data.objectID)}`;
        }
        return `/${urlEncode(`${data.parent}`)}/${urlEncode(data.objectID)}`;
    }
    if (search.type === 'location') {
        const data = search.data as LocationIndex;

        if (data.type === 'area') {
            return `/district/${urlEncode(data.objectID)}`;
        }
        return `/district/${urlEncode(`${data.parent}`)}/${urlEncode(
            data.objectID,
        )}`;
    }
    if (search.type === 'keyword') {
        const data = search.data as Keyword;
        return `/search?keyword=${urlEncode(data.id)}`;
    }
    if (search.type === 'merchant') {
        return `/merchant/${urlEncode(search.name)}-${search.id}`;
    }
    return '';
};

export default merchantPath;
