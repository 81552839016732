import './utils/i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (process.env.REACT_APP_SENTRY_DSN) {
    Sentry.init({
        dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
        environment: `${process.env.REACT_APP_SENTRY_ENV}`,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: Number(
            process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE,
        ),
    });
}

console.info('load GTM / Hotjar');
if (process.env.REACT_APP_GTM_ID) {
    TagManager.initialize({
        gtmId: `${process.env.REACT_APP_GTM_ID}`,
    });
}

if (process.env.REACT_APP_HJID && process.env.REACT_APP_HJSV) {
    hotjar.initialize(
        Number(process.env.REACT_APP_HJID),
        Number(process.env.REACT_APP_HJSV),
    );
}
