export const i18nKeyMapping = (takeawayMethod: string) => {
    if (takeawayMethod === 'Food Panda') {
        return 'food-panda';
    }
    if (takeawayMethod === 'Deliveroo') {
        return 'deliveroo';
    }
    if (takeawayMethod === 'HKTV EXPRESS') {
        return 'hktv-express';
    }
    if (takeawayMethod === 'Lingduck') {
        return 'lingduck';
    }
    if (takeawayMethod === '自家速遞') {
        return 'self-deliver';
    }
    if (takeawayMethod === '跨區團購') {
        return 'group-buying';
    }
    if (takeawayMethod === 'Shopper') {
        return 'shopper';
    }
    if (takeawayMethod === 'Dim Order') {
        return 'dim-order';
    }
    if (takeawayMethod === 'Poke Guide') {
        return 'poke-guide';
    }
    return 'the-lazy-chef';
};
