import create from 'zustand';

type Pages = 'login' | 'create-ac' | 'email-reg';
type State = {
    mobileSearchBarVisible: boolean;
    setMobileSearchBarVisible: (show: boolean) => void;
    loginMenuVisible: boolean;
    setLoginMenuVisible: (visible: boolean) => void;
    loginInPage: Pages;
    setLoginInPage: (page: Pages) => void;
    homeFirstCategoryViewTabIndex: number;
    setHomeFirstCategoryViewTabIndex: (index: number) => void;
    homeSecondCategoryViewTabIndex: number;
    setHomeSecondCategoryViewTabIndex: (index: number) => void;
};

export const useUiStore = create<State>((set, get) => ({
    mobileSearchBarVisible: false,
    setMobileSearchBarVisible: (show: boolean) => {
        set({ ...get(), mobileSearchBarVisible: show });
    },
    loginMenuVisible: false,
    setLoginMenuVisible: (visible) => {
        set({ ...get(), loginMenuVisible: visible });
    },
    loginInPage: 'login',
    setLoginInPage: (page) => {
        set({ ...get(), loginInPage: page });
    },
    homeFirstCategoryViewTabIndex: 0,
    setHomeFirstCategoryViewTabIndex: (index: number) => {
        set({ ...get(), homeFirstCategoryViewTabIndex: index });
    },
    homeSecondCategoryViewTabIndex: 0,
    setHomeSecondCategoryViewTabIndex: (index: number) => {
        set({ ...get(), homeSecondCategoryViewTabIndex: index });
    },
}));
