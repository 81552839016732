import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Image } from 'components';

type HeaderProps = {
    leftIconSrc: string;
    leftBtnOnClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
    title: string;
    rightView?: React.ReactNode;
    className?: string;
};

const useHeaderStyles = createUseStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: 80,
        width: '100%',
    },
    headerContainer: {
        flex: 1.0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme.color.bgSecondary,
    },
    leftViewContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1.0,
    },
    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.bold,
        color: theme.color.textPrimary,
        marginLeft: theme.spacing.spacing16,
        flex: 1.0,
    },
    separateLine: {
        background: theme.color.borderPrimaryOp4,
        height: 1,
        width: '100%',
    },
}));

const Header: FC<HeaderProps> = (props) => {
    const { leftIconSrc, leftBtnOnClick, title, rightView, className } = props;
    const theme = useTheme();
    const classes = useHeaderStyles({ theme });

    return (
        <div
            className={`${classes.container} ${
                className != null ? className : ''
            }`}
        >
            <div className={classes.headerContainer}>
                <div className={classes.leftViewContainer}>
                    {leftBtnOnClick != null ? (
                        <a role="button" tabIndex={0} onClick={leftBtnOnClick}>
                            <Image width="24" height="24" src={leftIconSrc} />
                        </a>
                    ) : (
                        <div>
                            <Image width="24" height="24" src={leftIconSrc} />
                        </div>
                    )}
                    <p className={classes.title}>{title}</p>
                </div>
                {rightView != null && rightView}
            </div>
            <div className={classes.separateLine} />
        </div>
    );
};

export default Header;
