import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import type { LocationIndex } from 'apis/merchant-algolia.d';

const useStyles = createUseStyles((theme) => ({
    container: {
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing16,
        marginBottom: theme.spacing.spacing8,
    },
    locations: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: theme.spacing.spacing8,
    },
    location: {
        display: 'flex',
        borderRadius: theme.borderRadius.size8,
        background: theme.color.borderPrimaryOp4,
        height: 48,
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: theme.spacing.spacing8,
        '& > div': {
            flex: 1,
            textAlign: 'center',
        },
    },
}));

type Props = {
    locations: LocationIndex[];
    onClicked: (location: LocationIndex) => void;
};
const HottestLocations = (props: Props) => {
    const { t } = useTranslation();
    const { locations, onClicked } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <div className={classes.container}>
            <div className={classes.locations}>
                {locations.map((location) => (
                    <div
                        key={`hottest-locations-${location.objectID}`}
                        className={classes.location}
                        onClick={() => onClicked(location)}
                        role="button"
                        tabIndex={0}
                    >
                        <div>{location.name}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HottestLocations;
