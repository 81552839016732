import create, { GetState, SetState } from 'zustand';
import {
    signUpWithEmail,
    loginWithEmail,
    connectFacebook,
    connectGoogle,
    signOut,
    updatePassword,
    sendPasswordResetEmail,
    verifyPassword,
} from 'apis/firebase-auth';
import { getUserInfo, updateUserInfo } from 'apis/user-info';
import type { User as FirebaseUser } from 'firebase/auth';

type State = {
    isAuth?: boolean; //when isAuth == undefined. means the firebase api not called yetf
    user: FirebaseUser | null;
    userInfo: {
        firstName: string;
        lastName: string;
        displayName: string;
    } | null;
    jwtToken: string | null;
    setUser: (user: FirebaseUser | null) => void;
    signUpWithEmail: (email: string, password: string) => void;
    connectFacebook: () => void;
    connectGoogle: () => void;
    signOut: () => void;
    loginWithEmail: (email: string, password: string) => void;
    updateUserInfo: (firstName: string, lastName: string) => void;
    updatePassword: (newPassword: string) => void;
    sendPasswordResetEmail: (email: string) => void;
    verifyPassword: (password: string) => Promise<boolean>;
};

export const useUserStore = create<State>((set, get) => ({
    user: null,
    jwtToken: null,
    userInfo: null,
    verifyPassword: async (password) => {
        const user = get().user;
        if (!user) {
            return false;
        }
        return verifyPassword(user, password);
    },
    updatePassword: async (newPassword) => {
        updatePassword(newPassword);
    },
    updateUserInfo: async (firstName, lastName) => {
        const userInfo = await updateUserInfo({ firstName, lastName });
        set({ ...get(), userInfo });
    },
    setUser: async (user) => {
        await changeUser(set, get, user);
    },
    signUpWithEmail: async (email, password) => {
        await signUpWithEmail(email, password);
    },
    loginWithEmail: async (email, password) => {
        await loginWithEmail(email, password);
    },
    connectFacebook: async () => {
        await connectFacebook();
    },
    connectGoogle: async () => {
        await connectGoogle();
    },
    signOut: async () => {
        await signOut();
    },
    sendPasswordResetEmail: async (email) => {
        await sendPasswordResetEmail(email);
    },
}));
async function changeUser(
    set: SetState<State>,
    get: GetState<State>,
    user: FirebaseUser | null,
) {
    if (user) {
        const jwtToken = await user.getIdToken(true);
        const userInfo = await getUserInfo(jwtToken);
        set({ ...get(), user, jwtToken, userInfo, isAuth: true });
    } else {
        set({
            ...get(),
            user: null,
            jwtToken: null,
            userInfo: null,
            isAuth: false,
        });
    }
}
