import React from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { LocaleLink, Image } from 'components';
import assetsPath from 'utils/assets-path';

type Props = {
    sectionTitle: string;
    className?: string;
    hideShowAllBtn?: boolean;
    seeAllTo?: string;
};

const useStyles = createUseStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        fontWeight: theme.fontWeight.bold,
        color: theme.color.textPrimary,
    },
    allBtnView: {
        flex: 1.0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    allBtn: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.bold,
        color: theme.color.textSecondary,
        '&:hover': {
            color: theme.color.textSecondary,
        },
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

const SectionHeader = (props: Props) => {
    const { sectionTitle, className, hideShowAllBtn, seeAllTo } = props;

    // hooks
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });
    const { t } = useTranslation();

    // UI event function

    // logic function

    // render function

    return (
        <div
            className={`${classes.container} ${
                className != null ? className : ''
            }`}
        >
            <h3 className={`is-hidden-mobile ${classes.title}`}>
                {sectionTitle}
            </h3>
            <h3 className={`is-hidden-tablet ${classes.title}`}>
                {sectionTitle}
            </h3>
            {hideShowAllBtn === false && seeAllTo && (
                <div className={classes.allBtnView}>
                    <LocaleLink to={seeAllTo} className={classes.allBtn}>
                        {t('header.all')}
                        <Image
                            src={assetsPath('arrow-right')}
                            width="24"
                            height="24"
                            stroke={theme.color.textSecondary}
                        />
                    </LocaleLink>
                </div>
            )}
        </div>
    );
};

export default SectionHeader;
