import React, { FC, useState, useCallback, useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { LokoTextField, RoundedButton, Image, LocaleLink } from 'components';
import { useUserStore } from 'store/user';
import assetsPath from 'utils/assets-path';
import { signUpSuccessful, loginSuccessful } from 'utils/gtm';

type Pages = 'login' | 'create-ac' | 'email-reg';
type Props = {
    onClose?: () => void;
    defaultPage?: Pages;
};

const useStyles = createUseStyles((theme) => ({
    container: {
        height: '100%',
        color: theme.color.textPrimary,
        positive: 'relative',
    },
    closeBtn: {
        display: 'block',
        position: 'sticky',
        top: theme.spacing.spacing24,
        marginLeft: theme.spacing.spacing16,
    },
    pageContainer: {
        height: '100%',
        paddingLeft: theme.spacing.spacing32,
        paddingRight: theme.spacing.spacing32,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.mediaQueries.mobile]: {
            justifyContent: 'flex-start',
            paddingTop: theme.spacing.spacing64,
            marginBottom: theme.spacing.spacing36,
        },
    },
    header: {
        marginBottom: theme.spacing.spacing4,
    },
    loginRegisterSwitch: {
        marginBottom: theme.spacing.spacing32,
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
    },
    link: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        textDecorationLine: 'underline',
        color: theme.color.textSecondary,
        '&:hover': {
            color: theme.color.textSecondary,
        },
    },
    input: {
        marginBottom: [[theme.spacing.spacing16], '!important'],
    },
    lastInput: {
        marginBottom: [[theme.spacing.spacing32], '!important'],
    },
    passwordInput: {
        marginBottom: [[theme.spacing.spacing4], '!important'],
    },
    passwordRulesText: {
        fontSize: theme.fontSize.size12,
        lineHeight: theme.lineHeight.size16,
        color: theme.color.borderPrimaryOp2,
        marginBottom: theme.spacing.spacing16,
        paddingLeft: theme.spacing.spacing16,
    },
    forgotPw: {
        marginBottom: theme.spacing.spacing32,
    },
    btn: {
        height: 56,
        fontWeight: theme.fontWeight.bold,
        '& p': {
            fontSize: [[theme.fontSize.size18], '!important'],
        },
    },
    loginBtn: {
        background: theme.color.btnPrimary,
        borderWidth: 0,
        extend: 'btn',
    },
    separateLine: {
        marginTop: theme.spacing.spacing32,
        marginBottom: theme.spacing.spacing32,
        background: theme.color.borderPrimaryOp4,
        height: 1,
    },
    googleBtn: {
        extend: 'btn',
        marginBottom: theme.spacing.spacing16,
    },
    fbBtn: {
        extend: 'btn',
        '& p': {
            color: theme.color.textThird,
        },
        background: '#3B5998',
    },
    error: {
        color: theme.color.textFive,
        marginBottom: theme.spacing.spacing32,
    },
}));

const LoginMenu: FC<Props> = (props) => {
    const { onClose, defaultPage } = props;
    // hooks
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { t } = useTranslation();
    const [currentPage, setCurrentPage] = useState<Pages>(
        defaultPage || 'login',
    );

    const [loginEmail, setLoginEmail] = useState<string>('');
    const [loginPw, setLoginPw] = useState<string>('');
    const [regEmail, setRegEmail] = useState<string>('');
    const [regPw, setRegPw] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [error, setError] = useState<any>(null);
    const [errorMsg, setErrorMsg] = useState('');
    const userStore = useUserStore(useCallback((state) => state, []));

    // UI event function
    useEffect(() => {
        if (error) {
            const message = t(`firebase.${error.code}`);
            if (message === `firebase.${error.code}`) {
                //Error message unexcepted.
                console.error(`unexcepted login error${JSON.stringify(error)}`);
            } else {
                setErrorMsg(message);
            }
        } else {
            setErrorMsg('');
        }
    }, [error]);

    const onClickLoginBtn = async () => {
        try {
            await userStore.loginWithEmail(loginEmail, loginPw);
            if (onClose) {
                onClose();
            }
        } catch (e) {
            console.error('error', e, t(`firebase.${e.code}`));
            setError(e);
        }
    };

    const onClickRegisterBtn = () => {
        setCurrentPage('email-reg');
    };

    const onClickCreateAcBtn = async () => {
        console.log('create ac btn onclick');

        try {
            await userStore.signUpWithEmail(regEmail, regPw);
            if (firstName || lastName) {
                const unSub = useUserStore.subscribe((state) => {
                    if (state.jwtToken) {
                        (async () => {
                            await userStore.updateUserInfo(firstName, lastName);
                            unSub();
                            signUpSuccessful('Email');
                            if (onClose) {
                                onClose();
                            }
                        })();
                    }
                });
            } else if (onClose) {
                onClose();
            }
        } catch (e) {
            console.error('error', e, t(`firebase.${e.code}`));
            setError(e);
        }
    };

    const onKeyPressEnterLoginPage = (
        event: React.KeyboardEvent<HTMLDivElement>,
    ) => {
        if (event.key === 'Enter') {
            onClickLoginBtn();
        }
    };

    const onKeyPressEnterCreateAcPage = (
        event: React.KeyboardEvent<HTMLDivElement>,
    ) => {
        if (event.key === 'Enter') {
            onClickRegisterBtn();
        }
    };

    const onKeyPressEnterEmailRegPage = (
        event: React.KeyboardEvent<HTMLDivElement>,
    ) => {
        if (event.key === 'Enter') {
            onClickCreateAcBtn();
        }
    };

    const goCreateAcPage = () => {
        setCurrentPage('create-ac');
    };

    const goLoginPage = () => {
        setCurrentPage('login');
    };

    // logic function

    const connectGoogle = useCallback(
        (isLoginIn) => {
            (async () => {
                try {
                    await userStore.connectGoogle();
                    if (isLoginIn) {
                        loginSuccessful('Google');
                    } else {
                        signUpSuccessful('Google');
                    }
                    if (onClose) {
                        onClose();
                    }
                } catch (e) {
                    console.error('connect google error', e);
                    setError(e);
                }
            })();
        },
        [userStore],
    );

    const connectFacebook = useCallback(
        (isLoginIn) => {
            (async () => {
                try {
                    await userStore.connectFacebook();
                    if (isLoginIn) {
                        loginSuccessful('Facebook');
                    } else {
                        signUpSuccessful('Facebook');
                    }
                    if (onClose) {
                        onClose();
                    }
                } catch (e) {
                    console.error('connect facebok error', e);
                    setError(e);
                }
            })();
        },
        [userStore],
    );

    // render function
    const renderLoginPage = () => {
        return (
            <div className={classes.pageContainer}>
                <div>
                    <h1 className={classes.header}>
                        {t('login-menu.login-title')}
                    </h1>
                    <p className={classes.loginRegisterSwitch}>
                        {t('login-menu.not-yet-reg')}{' '}
                        <a
                            onClick={goCreateAcPage}
                            role="button"
                            tabIndex={0}
                            className={classes.link}
                        >
                            {t('login-menu.create-ac')}
                        </a>
                    </p>
                    <LokoTextField
                        className={classes.input}
                        label={t('login-menu.email')}
                        type="email"
                        onKeyPress={onKeyPressEnterLoginPage}
                        value={loginEmail}
                        onChange={(event) => {
                            setLoginEmail(event.target.value);
                        }}
                    />
                    <LokoTextField
                        className={classes.lastInput}
                        label={t('login-menu.password')}
                        type="password"
                        onKeyPress={onKeyPressEnterLoginPage}
                        value={loginPw}
                        onChange={(event) => {
                            setLoginPw(event.target.value);
                        }}
                    />
                    <div className={classes.forgotPw}>
                        <LocaleLink className={classes.link} to={'/forget-pw'}>
                            {t('login-menu.forgot-pw')}
                        </LocaleLink>
                    </div>

                    <div className={classes.error}>{errorMsg}</div>
                    <RoundedButton
                        className={classes.loginBtn}
                        to=""
                        onClick={onClickLoginBtn}
                        title={t('login-menu.login')}
                    />
                    <div className={classes.separateLine} />
                    <RoundedButton
                        className={classes.googleBtn}
                        to={'#'}
                        title={t('login-menu.google-login')}
                        iconSrc={assetsPath('google-color')}
                        onClick={() => connectGoogle(true)}
                    />
                    <RoundedButton
                        className={classes.fbBtn}
                        to={'#'}
                        title={t('login-menu.fb-login')}
                        iconSrc={assetsPath('facebook')}
                        onClick={() => connectFacebook(true)}
                    />
                </div>
            </div>
        );
    };

    const renderCreateAccountPage = () => {
        return (
            <div className={classes.pageContainer}>
                <div>
                    <h1 className={classes.header}>
                        {t('login-menu.reg-title')}
                    </h1>
                    <p className={classes.loginRegisterSwitch}>
                        {t('login-menu.reg-already')}{' '}
                        <a
                            onClick={goLoginPage}
                            role="button"
                            tabIndex={0}
                            className={classes.link}
                        >
                            {t('login-menu.login')}
                        </a>
                    </p>
                    <LokoTextField
                        className={classes.lastInput}
                        label={t('login-menu.email')}
                        type="email"
                        onKeyPress={onKeyPressEnterCreateAcPage}
                        value={regEmail}
                        onChange={(event) => {
                            setRegEmail(event.target.value);
                        }}
                    />
                    <div className={classes.error}>{errorMsg}</div>
                    <RoundedButton
                        className={classes.loginBtn}
                        to=""
                        onClick={onClickRegisterBtn}
                        title={t('login-menu.continue')}
                    />
                    <div className={classes.separateLine} />
                    <RoundedButton
                        className={classes.googleBtn}
                        to={'#'}
                        title={t('login-menu.google-login')}
                        iconSrc={assetsPath('google-color')}
                        onClick={connectGoogle}
                    />
                    <RoundedButton
                        className={classes.fbBtn}
                        to={'#'}
                        title={t('login-menu.fb-login')}
                        iconSrc={assetsPath('facebook')}
                        onClick={connectFacebook}
                    />
                </div>
            </div>
        );
    };

    const renderEamilRegisterPage = () => {
        return (
            <div className={classes.pageContainer}>
                <div>
                    <h1 className={classes.header}>
                        {t('login-menu.reg-title')}
                    </h1>
                    <p className={classes.loginRegisterSwitch}>
                        {t('login-menu.reg-already')}{' '}
                        <a
                            onClick={goLoginPage}
                            role="button"
                            tabIndex={0}
                            className={classes.link}
                        >
                            {t('login-menu.login')}
                        </a>
                    </p>
                    <LokoTextField
                        className={classes.input}
                        label={t('login-menu.email')}
                        type="email"
                        onKeyPress={onKeyPressEnterEmailRegPage}
                        value={regEmail}
                        onChange={(event) => {
                            setRegEmail(event.target.value);
                        }}
                    />
                    <LokoTextField
                        className={classes.passwordInput}
                        label={t('login-menu.password')}
                        type="password"
                        onKeyPress={onKeyPressEnterEmailRegPage}
                        value={regPw}
                        onChange={(event) => {
                            setRegPw(event.target.value);
                        }}
                    />
                    <p className={classes.passwordRulesText}>
                        {t('login-menu.pw-rule')}
                    </p>
                    <LokoTextField
                        className={classes.input}
                        label={t('login-menu.last-name')}
                        onKeyPress={onKeyPressEnterEmailRegPage}
                        value={lastName}
                        onChange={(event) => {
                            setLastName(event.target.value);
                        }}
                    />
                    <LokoTextField
                        className={classes.lastInput}
                        label={t('login-menu.first-name')}
                        onKeyPress={onKeyPressEnterEmailRegPage}
                        value={firstName}
                        onChange={(event) => {
                            setFirstName(event.target.value);
                        }}
                    />
                    <div className={classes.error}>{errorMsg}</div>
                    <RoundedButton
                        className={classes.loginBtn}
                        to=""
                        onClick={onClickCreateAcBtn}
                        title={t('login-menu.create-ac')}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className={classes.container}>
            <a
                className={`${classes.closeBtn} is-hidden-tablet`}
                onClick={onClose}
                role="button"
                tabIndex={0}
            >
                <Image src={assetsPath('cross')} width="24" height="24" />
            </a>
            {currentPage === 'login' && renderLoginPage()}
            {currentPage === 'create-ac' && renderCreateAccountPage()}
            {currentPage === 'email-reg' && renderEamilRegisterPage()}
        </div>
    );
};

export default LoginMenu;
