import React, { FC } from 'react';

const ListLoader: FC = () => {
    // hooks

    // UI event function

    // logic function

    // render function

    return <h4>Loading...</h4>;
};

export default ListLoader;
