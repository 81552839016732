import create from 'zustand';
import {
    getUserFavourites,
    removeUserFavourites,
    addUserFavourites,
} from 'apis/user-favourite';
import * as _ from 'lodash';

type State = {
    userFavouriteMerchantIds: string[] | null;
    fetch: () => void;
    remove: (merchantIds: string[]) => void;
    add: (merchantIds: string[]) => void;
    clear: () => void;
};

export const useUserFavouriteStore = create<State>((set, get) => ({
    userFavouriteMerchantIds: null,
    fetch: async () => {
        const userFavourites = await getUserFavourites();
        set({
            ...get(),
            userFavouriteMerchantIds: userFavourites.map((uf) => uf.merchantId),
        });
    },
    remove: async (merchantIds: string[]) => {
        const removedUserFavouriteMerchantIds = (
            await removeUserFavourites(merchantIds)
        ).map((uf) => uf.merchantId);

        set({
            ...get(),
            userFavouriteMerchantIds: _.reject(
                get().userFavouriteMerchantIds,
                (mid) => removedUserFavouriteMerchantIds.includes(mid),
            ),
        });
    },
    add: async (merchantIds: string[]) => {
        const newUserFavouritieMerchantIds = (
            await addUserFavourites(merchantIds)
        ).map((uf) => uf.merchantId);

        set({
            ...get(),
            userFavouriteMerchantIds: _.concat(
                get().userFavouriteMerchantIds || [],
                newUserFavouritieMerchantIds,
            ),
        });
    },
    clear: () => {
        set({ ...get(), userFavouriteMerchantIds: null });
    },
}));
