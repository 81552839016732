declare global {
    interface Window {
        dataLayer: any[];
    }
}
window.dataLayer = window.dataLayer || [];

export const lokoIconSelected = () => {
    window.dataLayer.push({
        event: 'Loko Icon Selected',
        currentUrl: window.location.pathname + window.location.search,
    });
};

export const categorySelected = (
    categoryName: string,
    categoryId: string,
    entracePath: string,
) => {
    window.dataLayer.push({
        event: 'Category Selected',
        categoryName,
        categoryId,
        entracePath,
    });
};

export const subCategorySelected = (
    categoryName: string,
    categoryId: string,
    entracePath: string,
) => {
    window.dataLayer.push({
        event: 'Sub Category Selected',
        categoryName,
        categoryId,
        entracePath,
    });
};

export const merchantSelected = (
    merchantName: string,
    merchantId: string,
    merchantCategory: string,
    merchantSubCategory: string,
    entracePath: string,
) => {
    window.dataLayer.push({
        event: 'Merchant Selected',
        merchantName,
        merchantId,
        merchantCategory,
        merchantSubCategory,
        entracePath,
    });
};

export const signUpSuccessful = (
    signUpMethod: 'Facebook' | 'Email' | 'Google',
) => {
    window.dataLayer.push({
        event: 'Sign Up Successful',
        signUpMethod,
        signUpDate: new Date().toISOString(),
    });
};

export const loginSuccessful = (loginMethod: string) => {
    window.dataLayer.push({
        event: 'Login Successful',
        loginMethod,
    });
};

export const favouriteButtonSelected = () => {
    window.dataLayer.push({
        event: 'Favourite Button Selected',
        currentUrl: window.location.pathname + window.location.search,
    });
};

export const merchantAddFavouriteSuccessful = (
    merchantName: string,
    merchantId: string,
) => {
    window.dataLayer.push({
        event: 'Merchant Add Favourite Successful',
        merchantName,
        merchantId,
    });
};

export const searchBoxSelected = () => {
    window.dataLayer.push({
        event: 'Search Box Selected',
        currentUrl: window.location.pathname + window.location.search,
    });
};

type SearchType =
    | 'Keyword Search'
    | 'Popular Search'
    | 'Search History'
    | 'Suggested Results';

export const searchBoxKeywordSearched = (
    keyword: string,
    selectionType: SearchType,
) => {
    window.dataLayer.push({
        event: 'Search Box Keyword Searched',
        keyword,
        selectedionType: selectionType,
    });
};

export const homePagePopularSearchSelected = (keyword: string) => {
    window.dataLayer.push({
        event: 'Home Page Popular Search Selected',
        keyword,
    });
};

export const myAccountSelected = (entracePath: string) => {
    window.dataLayer.push({
        event: 'My Account Selected',
        currentUrl: entracePath,
    });
};

export const lokoSocialMediaSelected = (
    socialMedia: 'Facebook' | 'Whatsapp' | 'Instagram',
) => {
    window.dataLayer.push({
        event: 'Loko Social Media Selected',
        socialMedia,
    });
};

export const merchantSocialMediaSelected = (
    socialMedia:
        | 'Facebook'
        | 'Whatsapp'
        | 'Mewe'
        | 'Instagram'
        | 'Pinkoi'
        | 'Signal'
        | 'Openrice',
) => {
    window.dataLayer.push({
        event: 'Merchant Social Media Selected',
        socialMedia,
    });
};

export const favouriteListSelected = () => {
    window.dataLayer.push({
        event: 'Favourite List Selected',
    });
};

export const homePageBannerSelected = (bannerId: number) => {
    window.dataLayer.push({
        event: 'Home Page Banner Selected',
        bannerId,
    });
};

export const homePageThemeSelected = (themeId: number) => {
    window.dataLayer.push({
        event: 'Home Page Theme Selected',
        themeId,
    });
};

export const homePageJoinMerchantSelected = () => {
    window.dataLayer.push({
        event: 'Home Page Join Merchant Selected',
    });
};

export const merchantSuggestionSubmitted = () => {
    window.dataLayer.push({
        event: 'Merchant Suggestion Submitted',
    });
};

export const homePageLokoStandardDetailsSelected = () => {
    window.dataLayer.push({
        event: 'Home Page Loko Standard Details Selected',
    });
};

export const mobileHamburgerBarSelected = () => {
    window.dataLayer.push({
        event: 'Mobile Hamburger Bar Selected',
        currentUrl: window.location.pathname + window.location.search,
    });
};
