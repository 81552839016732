import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import _ from 'lodash';

const useStyles = createUseStyles((theme) => ({
    textField: {
        '& input': {
            color: theme.color.textPrimary,
            fontSize: theme.fontSize.size16,
        },
        '& label': {
            color: theme.color.borderPrimaryOp2,
            fontSize: theme.fontSize.size16,
        },
        '& label.Mui-focused': {
            color: theme.color.borderPrimaryOp2,
        },
        '& .MuiOutlinedInput-root': {
            height: 60,
            '& fieldset': {
                borderColor: theme.color.borderPrimaryOp2,
                borderRadius: theme.borderRadius.size8,
                borderWidth: 1,
            },
            '&:hover fieldset': {
                borderColor: theme.color.borderPrimaryOp2,
                borderRadius: theme.borderRadius.size8,
                borderWidth: 1,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.color.borderPrimaryOp2,
                borderRadius: theme.borderRadius.size8,
                borderWidth: 1,
            },
        },
    },
}));

const LokoTextField: FC<TextFieldProps> = (props) => {
    // hooks
    const { className } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });

    // UI event function

    // logic function

    // render function

    return (
        <TextField
            className={`${classes.textField} ${
                className != null ? className : ''
            }`}
            variant="outlined"
            fullWidth
            {..._.omit(props, 'className')}
        />
    );
};

export default LokoTextField;
