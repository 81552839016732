import React, { FC, useState, useCallback, useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import {
    Image,
    DrawerCategoryBtn,
    RoundedButton,
    FilterHeader,
} from 'components';
import { useBasicInfoStore } from 'store/basic-info';
import _ from 'lodash';
import assetsPath from 'utils/assets-path';
import CheckedImg from 'assets/checked.svg';
import UncheckedImg from 'assets/unchecked.svg';
import { useDebouncedEffect } from 'utils/use-debounced-effect';
import { i18nKeyMapping as d2dI18nKeyMapping } from 'utils/door-to-door';
import { i18nKeyMapping as takeawayI18nKeyMapping } from 'utils/takeaway';

type Props = {
    onClose: () => void;
    defaultSelected?: {
        categories: string[];
        locations: string[];
        consumptionVoucherMethod: string[];
        doorToDoorService: string[];
        takeawayMethod: string[];
    };
    onClickConfirmBtn: () => void;
    onFiltersUpdated: (cf: MenuFeed[]) => void;
    numOfRecord: number;
};

export type MenuFeed = {
    id: string | number;
    title: string;
    subTitle?: string | number;
    icon?: string;
    parent?: string | number;
    type:
        | 'category'
        | 'location'
        | 'consumptionVoucherMethod'
        | 'takeawayMethod'
        | 'doorToDoorService';
    subMenus: MenuFeed[];
};

const useStyles = createUseStyles((theme) => ({
    container: {
        height: '100%',
    },
    header: {
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing16,
        position: 'sticky',
        top: 0,
    },
    listItemContainer: {
        overflow: 'auto',
        height: 'calc(100% - 80px)',
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing16,
        '& $listItem': {
            '&:last-child': {
                '& $separateLine': {
                    display: 'none',
                },
            },
        },
    },
    listItem: {},
    categoryBtn: {
        background: 'transparent',
        paddingLeft: 0,
        paddingRight: 0,
    },
    separateLine: {
        height: 1,
        backgroundColor: theme.color.borderPrimaryOp4,
    },
    resetBtn: {
        height: 36,
    },
    confirmBtnContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.color.bgSecondary,
        padding: theme.spacing.spacing16,
    },
    confirmBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 38,
        borderRadius: 80,
        background: theme.color.btnPrimary,
    },
    confirmBtnTitle: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        color: theme.color.textPrimary,
    },
}));

const FilterMenu: FC<Props> = (props) => {
    // hooks
    const {
        onClose,
        onClickConfirmBtn,
        numOfRecord,
        onFiltersUpdated,
        defaultSelected,
    } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { t } = useTranslation();
    const basicInfoStore = useBasicInfoStore(useCallback((state) => state, []));
    const [categoryFeed, setCategoryFeed] = useState<MenuFeed[]>([]);
    const [currentPage, setCurrentPage] = useState<
        'first' | 'second' | 'third'
    >('first');
    const [
        currentSelectedCategoryIndex,
        setCurrentSelectedCategoryIndex,
    ] = useState<number | null>(null);
    const [
        currentSelectedsubMenusIndex,
        setCurrentSelectedsubMenusIndex,
    ] = useState<number | null>(null);
    const [
        currentSelectedThirdCategory,
        setCurrentSelectedThirdCategory,
    ] = useState<MenuFeed[]>([]);

    useEffect(() => {
        const categoryArr = Object.values(basicInfoStore.categories);
        const locationArr = Object.values(basicInfoStore.locations);
        const counts = basicInfoStore.facetsCount || {};
        const topLevelCategories = _.filter(
            categoryArr,
            (c) => c.type === 'categories_1st',
        );
        const findChildCategories = (type: string, parent: string) =>
            _.filter(
                categoryArr,
                (c) => c.parent === parent && c.type === type,
            );
        const findChildLocation = (type: string, parent: string) =>
            _.filter(
                locationArr,
                (l) => l.parent === parent && l.type === type,
            );

        const allLocation: any = {
            id: 'all-location',
            title: t('common.location'),
            icon: 'location',
            type: 'location',
            subMenus: _.filter(locationArr, (l) => l.type === 'area').map(
                (l) => {
                    return {
                        id: l.objectID,
                        title: l.name,
                        subTitle: _.get(counts, ['areas', l.objectID], 0),
                        icon: l.icon,
                        type: 'location',
                        subMenus: findChildLocation('district', l.objectID).map(
                            (d) => ({
                                id: d.objectID,
                                parent: l.objectID,
                                title: d.name,
                                subTitle: _.get(
                                    counts,
                                    ['districts', d.objectID],
                                    0,
                                ),
                                icon: d.icon,
                                type: 'location',
                                subMenus: [],
                            }),
                        ),
                    };
                },
            ),
        };

        const paymentMethodCounts = _.get(counts, ['payment_methods'], {});
        const d2dServicesCounts = _.get(counts, ['door_to_door_services'], {});
        const takeawayServicesCounts = _.get(counts, ['takeaway_methods'], {});
        const allConsumptionVoucherMethod: any = {
            id: 'all-consumption-voucher-method',
            title: t('consumption-voucher.filter-menu-title'),
            icon: 'money',
            type: 'consumptionVoucherMethod',
            subMenus: [
                {
                    id: 'octopus',
                    title: t('consumption-voucher.octopus'),
                    subTitle: paymentMethodCounts.octopus || '0',
                    icon: 'money',
                    type: 'consumptionVoucherMethod',
                    parent: 'all-consumption-voucher-method',
                    subMenus: [],
                },
                {
                    id: 'tap_and_go',
                    title: t('consumption-voucher.tap_and_go'),
                    subTitle: paymentMethodCounts.tap_and_go || '0',
                    icon: 'money',
                    type: 'consumptionVoucherMethod',
                    parent: 'all-consumption-voucher-method',
                    subMenus: [],
                },
                {
                    id: 'alipay',
                    title: t('consumption-voucher.alipay'),
                    subTitle: paymentMethodCounts.alipay || '0',
                    icon: 'money',
                    type: 'consumptionVoucherMethod',
                    parent: 'all-consumption-voucher-method',
                    subMenus: [],
                },
                {
                    id: 'wechat_pay',
                    title: t('consumption-voucher.wechat_pay'),
                    subTitle: paymentMethodCounts.wechat_pay || '0',
                    icon: 'money',
                    type: 'consumptionVoucherMethod',
                    parent: 'all-consumption-voucher-method',
                    subMenus: [],
                },
            ],
        };

        const allD2dServices: any = {
            id: 'all-d2d-services',
            title: t('door-to-door-service.name'),
            type: 'doorToDoorService',
            icon: 'home',
            subMenus: ['美容', '美甲', '髮型', '按摩', '攝影', '其他'].map(
                (type) => ({
                    id: type,
                    title: t(`door-to-door-service.${d2dI18nKeyMapping(type)}`),
                    subTitle: d2dServicesCounts[type] || '0',
                    type: 'doorToDoorService',
                    parent: 'all-d2d-services',
                    subMenus: [],
                }),
            ),
        };

        const allTakeawayMethods: any = {
            id: 'all-takeaway-methods',
            title: t('takeaway-method.name'),
            type: 'takeawayMethod',
            icon: 'shopping-bag',
            subMenus: [
                'Food Panda',
                'Deliveroo',
                'HKTV EXPRESS',
                'Lingduck',
                '自家速遞',
                '跨區團購',
                'Shopper',
                'Dim Order',
                'Poke Guide',
                '懶人煮意(餸包湯包)',
            ].map((type) => ({
                id: type,
                title: t(`takeaway-method.${takeawayI18nKeyMapping(type)}`),
                subTitle: takeawayServicesCounts[type] || '0',
                type: 'takeawayMethod',
                parent: 'all-takeaway-methods',
                subMenus: [],
            })),
        };

        setCategoryFeed(
            _.concat(
                _.map(topLevelCategories, (c) => {
                    return {
                        id: c.objectID,
                        title: c.name,
                        icon: c.icon,
                        subTitle: _.get(counts, [c.type, c.objectID], 0),
                        type: 'category',
                        subMenus: findChildCategories(
                            'categories_2nd',
                            c.objectID,
                        ).map((sc) => {
                            return {
                                id: sc.objectID,
                                parent: c.objectID,
                                title: sc.name,
                                subTitle: _.get(
                                    counts,
                                    [sc.type, sc.objectID],
                                    0,
                                ),
                                icon: sc.icon,
                                type: 'category',
                                subMenus: findChildCategories(
                                    'categories_3rd',
                                    sc.objectID,
                                ).map((ssc) => ({
                                    id: ssc.objectID,
                                    parent: sc.objectID,
                                    title: ssc.name,
                                    subTitle: _.get(
                                        counts,
                                        [ssc.type, ssc.objectID],
                                        0,
                                    ),
                                    type: 'category',
                                    icon: ssc.icon || ssc.icon,
                                    subMenus: [],
                                })),
                            };
                        }),
                    };
                }),
                [allLocation],
                [allConsumptionVoucherMethod],
                [allD2dServices],
                [allTakeawayMethods],
            ),
        );

        let preSelectedMenuFeeds: MenuFeed[] = [];
        defaultSelected?.categories.forEach((catName) => {
            const category = _.get(basicInfoStore.categories, catName, null);
            if (category) {
                preSelectedMenuFeeds = preSelectedMenuFeeds.concat({
                    id: category.objectID,
                    title: category.name,
                    subTitle: 99,
                    icon: category.icon,
                    parent: category.parent,
                    type: 'category',
                    subMenus: [],
                });
            }
        });
        defaultSelected?.locations.forEach((locationName) => {
            const location = _.get(
                basicInfoStore.locations,
                locationName,
                null,
            );
            if (location) {
                preSelectedMenuFeeds = preSelectedMenuFeeds.concat({
                    id: location.objectID,
                    title: location.name,
                    parent: location.parent,
                    subTitle: 99,
                    icon: location.icon,
                    type: 'location',
                    subMenus: [],
                });
            }
        });
        defaultSelected?.consumptionVoucherMethod.forEach((m) => {
            preSelectedMenuFeeds = preSelectedMenuFeeds.concat({
                id: m,
                title: t(`consumption-voucher.${m}`),
                subTitle: 99,
                icon: 'location',
                type: 'consumptionVoucherMethod',
                subMenus: [],
            });
        });
        defaultSelected?.doorToDoorService.forEach((m) => {
            preSelectedMenuFeeds = preSelectedMenuFeeds.concat({
                id: m,
                title: t(`door-to-door-service.${m}`),
                subTitle: 99,
                icon: 'home',
                type: 'doorToDoorService',
                subMenus: [],
            });
        });
        defaultSelected?.takeawayMethod.forEach((m) => {
            preSelectedMenuFeeds = preSelectedMenuFeeds.concat({
                id: m,
                title: t(`takeaway-method.${m}`),
                subTitle: 99,
                icon: 'shopping-bag',
                type: 'takeawayMethod',
                subMenus: [],
            });
        });

        setCurrentSelectedThirdCategory(preSelectedMenuFeeds);
    }, [basicInfoStore]);

    useDebouncedEffect(
        () => {
            onFiltersUpdated(currentSelectedThirdCategory);
        },
        300,
        [currentSelectedThirdCategory],
    );

    // UI event function
    const categoryItemOnClick = (selectedIndex: number) => {
        const selectedCat = categoryFeed[selectedIndex];

        if (
            [
                'consumptionVoucherMethod',
                'doorToDoorService',
                'takeawayMethod',
            ].includes(selectedCat.type)
        ) {
            setCurrentSelectedCategoryIndex(selectedIndex);
            setCurrentPage('third');
            return;
        }

        setCurrentSelectedCategoryIndex(selectedIndex);
        setCurrentPage('second');
    };

    const subMenusItemOnClick = (selectedIndex: number) => {
        setCurrentSelectedsubMenusIndex(selectedIndex);
        setCurrentPage('third');
    };

    const thirdCategoryItemOnClick = (categoryItem: MenuFeed) => {
        const findIndex = _.findIndex(
            currentSelectedThirdCategory,
            (o) => o.id === categoryItem.id,
        );

        if (findIndex === -1) {
            setCurrentSelectedThirdCategory((prevState) => {
                return [...prevState, categoryItem];
            });
        } else {
            setCurrentSelectedThirdCategory((prevState) => {
                const prevArray = [...prevState];
                _.pullAt(prevArray, findIndex);
                return prevArray;
            });
        }
    };

    const secondPageBackBtnOnClick = () => {
        setCurrentPage('first');
        setCurrentSelectedCategoryIndex(null);
    };

    const thirdPageBackBtnOnClick = () => {
        setCurrentPage('second');
        setCurrentSelectedsubMenusIndex(null);
    };

    const firstPageResetBtnOnClick = () => {
        setCurrentSelectedThirdCategory([]);
    };

    const secondPageResetBtnOnClick = () => {
        const subCatArray: any[] = getCurrentSelectedsubMenusList();

        const hitArray = subCatArray.map((cat) => {
            const filterArray = _.filter(
                currentSelectedThirdCategory,
                (o) => o.parent === cat.id,
            );

            return filterArray;
        });

        const newSelectedThirdCategory = [...currentSelectedThirdCategory];
        _.pullAllBy(newSelectedThirdCategory, _.flatten(hitArray), 'parent');
        setCurrentSelectedThirdCategory(newSelectedThirdCategory);
    };

    const confirmBtnOnClick = () => {
        onClickConfirmBtn();
    };

    // logic function
    const getCurrentSelectedCategoryTitle = () => {
        if (currentSelectedCategoryIndex != null) {
            return categoryFeed[currentSelectedCategoryIndex].title;
        }
        return '';
    };

    const getCurrentSelectedsubMenusTitle = () => {
        const subMenusList = getCurrentSelectedsubMenusList();
        if (currentSelectedsubMenusIndex != null) {
            return subMenusList[currentSelectedsubMenusIndex].title;
        }
        return '';
    };

    const getCurrentSelectedsubMenusList = () => {
        if (currentSelectedCategoryIndex != null) {
            return categoryFeed[currentSelectedCategoryIndex].subMenus || [];
        }
        return [];
    };

    const getCurrentSelectedThirdCategoryList = () => {
        const subMenusList = getCurrentSelectedsubMenusList();
        if (
            currentSelectedsubMenusIndex != null &&
            currentSelectedsubMenusIndex < subMenusList.length
        ) {
            const currentMenu = subMenusList[currentSelectedsubMenusIndex];
            if (currentMenu.subMenus.length === 0) {
                return [currentMenu];
            }
            return currentMenu.subMenus || [];
        }
        return [];
    };

    const getSelectedCategoryTitleForSecondPage = (category: any) => {
        const filterArray = _.filter(
            currentSelectedThirdCategory,
            (o) => o.parent === category.id,
        );

        return filterArray.map((cat) => cat.title).join(', ');
    };

    const getSelectedCategoryTitleForFirstPage = (category: any) => {
        const subCatArray: any[] = category.subMenus;

        const hitArray = subCatArray.map((cat) => {
            const filterArray = _.filter(
                currentSelectedThirdCategory,
                (o) => o.parent === cat.id,
            );

            return filterArray;
        });

        return _.flatten(hitArray)
            .map((cat) => cat.title)
            .join(', ');
    };

    // render function
    const renderFirstPageResetBtn = () => {
        if (currentSelectedThirdCategory.length === 0) {
            return null;
        }
        return (
            <RoundedButton
                className={classes.resetBtn}
                to={''}
                title={t('common.reset')}
                onClick={firstPageResetBtnOnClick}
            />
        );
    };

    const renderSecondPageResetBtn = () => {
        if (currentSelectedCategoryIndex != null) {
            const category = categoryFeed[currentSelectedCategoryIndex];
            if (getSelectedCategoryTitleForFirstPage(category).length > 0) {
                return (
                    <RoundedButton
                        className={classes.resetBtn}
                        to={''}
                        title={t('common.reset')}
                        onClick={secondPageResetBtnOnClick}
                    />
                );
            }
        }

        return null;
    };

    const renderConfirmBtn = () => {
        return (
            <div className={classes.confirmBtnContainer}>
                <a
                    className={classes.confirmBtn}
                    onClick={confirmBtnOnClick}
                    role="button"
                    tabIndex={0}
                >
                    <p className={classes.confirmBtnTitle}>
                        {t('filter.browse', { num: numOfRecord })}
                    </p>
                </a>
            </div>
        );
    };

    const renderFirstPage = () => {
        return (
            <div className={classes.container}>
                <FilterHeader
                    className={classes.header}
                    leftIconSrc={assetsPath('cross')}
                    title={t('filter.filter')}
                    leftBtnOnClick={onClose}
                    rightView={renderFirstPageResetBtn()}
                />
                <div className={classes.listItemContainer}>
                    {categoryFeed.map((category, index) => {
                        return (
                            <div
                                className={classes.listItem}
                                key={`filter-menu-first-page-${category.id}`}
                            >
                                <DrawerCategoryBtn
                                    className={classes.categoryBtn}
                                    to={''}
                                    iconSrc={
                                        category.icon
                                            ? assetsPath(category.icon)
                                            : ''
                                    }
                                    categoryTitle={category.title}
                                    subTitle={getSelectedCategoryTitleForFirstPage(
                                        category,
                                    )}
                                    onClick={() => categoryItemOnClick(index)}
                                    showRightArrow
                                    normalTitle
                                />
                                <div className={classes.separateLine} />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderSecondPage = () => {
        return (
            <div className={classes.container}>
                <FilterHeader
                    className={classes.header}
                    leftIconSrc={assetsPath('back')}
                    title={getCurrentSelectedCategoryTitle()}
                    leftBtnOnClick={secondPageBackBtnOnClick}
                    rightView={renderSecondPageResetBtn()}
                />
                <div className={classes.listItemContainer}>
                    {getCurrentSelectedsubMenusList().map((category, index) => {
                        return (
                            <div
                                className={classes.listItem}
                                key={`filter-menu-second-page-${category.id}`}
                            >
                                <DrawerCategoryBtn
                                    className={classes.categoryBtn}
                                    to={''}
                                    iconSrc={
                                        category.icon
                                            ? assetsPath(category.icon)
                                            : ''
                                    }
                                    categoryTitle={category.title}
                                    subTitle={getSelectedCategoryTitleForSecondPage(
                                        category,
                                    )}
                                    onClick={() => subMenusItemOnClick(index)}
                                    showRightArrow
                                    normalTitle
                                />
                                <div className={classes.separateLine} />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    const renderThirdPage = () => {
        let currentSelectedCategory;
        let getFeedFunction = getCurrentSelectedThirdCategoryList;
        if (currentSelectedCategoryIndex != null) {
            currentSelectedCategory =
                categoryFeed[currentSelectedCategoryIndex];

            if (
                [
                    'consumptionVoucherMethod',
                    'doorToDoorService',
                    'takeawayMethod',
                ].includes(currentSelectedCategory.type)
            ) {
                getFeedFunction = getCurrentSelectedsubMenusList;
            }
        }

        return (
            <div className={classes.container}>
                <FilterHeader
                    className={classes.header}
                    leftIconSrc={assetsPath('back')}
                    title={
                        currentSelectedCategory != null &&
                        [
                            'consumptionVoucherMethod',
                            'doorToDoorService',
                        ].includes(currentSelectedCategory.type)
                            ? getCurrentSelectedCategoryTitle()
                            : getCurrentSelectedsubMenusTitle()
                    }
                    leftBtnOnClick={
                        currentSelectedCategory != null &&
                        [
                            'consumptionVoucherMethod',
                            'doorToDoorService',
                            'takeawayMethod',
                        ].includes(currentSelectedCategory.type)
                            ? secondPageBackBtnOnClick
                            : thirdPageBackBtnOnClick
                    }
                />
                <div className={classes.listItemContainer}>
                    {getFeedFunction().map((category) => {
                        return (
                            <div
                                className={classes.listItem}
                                key={`filter-menu-third-page-${category.id}`}
                            >
                                <CategoryCheckBox
                                    className={classes.categoryBtn}
                                    title={category.title}
                                    subTitle={`${category.subTitle}`}
                                    isSelected={
                                        _.findIndex(
                                            currentSelectedThirdCategory,
                                            (o) => o.id === category.id,
                                        ) !== -1
                                    }
                                    onClick={() =>
                                        thirdCategoryItemOnClick(category)
                                    }
                                />
                                <div className={classes.separateLine} />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className={classes.container}>
            {currentPage === 'first' && renderFirstPage()}
            {currentPage === 'second' && renderSecondPage()}
            {currentPage === 'third' && renderThirdPage()}
            {renderConfirmBtn()}
        </div>
    );
};

type CategoryCheckBoxProps = {
    title: string;
    subTitle?: string;
    isSelected: boolean;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
};

const useCategoryCheckBoxStyles = createUseStyles((theme) => ({
    btn: {
        height: 56,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        color: theme.color.textPrimary,
        flex: 1.0,
    },
    rightViewContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    subTitle: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.fontSize.size24,
        color: theme.color.textPrimaryOp1,
        marginRight: theme.spacing.spacing8,
    },
}));

const CategoryCheckBox: FC<CategoryCheckBoxProps> = (props) => {
    const { title, subTitle, isSelected, className, onClick } = props;
    const theme = useTheme();
    const classes = useCategoryCheckBoxStyles({ theme });

    return (
        <a
            className={`${classes.btn} ${className}`}
            onClick={onClick}
            role="button"
            tabIndex={0}
        >
            <p className={classes.title}>{title}</p>
            <div className={classes.rightViewContainer}>
                <p className={classes.subTitle}>{subTitle}</p>
                <Image
                    src={isSelected ? CheckedImg : UncheckedImg}
                    width="24"
                    height="24"
                />
            </div>
        </a>
    );
};

export default FilterMenu;
