import React, { FC, useRef, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Carousel, { ReactElasticCarouselProps } from 'react-elastic-carousel';
import useTimeout from '@rooks/use-timeout';
import ConstantsConfig from 'constants/constants-config';
import { Image } from 'components';
import assetsPath from 'utils/assets-path';

interface Props extends ReactElasticCarouselProps {
    itemsLength: number;
}

const useStyles = createUseStyles((theme) => ({
    bigSlider: {
        width: '100%',
        position: 'relative',
        '& .rec-slider-container': {
            margin: [[0], '!important'],
        },
        '& .rec-pagination': {
            position: 'absolute',
            bottom: theme.spacing.spacing16,
        },
        '& .rec-dot': {
            width: 8,
            height: 8,
            background: theme.color.btnThird,
            border: ['1.5px', 'solid', theme.color.borderPrimaryOp4],
            borderRadius: '8px',
            marginLeft: theme.spacing.spacing8,
            marginRight: theme.spacing.spacing8,
            marginBottom: 0,
            boxShadow: 'none',
            '&:hover': {
                boxShadow: 'none',
            },
        },
        '& .rec-dot_active': {
            background: theme.color.btnSecondary,
        },
    },
    carouselControlBtn: {
        pointerEvents: 'all',
        width: 56,
        height: 56,
        borderRadius: 80,
        background: 'rgba(255, 255, 255, 0.2)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        visibility: 'visible',
        opacity: 1,
        transition: [['opacity', `0.1s`, 'linear']],
        alignSelf: 'center',
        position: 'absolute',
        zIndex: 2,
    },
    carouselControlLeftBtn: {
        extend: 'carouselControlBtn',
        left: theme.spacing.spacing16,
    },
    carouselControlRightBtn: {
        extend: 'carouselControlBtn',
        right: theme.spacing.spacing16,
    },
    carouselControlBtnHide: {
        visibility: 'hidden',
        opacity: 0,
        transition: [
            ['visibility', '0s', `0.1s`],
            ['opacity', `0.1s`, 'linear'],
        ],
    },
}));

const LokoCarousel: FC<Props> = (props) => {
    const { children, className, itemsLength } = props;
    // hooks
    const theme = useTheme();
    const classes = useStyles({ theme });
    const bannerCarousel = useRef<Carousel>(null);
    const [enableAutoPlay, setEnableAutoPlay] = useState(false);
    const bannerCarouselTimer = useTimeout(() => {
        if (bannerCarousel != null && bannerCarousel.current != null) {
            bannerCarousel.current.goTo(0);
        }
    }, ConstantsConfig.CarouselAutoPlaySpeed);
    const [hideLeftBtn, setHideLeftBtn] = useState<boolean>(true);
    const [hideRightBtn, setHideRightBtn] = useState<boolean>(false);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setEnableAutoPlay(true);
            clearTimeout(timer);
        }, 2000);
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, []);
    // UI event function

    // logic function

    // render function

    return (
        <Carousel
            ref={bannerCarousel}
            itemsToShow={1}
            showArrows
            enableAutoPlay={enableAutoPlay}
            autoPlaySpeed={ConstantsConfig.CarouselAutoPlaySpeed}
            onPrevEnd={({ index }) => {
                bannerCarouselTimer.clear();

                if (index === 0) {
                    setHideLeftBtn(true);
                } else {
                    setHideLeftBtn(false);
                }

                if (index + 1 === itemsLength) {
                    setHideRightBtn(true);
                } else {
                    setHideRightBtn(false);
                }
            }}
            onNextEnd={({ index }) => {
                bannerCarouselTimer.clear();
                console.log('on next end');
                if (index + 1 === itemsLength) {
                    bannerCarouselTimer.start();
                }

                if (index === 0) {
                    setHideLeftBtn(true);
                } else {
                    setHideLeftBtn(false);
                }

                if (index + 1 === itemsLength) {
                    setHideRightBtn(true);
                } else {
                    setHideRightBtn(false);
                }
            }}
            renderArrow={({ type, onClick }) => {
                return (
                    <a
                        className={`${
                            type === 'NEXT'
                                ? classes.carouselControlRightBtn
                                : classes.carouselControlLeftBtn
                        } ${
                            type === 'NEXT' && hideRightBtn === true
                                ? classes.carouselControlBtnHide
                                : ''
                        } ${
                            type === 'PREV' && hideLeftBtn === true
                                ? classes.carouselControlBtnHide
                                : ''
                        }`}
                        onClick={onClick}
                        role="button"
                        tabIndex={0}
                    >
                        <Image
                            src={assetsPath(
                                type === 'NEXT'
                                    ? 'arrow-chevron-right'
                                    : 'arrow-chevron-left',
                            )}
                            width="24"
                            height="24"
                        />
                    </a>
                );
            }}
            {...props}
            className={`${classes.bigSlider} ${
                className != null ? className : ''
            }`}
        >
            {children}
        </Carousel>
    );
};

export default LokoCarousel;
