export const getUserInfo = async (jwtToken: string): Promise<Response> => {
    return { firstName: 'test', lastName: 'test', displayName: 'test' };
    // return axios
    //     .get('/users/info', {
    //         headers: { Authorization: `Bearer ${jwtToken}` },
    //     })
    //     .then((r) => r.data);
};

export const updateUserInfo = async ({
    firstName,
    lastName,
}: {
    firstName: string;
    lastName: string;
}): Promise<Response> => {
    return { firstName: 'test', lastName: 'test', displayName: 'test' };
    // return axiosWithAuth()
    //     .post('/users/info', {
    //         first_name: firstName,
    //         last_name: lastName,
    //     })
    //     .then((r) => r.data);
};

type Response = {
    firstName: string;
    lastName: string;
    displayName: string;
};
