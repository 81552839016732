import React, { FC, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { FilterHeader, Image } from 'components';
import { useTranslation } from 'react-i18next';
import assetsPath from 'utils/assets-path';
import SortingConfig, {
    getCurrentLangSortingTitle,
    Sorting,
} from 'constants/sorting-config';

type Props = {
    onClose: () => void;
    onUpdatedSorting: (sorting: Sorting) => void;
    defaultSorting: Sorting;
};

const useStyles = createUseStyles((theme) => ({
    container: {
        height: '100%',
    },
    header: {
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing16,
        position: 'sticky',
        top: 0,
    },
    listItemContainer: {
        overflow: 'auto',
        height: 'calc(100% - 80px)',
        paddingLeft: theme.spacing.spacing16,
        paddingRight: theme.spacing.spacing16,
        '& $listItem': {
            '&:last-child': {
                '& $separateLine': {
                    display: 'none',
                },
            },
        },
    },
    listItem: {},
    categoryBtn: {
        background: 'transparent',
        paddingLeft: 0,
        paddingRight: 0,
    },
    separateLine: {
        height: 1,
        backgroundColor: theme.color.borderPrimaryOp4,
    },
    confirmBtnContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        background: theme.color.bgSecondary,
        padding: theme.spacing.spacing16,
    },
    confirmBtn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 38,
        borderRadius: 80,
        background: theme.color.btnPrimary,
    },
    confirmBtnTitle: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        color: theme.color.textPrimary,
    },
}));

const Component: FC<Props> = (props) => {
    // hooks
    const { onClose, onUpdatedSorting, defaultSorting } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { t } = useTranslation();
    const [selectedSorting, setSelectedSorting] = useState<Sorting>(
        defaultSorting,
    );

    // UI event function
    const onClickSortingItem = (sorting: Sorting) => {
        setSelectedSorting(sorting);
        onUpdatedSorting(sorting);
    };

    // logic function

    // render function

    return (
        <div className={classes.container}>
            <FilterHeader
                className={classes.header}
                leftIconSrc={assetsPath('cross')}
                title={t('filter.filter')}
                leftBtnOnClick={onClose}
            />
            <div className={classes.listItemContainer}>
                {SortingConfig.map((sorting) => {
                    return (
                        <div
                            className={classes.listItem}
                            key={`sorting-menu-${sorting.id}`}
                        >
                            <SortingRadioButton
                                className={classes.categoryBtn}
                                title={getCurrentLangSortingTitle(sorting)}
                                isSelected={sorting.id === selectedSorting.id}
                                onClick={() => onClickSortingItem(sorting)}
                            />
                            <div className={classes.separateLine} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

type SortingRadioButtonProps = {
    title: string;
    isSelected: boolean;
    className?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
};

const useSortingRadioButtonStyles = createUseStyles((theme) => ({
    btn: {
        height: 56,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        color: theme.color.textPrimary,
        flex: 1.0,
    },
    rightViewContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
}));

const SortingRadioButton: FC<SortingRadioButtonProps> = (props) => {
    const { title, isSelected, className, onClick } = props;
    const theme = useTheme();
    const classes = useSortingRadioButtonStyles({ theme });

    return (
        <a
            className={`${classes.btn} ${className}`}
            onClick={onClick}
            role="button"
            tabIndex={0}
        >
            <p className={classes.title}>{title}</p>
            <div className={classes.rightViewContainer}>
                <Image
                    src={
                        isSelected
                            ? assetsPath('radio-button-on')
                            : assetsPath('radio-button-off')
                    }
                    width="24"
                    height="24"
                />
            </div>
        </a>
    );
};

export default Component;
