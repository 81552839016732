import React, { useState } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { RoundedButton, StaticContent } from 'components';
import { useTranslation } from 'react-i18next';
import assetsPath from 'utils/assets-path';
import { Dialog, Alert } from '@mui/material';

const useStyles = createUseStyles((theme) => ({
    selfClaimView: {
        '& .button': {
            backgroundColor: theme.color.btnPrimary,
            border: 'none',
        },
    },
    dialog: {
        '& a': {
            textDecoration: 'underline',
            '&.whatsapp': {
                display: 'flex',
            },
            '&.ig': {
                display: 'inline-flex',
            },
            alignItems: 'center',
            '& img': {
                height: '24px',
            },
        },
    },
    btn: {
        background: theme.color.btnPrimary,
        borderWidth: 0,
        extend: 'btn',
    },
}));

type Props = {
    method: 'instagram' | 'whatsapp';
    merchantName: string;
};
const SelfClaim = (props: Props) => {
    const { method, merchantName } = props;
    const { t } = useTranslation();
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });
    const [showSelfClaimMessage, setShowSelfClaimMessage] = useState(false);
    const [showCopied, setShowCopied] = React.useState(false);
    React.useEffect(() => {
        if (showCopied) {
            setTimeout(() => {
                setShowCopied(false);
            }, 5000);
        }
    }, [showCopied]);
    return (
        <div className={classes.selfClaimView}>
            <RoundedButton
                onClick={() => setShowSelfClaimMessage(true)}
                className={'button'}
                title={t('merchant.self-claim')}
                tooltip={t('merchant.self-claim-tooltip')}
                to={''}
            />
            <Dialog
                open={showSelfClaimMessage}
                onClose={() => setShowSelfClaimMessage(false)}
                className={classes.dialog}
            >
                {method === 'instagram' ? (
                    <StaticContent
                        i18nKey="merchant.self-claim-ig"
                        translationOptions={{
                            icon: assetsPath('instagram-color'),
                        }}
                    />
                ) : (
                    <StaticContent
                        i18nKey="merchant.self-claim-other"
                        translationOptions={{
                            icon: assetsPath('whatsapp-color'),
                            whatsappTxt: `我是${merchantName}，想取得self claim連結`,
                        }}
                    />
                )}
                <p style={{ margin: theme.spacing.spacing20 }}>
                    {showCopied && (
                        <p style={{ marginBottom: 12 }}>
                            <Alert severity="info">製已複</Alert>
                        </p>
                    )}
                    <RoundedButton
                        className={classes.btn}
                        to={''}
                        onClick={() => {
                            navigator.clipboard.writeText(
                                t('merchant.self-claim-ig-message'),
                            );
                            setShowCopied(true);
                        }}
                        title={t('merchant.self-claim-ig-copy')}
                    />
                </p>
            </Dialog>
        </div>
    );
};

export default SelfClaim;
