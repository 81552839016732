import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

type Props = {
    i18nKey: string;
    translationOptions?: Record<string, any>;
};

const useStyles = createUseStyles((theme) => ({
    container: {
        paddingLeft: theme.spacing.spacing64,
        paddingRight: theme.spacing.spacing64,
        paddingTop: theme.spacing.spacing40,
        paddingBottom: theme.spacing.spacing80,
        color: theme.color.textPrimary,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        '@global': {
            h3: {
                marginTop: theme.spacing.spacing40,
                marginBottom: theme.spacing.spacing16,
            },
            p: {
                marginBottom: theme.spacing.spacing16,
            },
            ol: {
                marginBottom: theme.spacing.spacing16,
                paddingLeft: theme.spacing.spacing16,
            },
        },
        [theme.mediaQueries.mobile]: {
            paddingLeft: theme.spacing.spacing16,
            paddingRight: theme.spacing.spacing16,
        },
    },
    maxWidthContainer: {
        maxWidth: 800,
    },
}));

const StaticContent: FC<Props> = (props) => {
    // hooks
    const { i18nKey, translationOptions } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { t } = useTranslation();

    // UI event function

    // logic function

    // render function

    return (
        <div className={classes.container}>
            <div className={classes.maxWidthContainer}>
                {ReactHtmlParser(t(i18nKey, translationOptions))}
            </div>
        </div>
    );
};

export default StaticContent;
