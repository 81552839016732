import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Image } from 'components';
import { TAKEAWAY_SERVICE_ASSETS } from 'utils/takeway-service-icons';
import type { TakeawayMethod } from 'apis/merchant-algolia.d';
import assetsPath from 'utils/assets-path';
import _ from 'lodash';

const MAX_METHODS_DISPLAY = 3;

type Props = {
    merchantId: string;
    takeawayMethods: TakeawayMethod[];
};

const iconElement = (merchantId: string, method: string, className: string) => {
    return (
        <div
            className={`column is-narrow`}
            key={`merchant-card-takeaway-methods-${merchantId}-${method}`}
        >
            <div className={className}>
                <Image
                    src={_.get(TAKEAWAY_SERVICE_ASSETS, method)}
                    height="20"
                    width="20"
                />
            </div>
        </div>
    );
};
export default (props: Props) => {
    const { takeawayMethods, merchantId } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
        <div
            className={`columns is-mobile is-variable is-2 is-justify-content-flex-end ${classes.container}`}
        >
            {_.take(takeawayMethods, MAX_METHODS_DISPLAY).map((m) =>
                iconElement(merchantId, m, classes.img),
            )}
            {takeawayMethods.length > MAX_METHODS_DISPLAY ? (
                <div className={`column is-narrow `}>
                    <div className={` ${classes.collapseNumberView}`}>
                        {/* {takeawayMethods.length - MAX_METHODS_DISPLAY}+ */}
                        <Image
                            className={classes.collapseNumberViewBg}
                            width="22"
                            height="20"
                            src={assetsPath('standard-count-0')}
                        />
                        2+
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const useStyles = createUseStyles((theme) => ({
    container: {
        '& .column': {
            lineHeight: theme.lineHeight.size16,
        },
    },
    img: {
        width: 20,
        height: 20,
        '&  img': {
            borderRadius: 4,
        },
    },
    collapseNumberView: {
        width: 22,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        fontSize: 10,
    },
    collapseNumberViewBg: {
        position: 'absolute',
        height: '100%',
        left: 0,
        top: 0,
    },
}));
