import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Image } from 'components';
import type { Search } from 'apis/featured';
import assetsPath from 'utils/assets-path';

const useStyles = createUseStyles((theme) => ({
    result: {
        display: 'flex',
        minHeight: 48,
        paddingRight: theme.spacing.spacing16,
    },
    resultContextContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        overflowX: 'auto',
        [`${theme.mediaQueries.mobile}`]: {
            marginLeft: theme.spacing.spacing16,
        },
    },

    hottestSearch: {
        cursor: 'pointer',
        flex: 'none',
        background: theme.color.borderPrimaryOp4,
        borderRadius: theme.borderRadius.size8,
        padding: `${theme.spacing.spacing4}px ${theme.spacing.spacing8}px`,
        marginRight: theme.spacing.spacing8,
    },
    resultIconContainer: {
        minWidth: 64,
        maxWidth: 64,
        flexBasis: 'auto',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
}));

type Props = {
    searches: Search[];
    isMobile: boolean;
    onClicked: (search: Search) => void;
};
const HottestSearch = (props: Props) => {
    const { t } = useTranslation();
    const { searches, isMobile, onClicked } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <div className={classes.result}>
            {isMobile ? null : (
                <div className={classes.resultIconContainer}>
                    <Image
                        src={assetsPath('magnifier-fire')}
                        width="24"
                        height="24"
                    />
                </div>
            )}
            <div className={classes.resultContextContainer}>
                {searches.map((s) => (
                    <div
                        key={`search-bar-hottest-search-${s.id}`}
                        className={classes.hottestSearch}
                        onClick={() => onClicked(s)}
                        role="button"
                        tabIndex={0}
                    >
                        {s.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HottestSearch;
