import React from 'react';
import { Link as RnLink, LinkProps } from 'react-router-dom';
import _ from 'lodash';
import I18n, { DEFAULT_LOCALE } from '../utils/i18n';

const LocaleLink = (props: LinkProps) => {
    const { to } = props;
    let actualTo = to;
    const linkPrefix =
        I18n.language === DEFAULT_LOCALE ? '' : `/${I18n.language}`;

    if (_.isString(to)) {
        actualTo = `${linkPrefix}${to}`;
    } else if (_.isObject(to)) {
        actualTo = { ...to, pathname: `${linkPrefix}${_.get(to, 'pathname')}` };
    }

    return <RnLink {...props} to={actualTo} />;
};
export default LocaleLink;
