import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Alert, Dialog } from '@mui/material';
import { RoundedButton } from 'components';

const useStyles = createUseStyles((theme) => ({
    btn: {
        background: theme.color.btnPrimary,
        borderWidth: 0,
        extend: 'btn',
    },
}));

const SelfRegistrationSuccess: FC = () => {
    const [showCopied, setShowCopied] = React.useState(false);
    React.useEffect(() => {
        if (showCopied) {
            setTimeout(() => {
                setShowCopied(false);
            }, 5000);
        }
    }, [showCopied]);
    const theme = useTheme();
    const classes = useStyles({ theme });
    const currentLocation = useLocation();
    const queryParams = queryString.parse(currentLocation.search);
    const { _id } = queryParams;
    const url = `http://loko.hk/merchant-registration?_id=${_id}`;
    return (
        <div className="loko-container">
            <Dialog open>
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: '24px',
                        fontWeight: 'bold',
                        padding: 28,
                    }}
                >
                    <p style={{ marginBottom: 12 }}>
                        你可以複製及保留以下連結，以便之後更新資料！
                    </p>

                    <p style={{ marginBottom: 12 }}>
                        <a href={url}>{url}</a>
                    </p>
                    {showCopied && (
                        <p style={{ marginBottom: 12 }}>
                            <Alert severity="info">製已複</Alert>
                        </p>
                    )}
                    <p style={{ marginBottom: 12 }}>
                        <RoundedButton
                            className={classes.btn}
                            to={''}
                            onClick={() => {
                                navigator.clipboard.writeText(url);
                                setShowCopied(true);
                            }}
                            title="按此複製"
                        />
                    </p>
                </div>
            </Dialog>
        </div>
    );
};

export default SelfRegistrationSuccess;
