import React, { FC, useState, HTMLAttributes, useCallback } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { Image, LocaleLink, SnapScroll, MerchantCard } from 'components';
import urlEncode from 'utils/url-encode';
import { useTranslation } from 'react-i18next';
import assetsPath from 'utils/assets-path';
import { useMediaQuery } from '@mui/material';
import { MerchantIndex } from 'apis/merchant-algolia.d';
import { merchantIndexToMerchantCardProps } from 'utils/merchant';
import { useUserStore } from 'store/user';
import { useUiStore } from 'store/ui';
import { clickedMerchantCard } from 'apis/aloglia-insight';
import { useUserFavouriteStore } from 'store/user-favourite';
import { merchantAddFavouriteSuccessful } from 'utils/gtm';
import { useIntersection } from 'components/use-intersection';

export interface MerchantsSnapScrollProps
    extends HTMLAttributes<HTMLDivElement> {
    sectionTitle: string;
    seeAllLink: string;
    merchants: MerchantIndex[];
}

const useStyles = createUseStyles((theme) => ({
    sectionContainer: {
        overflow: 'hidden',
        [`${theme.mediaQueries.mobile}`]: {
            paddingLeft: theme.spacing.spacing16,
            paddingRight: theme.spacing.spacing16,
        },
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 56,
    },
    title: {
        color: theme.color.textPrimary,
        fontSize: theme.fontSize.size18,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.bold,
    },
    seeAllBtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    seeAllTitle: {
        color: theme.color.textSecondary,
        fontSize: theme.fontSize.size12,
        lineHeight: theme.lineHeight.size16,
        fontWeight: theme.fontWeight.bold,
    },
    featuredMerchantsMerchantCard: {
        width: 240,
        marginRight: theme.spacing.spacing16,
        flexShrink: 0,
        scrollSnapAlign: 'start',
        scrollSnapStop: 'always',
    },
}));

const MerchantsSnapScroll: FC<MerchantsSnapScrollProps> = (props) => {
    // hooks
    const { className, sectionTitle, merchants, seeAllLink } = props;
    const rootRef = React.createRef<HTMLDivElement>();
    const inViewPort = useIntersection(rootRef, '-50px');
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });

    const isMobile = useMediaQuery(
        `(max-width:${theme.breakpoints.tablet - 1}px)`,
    );

    const { t } = useTranslation();

    const {
        userFavouriteMerchantIds,
        addFavourites,
        removeFavourites,
    } = useUserFavouriteStore(
        useCallback(
            (state) => ({
                userFavouriteMerchantIds: state.userFavouriteMerchantIds,
                addFavourites: state.add,
                removeFavourites: state.remove,
            }),
            [],
        ),
    );

    const { isAuth } = useUserStore(
        useCallback((state) => ({ isAuth: state.isAuth }), []),
    );

    const { setLoginMenuVisible, setLoginInPage } = useUiStore(
        useCallback((state) => state, []),
    );

    const [isHideControlBtn, setIsHideControlBtn] = useState(true);

    // UI event function
    const onFavouriteButtonClicked = useCallback((merchant: any) => {
        if (merchant.isBookmark) {
            removeFavourites([merchant.objectId]);
        } else {
            addFavourites([merchant.objectId]);
            merchantAddFavouriteSuccessful(merchant.title, merchant.objectId);
        }
    }, []);

    const [isAllLoaded, setIsAllLoaded] = useState(false);
    const [merchantsToDisplay, setMerchantsToDisplay] = useState<
        MerchantIndex[]
    >([]);
    React.useEffect(() => {
        if (isAllLoaded) {
            return;
        }
        if (inViewPort) {
            console.log('load all');
            setMerchantsToDisplay(merchants);
            setIsAllLoaded(true);
        } else {
            setMerchantsToDisplay(merchants.slice(0, 1));
        }
    }, [inViewPort]);

    // logic function

    // render function

    return (
        <div
            ref={rootRef}
            className={`${classes.sectionContainer} ${
                className != null ? className : ''
            }`}
            onMouseEnter={() => setIsHideControlBtn(false)}
            onMouseLeave={() => setIsHideControlBtn(true)}
        >
            <div className={classes.titleContainer}>
                <p className={classes.title}>{sectionTitle}</p>
                <LocaleLink className={classes.seeAllBtn} to={seeAllLink}>
                    <p className={classes.seeAllTitle}>
                        {t('consumption-voucher.see-all')}
                    </p>
                    <Image
                        width="16"
                        height="16"
                        stroke={theme.color.svgFillPrimary}
                        src={assetsPath('arrow-chevron-right')}
                    />
                </LocaleLink>
            </div>
            <SnapScroll
                padding={isMobile ? theme.spacing.spacing16 : 0}
                hideControlBtn={isHideControlBtn}
            >
                {merchantsToDisplay.map((m) => {
                    const merchant = merchantIndexToMerchantCardProps({
                        merchantIndex: m,
                        isBookmark:
                            userFavouriteMerchantIds?.includes(m.objectID) ||
                            false,
                    });
                    return (
                        <div
                            key={`snap-scroll-mc-${merchant.objectId}`}
                            className={classes.featuredMerchantsMerchantCard}
                        >
                            <MerchantCard
                                onClick={() => {
                                    clickedMerchantCard(merchant.objectId);
                                }}
                                to={`/merchant/${urlEncode(merchant.title)}-${
                                    merchant.objectId
                                }`}
                                bookmarkBtnOnClick={() => {
                                    if (isAuth === false) {
                                        setLoginInPage('login');
                                        setLoginMenuVisible(true);
                                    } else {
                                        onFavouriteButtonClicked(merchant);
                                    }
                                }}
                                {...merchant}
                            />
                        </div>
                    );
                })}
            </SnapScroll>
        </div>
    );
};

export default MerchantsSnapScroll;
