import React, { FC } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { MerchantCard, RoundedButton } from 'components';
import ReactHtmlParser from 'react-html-parser';
import { merchantIndexToMerchantCardProps } from 'utils/merchant';
import type { MerchantIndex } from 'apis/merchant-algolia.d';
import urlEncode from 'utils/url-encode';
import _ from 'lodash';

type Props = {
    content: string;
    userFavouriteMerchantIds: string[] | null;
    merchants: { [key: string]: MerchantIndex };
    onMerchantCardClicked: (m: MerchantIndex) => void;
    onMerchantCardBookmarkClicked: (m: MerchantIndex) => void;
};

const useStyles = createUseStyles((theme) => ({
    detailBtnContainer: {
        display: 'inline-flex',
        flexDirection: 'row',
        marginTop: theme.spacing.spacing16,
        '& .button': {
            backgroundColor: theme.color.btnPrimary,
            border: 'none',
        },
    },
}));

const BlogContent: FC<Props> = (props) => {
    const {
        content,
        userFavouriteMerchantIds,
        merchants,
        onMerchantCardClicked,
        onMerchantCardBookmarkClicked,
    } = props;
    // hooks
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });

    return (
        <>
            {ReactHtmlParser(content, {
                transform: (node, index) => {
                    if (
                        node.type === 'tag' &&
                        node.name === 'a' &&
                        _.get(node, 'attribs.class') === 'custom-loko-button'
                    ) {
                        console.log('attr', JSON.stringify(node.attribs));
                        return (
                            <div
                                className={`${classes.detailBtnContainer} loko-button`}
                                key={`blog-content-loko-button-${index}`}
                            >
                                <RoundedButton
                                    className="button"
                                    title={_.get(node, 'attribs.data-title')}
                                    to={''}
                                    href={_.get(node, 'attribs.href')}
                                    // target="_blank"
                                    rel="noreferrer"
                                />
                            </div>
                        );
                    }
                    if (
                        node.type === 'tag' &&
                        node.name === 'div' &&
                        _.get(node, 'attribs.class') === 'loko-merchant'
                    ) {
                        const merchantId: string = _.get(
                            node,
                            'attribs.data-id',
                        );
                        const merchantIndex = merchants[merchantId];

                        if (merchantIndex == null) {
                            console.error(
                                `load ${merchantId} loko-merchant card failed`,
                            );
                            return null;
                        }
                        const m = merchantIndexToMerchantCardProps({
                            merchantIndex,
                            isBookmark:
                                userFavouriteMerchantIds?.includes(
                                    merchantIndex.objectID,
                                ) || false,
                        });
                        return (
                            <MerchantCard
                                key={`blog-content-loko-merchant-card-${index}`}
                                className="merchant-card"
                                cardType="big"
                                onClick={() =>
                                    onMerchantCardClicked(merchantIndex)
                                }
                                to={`/merchant/${urlEncode(m.title)}-${
                                    m.objectId
                                }`}
                                bookmarkBtnOnClick={() => {
                                    onMerchantCardBookmarkClicked(
                                        merchantIndex,
                                    );
                                }}
                                {...m}
                            />
                        );
                    }
                    return undefined;
                },
            })}
        </>
    );
};

export default BlogContent;
