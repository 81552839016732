export const getUserFavourites = async (): Promise<UserFavourite[]> => {
    return [];
    // return axiosWithAuth()
    //     .get('/user/favourites')
    //     .then((r) => r.data as UserFavourite[]);
};

//add
export const addUserFavourites = async (
    merchantIds: string[],
): Promise<UserFavourite[]> => {
    return [];
    // return axiosWithAuth()
    //     .post('/user/favourites', { merchant_ids: merchantIds })
    //     .then((r) => r.data as UserFavourite[]);
};

//delete
export const removeUserFavourites = async (
    merchantIds: string[],
): Promise<UserFavourite[]> => {
    return [];
    // return axiosWithAuth()
    //     .delete('/user/favourites', { data: { merchant_ids: merchantIds } })
    //     .then((r) => r.data as UserFavourite[]);
};

export type UserFavourite = {
    id: number;
    createdAt: string;
    updatedAt: string | null;
    deletedAt: string | null;
    userUid: string;
    merchantId: string;
};
