import React, { FC, useState, useEffect, useRef, HTMLAttributes } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { scrollSnapToNext, getScrollSnapPositions } from 'scroll-snap-api';
import { Image } from 'components';
import assetsPath from 'utils/assets-path';
import _ from 'lodash';

interface Props extends HTMLAttributes<HTMLDivElement> {
    hideControlBtn?: boolean;
    padding: number;
}

const useStyles = createUseStyles((theme) => ({
    merchantSnapContainer: {
        position: 'relative',
        marginLeft: (props: Props) => -props.padding,
        marginRight: (props: Props) => -props.padding,
    },
    merchantSnapControlBtnContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: 1,
        pointerEvents: 'none',
        left: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing.spacing16,
        visibility: 'hidden',
        opacity: 0,
        transition: [
            ['visibility', '0s', `0.1s`],
            ['opacity', `0.1s`, 'linear'],
        ],
    },
    merchantSnapControlBtnContainerVisible: {
        extend: 'merchantSnapControlBtnContainer',
        visibility: 'visible',
        opacity: 1,
        transition: [['opacity', `0.1s`, 'linear']],
    },
    merchantSnapControlBtn: {
        pointerEvents: 'all',
        width: 40,
        height: 40,
        borderRadius: 80,
        background: theme.color.btnThird,
        boxShadow: [
            ['0px', '0px', '4px', 'rgba(44, 45, 37, 0.1)'],
            ['0px', '4px', '8px', 'rgba(44, 45, 37, 0.2)'],
        ],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        visibility: 'visible',
        opacity: 1,
        transition: [['opacity', `0.1s`, 'linear']],
    },
    merchantSnapControlBtnHide: {
        visibility: 'hidden',
        opacity: 0,
        transition: [
            ['visibility', '0s', `0.1s`],
            ['opacity', `0.1s`, 'linear'],
        ],
    },
    merchantSnapScrollView: {
        paddingLeft: (props: Props) => props.padding,
        paddingRight: (props: Props) => props.padding,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        overflowX: 'scroll',
        overflowY: 'hidden',
        overscrollBehavior: 'auto',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        WebkitOverflowScrolling: 'touch',
        scrollSnapType: [['x', 'mandatory']],
        scrollPaddingLeft: (props: Props) => `${props.padding}px`,
        scrollPaddingInlineStart: (props: Props) => `${props.padding}px`,
        '&>div': {
            scrollSnapAlign: 'start',
        },
        '&>div:last-child': {
            marginRight: 0,
            position: 'relative',
            '&::after': {
                position: 'absolute',
                left: '100%',
                height: 1,
                width: (props: Props) => props.padding,
                display: 'block',
                content: ['""'],
            },
        },
        '&::-webkit-scrollbar': {
            display: 'none',
            width: 0,
            height: 0,
        },
    },
}));

const SnapScoll: FC<Props> = (props) => {
    const { children, className, hideControlBtn } = props;

    // hooks
    const theme = useTheme();
    const classes = useStyles({ theme, ...props });

    const [isHideMerchantListLeftBtn, setIsHideMerchantListLeftBtn] = useState(
        false,
    );
    const [
        isHideMerchantListRightBtn,
        setIsHideMerchantListRightBtn,
    ] = useState(false);

    const snapScroll = useRef<HTMLElement>();
    const isInitScroll = useRef<boolean>(false);

    useEffect(() => {
        if (isInitScroll.current === true) {
            return;
        }

        if (React.Children.count(children) > 0) {
            isInitScroll.current = true;
            setTimeout(() => {
                onScrollScrollView();
                console.log('init onScrollScrollView');
            }, 1);
        }
    }, [children]);

    // UI event function
    const onScrollScrollView = () => {
        if (snapScroll.current != null) {
            const scroll = getScrollSnapPositions(snapScroll.current);
            const currentPos = snapScroll.current.scrollLeft;

            if (currentPos <= 0) {
                // hide left
                if (isHideMerchantListLeftBtn === false) {
                    setIsHideMerchantListLeftBtn(true);
                }
            } else if (isHideMerchantListLeftBtn === true) {
                setIsHideMerchantListLeftBtn(false);
            }

            const lastSnapPosition = _.last(scroll.x);
            if (lastSnapPosition == null || currentPos >= lastSnapPosition) {
                // hide right
                if (isHideMerchantListRightBtn === false) {
                    setIsHideMerchantListRightBtn(true);
                }
            } else if (isHideMerchantListRightBtn === true) {
                setIsHideMerchantListRightBtn(false);
            }
        }
    };

    const nextBtnOnClick = () => {
        if (snapScroll.current != null) {
            const scroll = getScrollSnapPositions(snapScroll.current);
            const currentPos = snapScroll.current.scrollLeft;

            if (scroll.x.length < 2) {
                return;
            }

            const snapSize = scroll.x[1] - scroll.x[0];
            const lastSnapPosition = _.last(scroll.x);
            if (lastSnapPosition == null) {
                return;
            }

            if (lastSnapPosition - currentPos <= snapSize) {
                snapScroll.current.scrollTo({
                    top: 0,
                    left: lastSnapPosition * 2,
                    behavior: 'smooth',
                });
            } else {
                scrollSnapToNext(snapScroll.current, 'right');
            }
        }
    };

    const prevBtnOnClick = () => {
        if (snapScroll.current != null) {
            scrollSnapToNext(snapScroll.current, 'left');
        }
    };

    // logic function

    // render function

    return (
        <div
            className={`${classes.merchantSnapContainer} ${
                className != null ? className : ''
            }`}
        >
            <div
                className={`${
                    hideControlBtn === true
                        ? classes.merchantSnapControlBtnContainer
                        : classes.merchantSnapControlBtnContainerVisible
                } is-hidden-mobile`}
            >
                <a
                    className={`${classes.merchantSnapControlBtn} ${
                        isHideMerchantListLeftBtn === true
                            ? classes.merchantSnapControlBtnHide
                            : ''
                    }`}
                    onClick={prevBtnOnClick}
                    role="button"
                    tabIndex={0}
                >
                    <Image
                        src={assetsPath('arrow-chevron-left')}
                        width="24"
                        height="24"
                    />
                </a>
                <a
                    className={`${classes.merchantSnapControlBtn} ${
                        isHideMerchantListRightBtn === true
                            ? classes.merchantSnapControlBtnHide
                            : ''
                    }`}
                    onClick={nextBtnOnClick}
                    role="button"
                    tabIndex={0}
                >
                    <Image
                        src={assetsPath('arrow-chevron-right')}
                        width="24"
                        height="24"
                    />
                </a>
            </div>
            <div
                className={classes.merchantSnapScrollView}
                ref={(ref) => {
                    snapScroll.current = ref as HTMLElement;
                }}
                onScroll={onScrollScrollView}
            >
                {children}
            </div>
        </div>
    );
};

SnapScoll.defaultProps = {
    hideControlBtn: true,
    padding: 0,
};

export default SnapScoll;
