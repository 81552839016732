import i18n from 'utils/i18n';

export type Sorting = {
    id: string;
    enTitle: string;
    zhTitle: string;
};

const hottest: Sorting = {
    id: 'hottest',
    enTitle: 'Hottest',
    zhTitle: '最熱門',
};

const newest: Sorting = {
    id: 'newest',
    enTitle: 'Newest',
    zhTitle: '新至舊',
};

const highestRating: Sorting = {
    id: '3',
    enTitle: 'Highest Rating',
    zhTitle: '新至舊',
};

const distance: Sorting = {
    id: '4',
    enTitle: 'Distance',
    zhTitle: '距離近至遠',
};

export const getCurrentLangSortingTitle = (sorting: Sorting) => {
    if (i18n.language === 'en') {
        return sorting.enTitle;
    }
    return sorting.zhTitle;
};

const SortingConfig = [
    hottest,
    newest,
    // , highestRating, distance
] as const;

export default SortingConfig;
