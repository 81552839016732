import create from 'zustand';
import * as _ from 'lodash';
import { getFeatured } from 'apis/featured';
import type { Search, Marketing } from 'apis/featured';
import type {
    MerchantIndex,
    CategoryIndex,
    LocationIndex,
} from 'apis/merchant-algolia.d';

type State = {
    inited: boolean;
    searches: Search[];
    categories: CategoryIndex[];
    merchants: MerchantIndex[];
    banners: {
        mobile: { image: string; url?: string }[];
        desktop: { image: string; url?: string }[];
    };
    locations: LocationIndex[];
    marketings: {
        mobile: Marketing[];
        desktop: Marketing[];
    };
    fetch: () => void;
};

export const useFeaturedStore = create<State>((set, get) => ({
    inited: false,
    searches: [],
    categories: [],
    merchants: [],
    banners: { mobile: [], desktop: [] },
    locations: [],
    marketings: { mobile: [], desktop: [] },
    fetch: async () => {
        if (get().inited) {
            return;
        }
        const result = await getFeatured();
        set({
            ..._.omit(result, [
                'mobile-banners',
                'desktop-banners',
                'mobile-marketings',
                'desktop-marketings',
            ]),
            inited: true,
            merchants: _.compact(result.merchants),
            banners: {
                mobile: result['mobile-banners'],
                desktop: result['desktop-banners'],
            },
            marketings: {
                mobile: result['mobile-marketings'],
                desktop: result['desktop-marketings'],
            },
        });
    },
}));
