import searchInsights from 'search-insights';
import { useUserStore } from 'store/user';
import { useAutoCompleteSearch } from 'store/auto-complete';
import { useSearchStore } from 'store/search';

searchInsights('init', {
    appId: `${
        process.env.REACT_APP_ALGOLIA_APP_ID || (window as any).ALGOLIA_APP_ID
    }`,
    apiKey: `${
        process.env.REACT_APP_ALGOLIA_MERCHANT_API_KEY ||
        (window as any).ALGOLIA_MERCHANT_API_KEY
    }`,
});

let userToken: string | null = null;
useUserStore.subscribe((state) => {
    if (state.user && state.user.uid) {
        userToken = state.user.uid;
    } else {
        userToken = null;
    }
});
let autoCompleteLastQueryId: string | null = null;
useAutoCompleteSearch.subscribe((state) => {
    autoCompleteLastQueryId = state.lastQueryId || null;
});

let searchLastQueryId: string | null = null;
useSearchStore.subscribe((state) => {
    searchLastQueryId = state.lastQueryId || null;
});

export const clickedMerchantAtMerchants = (
    merchantId: string,
    position: number,
) => {
    searchInsights('clickedObjectIDsAfterSearch', {
        userToken: userToken || undefined,
        eventName: 'Click merchant card at search result',
        index: `${process.env.REACT_APP_ALGOLIA_MERCHANT_INDEX_NAME}`,
        queryID: `${searchLastQueryId}`,
        objectIDs: [merchantId],
        positions: [position],
    } as any);
};

export const clickedMerchantAtAutoComplete = (
    merchantId: string,
    position: number,
) => {
    searchInsights('clickedObjectIDsAfterSearch', {
        userToken: userToken || undefined,
        eventName: 'Click auto-complete result',
        index: `${process.env.REACT_APP_ALGOLIA_MERCHANT_INDEX_NAME}`,
        queryID: `${autoCompleteLastQueryId}`,
        objectIDs: [merchantId],
        positions: [position],
    } as any);
};

export const clickedMerchantCard = (merchantId: string) => {
    searchInsights('clickedObjectIDs', {
        index: `${process.env.REACT_APP_ALGOLIA_MERCHANT_INDEX_NAME}`,
        eventName: 'Click merchant card',
        objectIDs: [merchantId],
        userToken: userToken || undefined,
    });
};

export const viewedMerchant = (merchantsId: string) => {
    searchInsights('viewedObjectIDs', {
        index: `${process.env.REACT_APP_ALGOLIA_MERCHANT_INDEX_NAME}`,
        eventName: 'Browse to merchants',
        objectIDs: [merchantsId],
        userToken: userToken || undefined,
    });
};
