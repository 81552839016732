import deliveroo from 'assets/takeaway/deliveroo.png';
import foodpanda from 'assets/takeaway/foodpanda.png';
import dimOrder from 'assets/takeaway/dim-order.png';
import hktvMall from 'assets/takeaway/hktv-mall.png';
import lingduck from 'assets/takeaway/lingduck.png';
import pokeGuide from 'assets/takeaway/poke-guide.png';
import shopper from 'assets/takeaway/shopper.png';
import 懶人煮意 from 'assets/takeaway/懶人煮意.png';
import 自家速遞 from 'assets/takeaway/自家速遞.png';
import 跨區團購 from 'assets/takeaway/跨區團購.png';

export const TAKEAWAY_SERVICE_ASSETS = {
    'Food Panda': foodpanda,
    Deliveroo: deliveroo,
    'HKTV EXPRESS': hktvMall,
    Lingduck: lingduck,
    自家速遞,
    跨區團購,
    Shopper: shopper,
    'Dim Order': dimOrder,
    'Poke Guide': pokeGuide,
    '懶人煮意(餸包湯包)': 懶人煮意,
};
