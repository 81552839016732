import type { MerchantIndex } from 'apis/merchant-algolia.d';
import { illustrationPath } from 'utils/assets-path';
import * as _ from 'lodash';

export const patchEmptyLogoAndCover = (merchant: MerchantIndex) => {
    let newMerchant = merchant;
    if (_.isEmpty(merchant.logo)) {
        newMerchant = {
            ...newMerchant,
            logo: {
                original: illustrationPath('logo-empty-placeholder-3000.png'),
                200: illustrationPath('logo-empty-placeholder-200.png'),
                500: illustrationPath('logo-empty-placeholder-500.png'),
                2000: illustrationPath('logo-empty-placeholder-3000.png'),
            },
        };
    }
    return newMerchant;
};

export const getStandards = (merchant: MerchantIndex) => {
    if (merchant.categories_1st?.includes('餐飲')) {
        return merchant.fab_loko_standards;
    }
    return merchant.product_loko_standards;
};

export const getTotalNumberOfStandards = (merchant: MerchantIndex) => {
    if (merchant.categories_1st?.includes('餐飲')) {
        return 6;
    }
    return 4;
};

export const isFAndB = (merchant: MerchantIndex) => {
    if (merchant.categories_1st?.includes('餐飲')) {
        return true;
    }
    return false;
};

export const merchantIndexToMerchantCardProps = (params: {
    merchantIndex: MerchantIndex;
    isBookmark: boolean;
}) => {
    const { merchantIndex, isBookmark } = params;
    return {
        objectId: merchantIndex.objectID,
        merchantImage: merchantIndex.cover?.[500],
        merchantProductImages: merchantIndex.product_images?.map((p) => p[500]),
        merchantLogo: merchantIndex.logo?.[200],
        location: merchantIndex.districts?.join(' ') || '',
        category: _.chain(merchantIndex)
            .pick(['categories_1st'])
            .values()
            .flatten()
            .join(' ')
            .value(),
        title: merchantIndex.name,
        isFAndB: isFAndB(merchantIndex),
        isFeature: _.includes(getStandards(merchantIndex), '本地特色餐廳'),
        isConscience: _.includes(getStandards(merchantIndex), '本地良心餐廳'),
        isKindness:
            _.includes(getStandards(merchantIndex), '本地善心餐廳') ||
            _.includes(getStandards(merchantIndex), '本地關愛餐廳'),
        isBrand: _.includes(getStandards(merchantIndex), '本地品牌餐廳'),
        isEnvironment: _.includes(getStandards(merchantIndex), '本地環保餐廳'),
        isIngredient: _.includes(getStandards(merchantIndex), '本地食材餐廳'),
        isLocalStore: _.includes(getStandards(merchantIndex), '香港商家'),
        isLocalDesign: _.includes(getStandards(merchantIndex), '香港設計'),
        isLocalManufacture: _.includes(getStandards(merchantIndex), '香港生產'),
        isLocalMaterial:
            _.includes(getStandards(merchantIndex), '香港來源') ||
            _.includes(getStandards(merchantIndex), '香港原料'),
        isClosed: merchantIndex.is_closed,
        numberOfFavourites: merchantIndex.num_of_favourites || 0,
        hashTags: merchantIndex.tags || [],
        isBookmark,
        status: merchantIndex.status,
        isOctopus: _.get(merchantIndex, 'payment_methods', '').includes(
            'octopus',
        ),
        isTapAndGo: _.get(merchantIndex, 'payment_methods', '').includes(
            'tap_and_go',
        ),
        isAlipay: _.get(merchantIndex, 'payment_methods', '').includes(
            'alipay',
        ),
        isWechatPay: _.get(merchantIndex, 'payment_methods', '').includes(
            'wechat_pay',
        ),
        isBocPay: _.get(merchantIndex, 'payment_methods', '').includes(
            'boc_pay',
        ),
        isPayme: _.get(merchantIndex, 'payment_methods', '').includes('payme'),
        openriceRating: merchantIndex.openrice_rating,
        openriceReviewCount: merchantIndex.openrice_review_count,
        igFollowerCount: merchantIndex.ig_follower_count,
        takeawayMethods: merchantIndex.takeaway_methods,
    };
};

export const getFbPageName = (merchant: MerchantIndex) => {
    let fbPageName = '';

    const fbUrls = merchant.facebook_urls;
    if (fbUrls != null && fbUrls.length > 0) {
        const fbUrl = fbUrls[0];
        const stringArray = fbUrl.split('/');
        if (stringArray.length > 0) {
            fbPageName = stringArray[stringArray.length - 1];

            if (fbPageName.length === 0) {
                fbPageName = stringArray[stringArray.length - 2];
            }
        }
    }

    return fbPageName;
};

export const getIgPageAcName = (merchant: MerchantIndex) => {
    let igAcName = '';

    const igUrls = merchant.ig_urls;
    if (igUrls != null && igUrls.length > 0) {
        const igUrl = igUrls[0];
        const stringArray = igUrl.split('/');
        if (stringArray.length > 0) {
            igAcName = stringArray[stringArray.length - 1];

            if (igAcName.length === 0) {
                igAcName = stringArray[stringArray.length - 2];
            }
        }
    }

    return igAcName;
};
