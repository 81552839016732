import { DefaultTheme } from 'react-jss';

const colorPool = {
    dark: '#2C2D25',
    darkOp1: 'rgba(44, 45, 37, 0.6)',
    darkOp2: 'rgba(44, 45, 37, 0.4)',
    darkOp3: 'rgba(44, 45, 37, 0.2)',
    darkOp4: 'rgba(44, 45, 37, 0.1)',
    darkOp5: 'rgba(44, 45, 37, 0.05)',
    darkOp6: 'rgba(44, 45, 37, 0.03)',
    light: '#FFFFFF',
    lightOp1: 'rgba(255, 255, 255, 0.8)',
    lightOp2: 'rgba(255, 255, 255, 0.6)',
    lightOp3: 'rgba(255, 255, 255, 0.2)',
    lightOp4: 'rgba(255, 255, 255, 0.05)',
    primary: '#F4E04D',
    primaryOp1: 'rgba(244, 224, 77, 0.8)',
    primaryOp2: 'rgba(244, 224, 77, 0.5)',
    primaryOp3: 'rgba(244, 224, 77, 0.4)',
    primaryOp4: 'rgba(244, 224, 77, 0.2)',
    primaryDisabled: '#EDE8C3',
    secondary: '#00AFB1',
    secondaryOp1: 'rgba(0, 206, 209, 0.3)',
    stardard: '#87C306',
    favourite: '#EC6345',
};

const lightModeColor = {
    color: {
        bgPrimary: colorPool.dark,
        bgPrimaryOp1: colorPool.darkOp1,
        bgPrimaryOp3: colorPool.darkOp3,
        bgPrimaryOp5: colorPool.darkOp5,
        bgPrimaryOp6: colorPool.darkOp6,
        bgSecondary: colorPool.light,
        bgThird: colorPool.primary,
        textPrimary: colorPool.dark,
        textPrimaryOp1: colorPool.darkOp1,
        textSecondary: colorPool.secondary,
        textThird: colorPool.light,
        textFour: colorPool.stardard,
        textFive: colorPool.favourite,
        btnPrimary: colorPool.primaryOp1,
        btnSecondary: colorPool.dark,
        btnSecondaryOp4: colorPool.darkOp4,
        btnSecondaryOp5: colorPool.darkOp5,
        btnThird: colorPool.light,
        borderPrimary: colorPool.dark,
        borderPrimaryOp2: colorPool.darkOp2,
        borderPrimaryOp3: colorPool.darkOp3,
        borderPrimaryOp4: colorPool.darkOp4,
        svgFillPrimary: colorPool.secondary,
    },
};

const darkModeColor = {
    color: {
        bgPrimary: colorPool.dark,
        bgPrimaryOp1: colorPool.darkOp1,
        bgPrimaryOp3: colorPool.darkOp3,
        bgPrimaryOp5: colorPool.darkOp5,
        bgPrimaryOp6: colorPool.darkOp6,
        bgSecondary: colorPool.light,
        bgThird: colorPool.primary,
        textPrimary: colorPool.dark,
        textPrimaryOp1: colorPool.darkOp1,
        textSecondary: colorPool.secondary,
        textThird: colorPool.light,
        textFour: colorPool.stardard,
        textFive: colorPool.favourite,
        btnPrimary: colorPool.primaryOp1,
        btnSecondary: colorPool.dark,
        btnSecondaryOp4: colorPool.darkOp4,
        btnSecondaryOp5: colorPool.darkOp5,
        btnThird: colorPool.light,
        borderPrimary: colorPool.dark,
        borderPrimaryOp2: colorPool.darkOp2,
        borderPrimaryOp3: colorPool.darkOp3,
        borderPrimaryOp4: colorPool.darkOp4,
        svgFillPrimary: colorPool.secondary,
    },
};

const config = {
    spacing: {
        spacing2: 2,
        spacing4: 4,
        spacing8: 8,
        spacing12: 12,
        spacing16: 16,
        spacing20: 20,
        spacing24: 24,
        spacing32: 32,
        spacing36: 36,
        spacing40: 40,
        spacing48: 48,
        spacing56: 56,
        spacing64: 64,
        spacing72: 72,
        spacing80: 80,
        spacing120: 120,
        spacing128: 128,
    },
    fontSize: {
        size12: '12px',
        size16: '16px',
        size18: '18px',
        size24: '24px',
        size40: '40px',
        size48: '48px',
    },
    fontWeight: {
        bold: 'bold',
        normal: 'normal',
    },
    lineHeight: {
        size16: '16px',
        size24: '24px',
        size32: '32px',
        size40: '40px',
        size56: '56px',
    },
    borderRadius: {
        size4: '4px',
        size8: '8px',
        size20: '20px',
    },
    breakpoints: {
        tablet: 1024,
        desktop: 1024,
        widescreen: 1280,
        fullhd: 1440,
    },
};

const mediaQueries = {
    mobile: `@media (max-width: ${config.breakpoints.tablet - 1}px)`,
    desktop: `@media (min-width: ${config.breakpoints.tablet}px)`,
};

const lightConfig: DefaultTheme = {
    ...lightModeColor,
    ...config,
    mediaQueries,
};

const darkConfig: DefaultTheme = {
    ...darkModeColor,
    ...config,
    mediaQueries,
};

export { lightConfig, darkConfig };
