import React, { FC, HTMLAttributes } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { Image } from 'components';
import assetsPath from 'utils/assets-path';
import { useTranslation } from 'react-i18next';

interface Props extends HTMLAttributes<HTMLDivElement> {
    title: string;
    merchantLogo?: string;
    isVerify?: boolean;
    igFollowerCount?: number;
    cardType?: 'small' | 'big';
}

const useStyles = createUseStyles((theme) => ({
    infoContainer: {
        display: 'flex',
        width: '100%',
    },
    logoContainer: {
        borderRadius: theme.borderRadius.size8,
        overflow: 'hidden',
        height: 48,
        width: 48,
        flex: 'none',
        '& img': {
            objectFit: 'contain',
        },
    },
    titleContainer: {
        flex: 1.0,
        minWidth: 0,
        maxHeight: 48,
        paddingLeft: (props: Props) =>
            props.cardType === 'small'
                ? theme.spacing.spacing8
                : theme.spacing.spacing16,
    },
    lokoStoreImage: {
        marginRight: theme.spacing.spacing8,
    },
    statView: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    statContent: {
        marginLeft: theme.spacing.spacing4,
        fontSize: theme.fontSize.size12,
        lineHeight: theme.lineHeight.size16,
        color: theme.color.textPrimary,
    },
    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.fontSize.size24,
        fontWeight: theme.fontWeight.bold,
        color: theme.color.textPrimary,
        marginBottom: theme.spacing.spacing4,
        minWidth: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}));

const MerchantInfo: FC<Props> = (props) => {
    const {
        className,
        title,
        merchantLogo,
        igFollowerCount,
        cardType,
        isVerify,
        ...otherProps
    } = props;
    // hooks
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });

    const { t } = useTranslation();

    // UI event function

    // logic function

    // render function

    return (
        <div className={className} {...otherProps}>
            <div className={`${classes.infoContainer}`}>
                <div className={classes.logoContainer}>
                    <Image
                        width="100%"
                        height="inherit"
                        layout="fixed-height"
                        lazyLoad
                        src={merchantLogo}
                    />
                </div>
                <div className={`${classes.titleContainer}`}>
                    <div className={classes.title}>{title}</div>
                    <div>
                        <div className="columns is-gapless is-mobile">
                            {isVerify === true && (
                                <div className="column is-narrow">
                                    <Image
                                        className={classes.lokoStoreImage}
                                        width="92"
                                        height="16"
                                        src={assetsPath(
                                            'loko-store-merchant-card',
                                        )}
                                    />
                                </div>
                            )}

                            {cardType === 'big' && igFollowerCount != null && (
                                <div className="column is-narrow">
                                    <div className={classes.statView}>
                                        <Image
                                            width="16"
                                            height="16"
                                            src={assetsPath('instagram')}
                                        />
                                        <p className={classes.statContent}>
                                            {t('merchant.ig-follower-count', {
                                                count: igFollowerCount,
                                            })}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MerchantInfo;
