import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Image } from 'components';
import * as _ from 'lodash';
import { useAutoCompleteSearch } from 'store/auto-complete';
import { useFeaturedStore } from 'store/featured';
import { useUiStore } from 'store/ui';
import { useDebouncedEffect } from 'utils/use-debounced-effect';
import type {
    MerchantIndex,
    CategoryIndex,
    LocationIndex,
} from 'apis/merchant-algolia.d';
import { getRecentSearches, addRecentSearchers } from 'apis/recent-search';
import HottestSearch from 'components/search-bar/hottest-search';
import SectionHeader from 'components/search-bar/section-header';
import SearchResult from 'components/search-bar/search-result';
import HottestCategories from 'components/search-bar/hottest-categories';
import HottestLocations from 'components/search-bar/hottest-locations';
import Dialog from '@mui/material/Dialog';
import Slide, { SlideProps } from '@mui/material/Slide';
import { generateUrl } from 'utils/i18n';
import type { Search } from 'apis/featured';
import assetsPath from 'utils/assets-path';
import { merchantPathBySearch } from 'utils/merchants-path';
import { searchBoxKeywordSearched } from 'utils/gtm';
import urlEncode from 'utils/url-encode';

type Props = {
    isVisible: boolean;
    onCloseClicked: () => void;
};

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const getSearchSet = (searchStore: {
    merchants: MerchantIndex[];
    locations: LocationIndex[];
    categories: CategoryIndex[];
}) => {
    let resultToPick = 9;
    const merchants = _.take(searchStore.merchants, resultToPick);
    resultToPick -= _.size(merchants);
    const categories = _.take(searchStore.categories, resultToPick);
    resultToPick -= _.size(categories);
    const locations = _.take(searchStore.locations, resultToPick);
    return { merchants, categories, locations };
};

const SearchBarMobile = (props: Props) => {
    const { isVisible, onCloseClicked } = props;
    const { t } = useTranslation();
    const navigation = useNavigate();
    const inputRef = useRef<HTMLInputElement>(null);
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [isSearching, setIsSearching] = useState(false);
    const [inputState, setInputState] = useState({ value: '' });

    const searchStore = useAutoCompleteSearch(
        useCallback((state) => state, []),
    );
    const { setMobileSearchBarVisible } = useUiStore(
        useCallback((state) => state, []),
    );
    const featuredStore = useFeaturedStore(useCallback((state) => state, []));

    const inputChanged = useCallback((e) => {
        const inputValue = e.target.value;
        setInputState({ value: inputValue });
    }, []);

    const clearKeyword = useCallback(() => {
        setInputState({ value: '' });
    }, []);

    useDebouncedEffect(
        () => {
            setIsSearching(!!inputState.value);
            if (inputState.value) {
                addRecentSearchers(inputState.value);
                searchStore.fetchAll({ query: inputState.value });
            }
        },
        1000,
        [inputState],
    );

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                inputRef.current?.focus();
            }, 300);
        }
    }, [isVisible]);

    const goSearch = useCallback((search: Search) => {
        setMobileSearchBarVisible(false);
        searchBoxKeywordSearched(search.name || '', 'Keyword Search');
        navigation(merchantPathBySearch(search));
    }, []);

    const goToMerchant = useCallback((merchant: MerchantIndex) => {
        setMobileSearchBarVisible(false);

        navigation(
            generateUrl(
                `/merchant/${urlEncode(merchant.name)}-${merchant.objectID}`,
            ),
        );
    }, []);

    const onKeyPress = useCallback(
        (e) => {
            if (inputState.value && e.key === 'Enter') {
                searchBoxKeywordSearched(
                    searchStore.searchKeyword || '',
                    'Keyword Search',
                );
                goToKeyword(inputState.value);
            }
        },
        [inputState],
    );

    const goToKeyword = useCallback((keyword: string) => {
        setMobileSearchBarVisible(false);

        navigation(generateUrl(`/search?keyword=${keyword}`));
    }, []);

    const goToLocation = useCallback((location: LocationIndex) => {
        setMobileSearchBarVisible(false);

        navigation(
            generateUrl(`/district/${location.parent}/${location.objectID}`),
        );
    }, []);

    const goToCategory = useCallback((category: CategoryIndex) => {
        setMobileSearchBarVisible(false);

        navigation(generateUrl(`/${category.parent}/${category.objectID}`));
    }, []);

    if (!isVisible) {
        return null;
    }

    const recentSearches = getRecentSearches();

    const { merchants, categories, locations } = getSearchSet(searchStore);
    const numOfSearchResult =
        merchants.length + categories.length + locations.length;

    const resultComponent = isSearching ? (
        <div>
            {numOfSearchResult > 0 ? (
                <SectionHeader title={t('search-bar.section-header-hits')} />
            ) : null}
            {merchants.map((m) => (
                <SearchResult
                    key={`search-bar-mobile-merchant-${m.objectID}`}
                    text={m.name}
                    icon={m.logo?.[200] || ''}
                    iconSize="32"
                    onClicked={() => {
                        searchBoxKeywordSearched(
                            searchStore.searchKeyword || '',
                            'Suggested Results',
                        );
                        goToMerchant(m);
                    }}
                />
            ))}
            {categories.map((m) => (
                <SearchResult
                    key={`search-bar-mobile-category-${m.objectID}`}
                    text={m.name}
                    icon={m.icon ? assetsPath(m.icon) : ''}
                    iconSize="32"
                    onClicked={() => {
                        searchBoxKeywordSearched(
                            searchStore.searchKeyword || '',
                            'Suggested Results',
                        );
                        goToCategory(m);
                    }}
                />
            ))}
            {locations.map((m) => (
                <SearchResult
                    key={`search-bar-mobile-location-${m.objectID}`}
                    text={m.name}
                    icon={m.icon ? assetsPath(m.icon) : ''}
                    iconSize="32"
                    onClicked={() => {
                        searchBoxKeywordSearched(
                            searchStore.searchKeyword || '',
                            'Suggested Results',
                        );
                        goToLocation(m);
                    }}
                />
            ))}

            <SectionHeader title={t('search-bar.section-header-recent')} />
            {recentSearches.map((k) => (
                <SearchResult
                    key={`search-bar-mobile-recent-search-${k}`}
                    text={k}
                    icon={assetsPath('history')}
                    iconSize="32"
                    onClicked={() => {
                        searchBoxKeywordSearched(k || '', 'Search History');
                        goToKeyword(k);
                    }}
                />
            ))}
        </div>
    ) : (
        <div>
            <SectionHeader title={t('header.hottest-search')} />
            <HottestSearch
                onClicked={(k) => {
                    searchBoxKeywordSearched(k.name || '', 'Popular Search');
                    goSearch(k);
                }}
                isMobile
                searches={featuredStore.searches}
            />
            <div className={classes.separator}> </div>

            <SectionHeader title={t('home.hottest-category')} />
            {
                <HottestCategories
                    categories={_.take(featuredStore.categories, 6)}
                    onClicked={(c) => {
                        searchBoxKeywordSearched(
                            c.name || '',
                            'Popular Search',
                        );
                        goToCategory(c);
                    }}
                />
            }
            <div className={classes.separator}> </div>

            <SectionHeader title={t('search-bar.hottest-location')} />
            <HottestLocations
                locations={_.take(featuredStore.locations, 6)}
                onClicked={(l) => {
                    searchBoxKeywordSearched(l.name || '', 'Popular Search');
                    goToLocation(l);
                }}
            />
            <div className={classes.separator}> </div>

            <SectionHeader title={t('search-bar.section-header-recent')} />
            {recentSearches.map((k) => (
                <SearchResult
                    key={`search-bar-mobile-recent-search-${k}`}
                    text={k}
                    icon={assetsPath('history')}
                    iconSize="24"
                    onClicked={() => {
                        searchBoxKeywordSearched(k || '', 'Search History');
                        goToKeyword(k);
                    }}
                />
            ))}
        </div>
    );

    return (
        <Dialog
            fullScreen
            open={isVisible}
            onClose={onCloseClicked}
            aria-labelledby="responsive-dialog-title"
            TransitionComponent={Transition}
        >
            <div className={classes.container}>
                <div className={classes.inputContainer}>
                    <a
                        className={classes.closeBtn}
                        onClick={onCloseClicked}
                        role="button"
                        tabIndex={0}
                    >
                        <Image
                            src={assetsPath('cross')}
                            height="24"
                            width="24"
                        />
                    </a>

                    <div className={classes.inputBg}>
                        <input
                            ref={inputRef}
                            value={inputState.value}
                            className={classes.input}
                            onChange={inputChanged}
                            onKeyPress={onKeyPress}
                            placeholder={t('search-bar.input-placeholder')}
                        ></input>
                        <a
                            className={classes.clearBtn}
                            onClick={clearKeyword}
                            role="button"
                            tabIndex={0}
                        >
                            <Image
                                src={assetsPath('clear')}
                                height="24"
                                width="24"
                            />
                        </a>
                    </div>
                </div>
                <div className={classes.resultContainer}>{resultComponent}</div>
            </div>
        </Dialog>
    );
};

export default SearchBarMobile;

const useStyles = createUseStyles((theme) => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 31,
        width: '100vw',
        height: '100vh',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 0,

        width: '100%',
        height: '100%',

        background: '#FFFFFF',
        overflow: 'hidden',
        overscrollBehavior: 'contain',
    },
    inputContainer: {
        display: 'flex',
        width: '100%',
        padding: `${theme.spacing.spacing16}px ${theme.spacing.spacing16}px`,
        height: 72,
        '& > *': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    },
    inputBg: {
        width: '100%',
        padding: `${theme.spacing.spacing8}px ${theme.spacing.spacing16}px`,
        background: theme.color.bgPrimaryOp5,
        borderRadius: theme.borderRadius.size20,
    },

    input: {
        color: theme.color.bgPrimary,
        fontSize: theme.fontSize.size16,
        minHeight: 24,
        background: 'transparent',
        width: '100%',
        border: 'none',
        outline: 'none !important',
        '&::placeholder': {
            color: theme.color.bgPrimaryOp1,
        },
    },
    resultContainer: {
        overflowY: 'auto',
        width: '100%',
        paddingTop: theme.spacing.spacing8,
        paddingBottom: theme.spacing.spacing8,
    },

    separator: {
        minHeight: theme.spacing.spacing8,
    },

    clearBtn: {
        outline: 'none',
        position: 'absolute',
        right: theme.spacing.spacing24,
        top: theme.spacing.spacing24,
        height: 24,
        width: 24,
    },
    closeBtn: {
        outline: 'none',
        marginRight: theme.spacing.spacing8,
        height: 40,
        width: 40,
    },
}));
