import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { generateUrl } from 'utils/i18n';
import { Image } from 'components';
import Collapse from '@mui/material/Collapse';
import * as _ from 'lodash';
import { useAutoCompleteSearch } from 'store/auto-complete';
import { useFeaturedStore } from 'store/featured';
import { useDebouncedEffect } from 'utils/use-debounced-effect';
import type {
    CategoryIndex,
    LocationIndex,
    MerchantIndex,
} from 'apis/merchant-algolia.d';
import { getRecentSearches, addRecentSearchers } from 'apis/recent-search';
import HottestSearch from 'components/search-bar/hottest-search';
import SectionHeader from 'components/search-bar/section-header';
import SearchResult from 'components/search-bar/search-result';
import type { Search } from 'apis/featured';
import assetsPath from 'utils/assets-path';
import { clickedMerchantAtAutoComplete } from 'apis/aloglia-insight';
import { merchantPathBySearch } from 'utils/merchants-path';
import { searchBoxKeywordSearched } from 'utils/gtm';
import urlEncode from 'utils/url-encode';

type Props = {
    isVisible: boolean;
    componentRef: React.RefObject<HTMLDivElement>;
};

const getSearchSet = (searchStore: {
    merchants: MerchantIndex[];
    locations: LocationIndex[];
    categories: CategoryIndex[];
}) => {
    let resultToPick = 3;
    const merchants = _.take(searchStore.merchants, resultToPick);
    resultToPick -= _.size(merchants);
    const categories = _.take(searchStore.categories, resultToPick);
    resultToPick -= _.size(categories);
    const locations = _.take(searchStore.locations, resultToPick);
    return { merchants, categories, locations };
};

const SearchBar = (props: Props) => {
    const { t } = useTranslation();
    const navigation = useNavigate();
    const { isVisible, componentRef } = props;
    const inputRef = useRef<HTMLInputElement>(null);
    const theme = useTheme();
    const classes = useStyles({ theme });
    const [isSearching, setIsSearching] = useState(false);
    const [showSuggestion, setShowSuggestion] = useState(false);

    const searchStore = useAutoCompleteSearch(
        useCallback((state) => state, []),
    );
    const featuredStore = useFeaturedStore(useCallback((state) => state, []));

    const inputChanged = useCallback((e) => {
        const inputValue = e.target.value;
        searchStore.setSearchKeyword(inputValue);
    }, []);

    const clearKeyword = useCallback(() => {
        searchStore.setSearchKeyword(null);
    }, []);

    useDebouncedEffect(
        () => {
            setIsSearching(!!searchStore.searchKeyword);
            if (searchStore.searchKeyword) {
                addRecentSearchers(searchStore.searchKeyword);
                searchStore.fetchAll({ query: searchStore.searchKeyword });
            }
        },
        500,
        [searchStore.searchKeyword],
    );

    useEffect(() => {
        if (isVisible) {
            inputRef.current?.focus();
        }
    }, [isVisible]);

    const goToMerchant = useCallback((merchant: MerchantIndex) => {
        navigation(
            generateUrl(
                `/merchant/${urlEncode(merchant.name)}-${merchant.objectID}`,
            ),
        );
    }, []);

    const onKeyPress = useCallback(
        (e) => {
            if (searchStore.searchKeyword && e.key === 'Enter') {
                searchBoxKeywordSearched(
                    searchStore.searchKeyword || '',
                    'Keyword Search',
                );
                goToKeyword(searchStore.searchKeyword);
            }
        },
        [searchStore.searchKeyword],
    );

    const goToKeyword = useCallback((keyword: string) => {
        navigation(generateUrl(`/search?keyword=${keyword}`));
    }, []);

    const goSearch = useCallback((search: Search) => {
        navigation(merchantPathBySearch(search));
    }, []);

    const goToLocation = useCallback((location: LocationIndex) => {
        navigation(
            generateUrl(`/district/${location.parent}/${location.objectID}`),
        );
    }, []);

    const goToCategory = useCallback((category: CategoryIndex) => {
        navigation(generateUrl(`/${category.parent}/${category.objectID}`));
    }, []);

    if (!isVisible && !searchStore.searchKeyword) {
        return null;
    }

    const recentSearches = getRecentSearches();

    const { merchants, categories, locations } = getSearchSet(searchStore);
    const numOfSearchResult =
        merchants.length + categories.length + locations.length;

    const resultComponent = isSearching ? (
        <div>
            {numOfSearchResult > 0 ? (
                <SectionHeader title={t('search-bar.section-header-hits')} />
            ) : null}
            {merchants.map((m, index) => (
                <SearchResult
                    key={`search-bar-merchant-result-${m.identifier}`}
                    text={m.name}
                    icon={m.logo?.[200] || ''}
                    iconSize="32"
                    onClicked={() => {
                        searchBoxKeywordSearched(
                            searchStore.searchKeyword || '',
                            'Suggested Results',
                        );
                        clickedMerchantAtAutoComplete(m.objectID, index + 1);
                        goToMerchant(m);
                    }}
                />
            ))}
            {categories.map((m) => (
                <SearchResult
                    key={`search-bar-merchant-category-${m.objectID}`}
                    text={m.name}
                    icon={m.icon ? assetsPath(m.icon) : ''}
                    iconSize="32"
                    onClicked={() => {
                        searchBoxKeywordSearched(
                            searchStore.searchKeyword || '',
                            'Suggested Results',
                        );
                        goToCategory(m);
                    }}
                />
            ))}
            {locations.map((m) => (
                <SearchResult
                    key={`search-bar-merchant-location-${m.objectID}`}
                    text={m.name}
                    icon={m.icon ? assetsPath(m.icon) : ''}
                    iconSize="32"
                    onClicked={() => {
                        searchBoxKeywordSearched(
                            searchStore.searchKeyword || '',
                            'Suggested Results',
                        );
                        goToLocation(m);
                    }}
                />
            ))}

            <SectionHeader title={t('search-bar.section-header-recent')} />
            {recentSearches.map((k) => (
                <SearchResult
                    key={`search-bar-recent-search-${k}`}
                    text={k}
                    icon={assetsPath('history')}
                    iconSize="32"
                    onClicked={() => {
                        searchBoxKeywordSearched(k || '', 'Search History');
                        goToKeyword(k);
                    }}
                />
            ))}
        </div>
    ) : (
        <div>
            <HottestSearch
                onClicked={(k) => {
                    searchBoxKeywordSearched(k.name || '', 'Popular Search');
                    goSearch(k);
                }}
                isMobile={false}
                searches={featuredStore.searches}
            />
            {recentSearches.map((k) => (
                <SearchResult
                    key={`search-bar-recent-search-${k}`}
                    text={k}
                    icon={assetsPath('history')}
                    iconSize="24"
                    onClicked={() => {
                        searchBoxKeywordSearched(k || '', 'Search History');
                        goToKeyword(k);
                    }}
                />
            ))}
        </div>
    );

    return (
        <div className={classes.container} ref={componentRef}>
            <div className={classes.inputContainer}>
                <input
                    ref={inputRef}
                    value={searchStore.searchKeyword || ''}
                    className={classes.input}
                    onChange={inputChanged}
                    onKeyPress={onKeyPress}
                    onFocus={() => setShowSuggestion(true)}
                    onBlur={() => setShowSuggestion(false)}
                    placeholder={t('search-bar.input-placeholder')}
                ></input>
                <a
                    className={classes.clearBtn}
                    onClick={clearKeyword}
                    role="button"
                    tabIndex={0}
                >
                    <Image src={assetsPath('clear')} height="24" width="24" />
                </a>
            </div>
            <Collapse in={showSuggestion} timeout={500}>
                <div className={classes.resultContainer}>{resultComponent}</div>
            </Collapse>
        </div>
    );
};

export default SearchBar;

const useStyles = createUseStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 0,
        position: 'absolute',
        width: 600,
        left: 0,
        top: 0,
        background: '#FFFFFF',

        boxShadow:
            '0px 0px 4px rgba(44, 45, 37, 0.1), 0px 4px 8px rgba(44, 45, 37, 0.2)',
        borderRadius: 16,
        zIndex: 2,
        overflow: 'hidden',
    },
    inputContainer: {
        width: '100%',
        padding: `${theme.spacing.spacing8}px ${theme.spacing.spacing16}px`,
        borderBottom: `1px solid ${theme.color.borderPrimaryOp4}`,
    },
    input: {
        minHeight: 40 - theme.spacing.spacing8 * 2,
        width: '100%',
        border: 'none',
        outline: 'none !important',
    },
    resultContainer: {
        overflowY: 'auto',
        minWidth: '100%',
        paddingTop: theme.spacing.spacing8,
        paddingBottom: theme.spacing.spacing8,
    },

    clearBtn: {
        outline: 'none',
        position: 'absolute',
        right: theme.spacing.spacing16,
        top: theme.spacing.spacing8,
        height: 24,
        width: 24,
    },
}));
