import React, { useEffect, Suspense, FC } from 'react';
import {
    Home,
    About,
    NotFound,
    Merchant,
    Merchants,
    Tnc,
    PrivacyPolicy,
    Standard,
    AuthTest,
    ForgetPw,
    ResetPw,
    Profile,
    FavMerchants,
    MerchantForm,
    Campaign,
    Blog,
    ConsumptionVoucher,
    Takeaway,
    DoorToDoor,
    Blogs,
    // Lunar2023,
    // Vday2023,
} from 'pages';
import { RedirectWithoutLastLocation } from 'hooks/react-router-last-location';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import i18n, {
    generateLocaleUrl,
    getUserDefaultLocale,
    isSupportedLocale,
    DEFAULT_LOCALE,
} from './utils/i18n';

const I18nRoute = () => {
    const location = useLocation();
    const pathName = location.pathname;
    const pathNameArray = pathName.split('/');
    let firstPath = '';
    if (pathNameArray.length > 1) {
        firstPath = pathNameArray[1];
    }

    // const params = match.params as { [key: string]: string };
    const urlLocale = (() => {
        if (firstPath && isSupportedLocale(firstPath)) {
            return firstPath;
        }
        return DEFAULT_LOCALE;
    })();
    const userDefaultLocale = getUserDefaultLocale();

    //Change language if URL locale changed
    //urlLocale != loaded locale or urlLocale != client default
    if (userDefaultLocale !== urlLocale || i18n.language !== urlLocale) {
        i18n.changeLanguage(urlLocale);
    }

    //Redirect to root if locale is zh
    if (
        urlLocale === DEFAULT_LOCALE &&
        location.pathname.startsWith(`/${DEFAULT_LOCALE}`)
    ) {
        return (
            <RedirectWithoutLastLocation
                to={
                    location.pathname.replace(
                        new RegExp(`^/${DEFAULT_LOCALE}`),
                        '',
                    ) + location.search
                }
            />
        );
    }

    const urlLocalePrefix = urlLocale === DEFAULT_LOCALE ? '' : `/${urlLocale}`;

    return (
        <div>
            <Suspense fallback={null}>
                <ScrollToTop>
                    <Routes>
                        <Route
                            path={`${urlLocalePrefix}/`}
                            element={<Home />}
                        />
                        {process.env.NODE_ENV === 'production' ? null : (
                            <Route
                                path={`${urlLocalePrefix}/auth-test`}
                                element={<AuthTest />}
                            />
                        )}
                        <Route
                            path={`${urlLocalePrefix}/blogs`}
                            element={<Blogs />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/about-us`}
                            element={<About />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/tnc`}
                            element={<Tnc />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/privacy-policy`}
                            element={<PrivacyPolicy />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/standard`}
                            element={<Standard />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/forget-pw`}
                            element={<ForgetPw />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/reset-pw`}
                            element={<ResetPw />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/profile`}
                            element={<Profile />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/fav-merchants`}
                            element={<FavMerchants />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/merchant-form`}
                            element={<MerchantForm />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/merchant/view-all`}
                            element={<Merchants routeMode="other" />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/campaign/:campaignPath`}
                            element={<Campaign />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/blog/:blogPath`}
                            element={<Blog />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/consumption-voucher`}
                            element={<ConsumptionVoucher />}
                        />
                        {/* <Route
                            path={`${urlLocalePrefix}/lunar2023`}
                            element={<Lunar2023 />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/vday2023`}
                            element={<Vday2023 />}
                        /> */}
                        <Route
                            path={`${urlLocalePrefix}/takeaway`}
                            element={<Takeaway />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/door-to-door`}
                            element={<DoorToDoor />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/not-found`}
                            element={<NotFound />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/search`}
                            element={<Merchants routeMode="other" />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/district/:district/:districtName`}
                            element={<Merchants routeMode="district" />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/district/:district`}
                            element={<Merchants routeMode="district" />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/merchant/join-us/`}
                            element={<MerchantForm />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/merchant/:merchantName-:id`}
                            element={<Merchant />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/merchant/:id`}
                            element={<Merchant />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/:category/:subcategory/search`}
                            element={<Merchants routeMode="subCategory" />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/:category/search`}
                            element={<Merchants routeMode="category" />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/:category/:subcategory`}
                            element={<Merchants routeMode="subCategory" />}
                        />
                        <Route
                            path={`${urlLocalePrefix}/:category`}
                            element={<Merchants routeMode="category" />}
                        />
                        <Route element={<NotFound />} />
                    </Routes>
                </ScrollToTop>
            </Suspense>
            {process.env.NODE_ENV === 'production' ? null : (
                <div style={{ backgroundColor: 'yellow' }}>
                    <span>For testing only - </span>
                    <Link to={generateLocaleUrl('zh', location.pathname)}>
                        ZH
                    </Link>
                    &nbsp;|&nbsp;
                    <Link to={generateLocaleUrl('en', location.pathname)}>
                        EN
                    </Link>
                    <span style={{ float: 'right' }}>
                        Current Language : {i18n.language}
                    </span>
                </div>
            )}
        </div>
    );
};

export { I18nRoute };

const ScrollToTop: FC = (props) => {
    const { children } = props;
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <>{children}</>;
};
