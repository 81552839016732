import type {
    MerchantIndex,
    LocationIndex,
    CategoryIndex,
} from 'apis/merchant-algolia.d';

import json from './featured.json';

export type Keyword = {
    type: 'keyword';
    id: string;
};

export type Search = {
    type: 'category' | 'location' | 'merchant' | 'keyword';
    id: string;
    name: string;
    data: CategoryIndex | LocationIndex | MerchantIndex | Keyword;
};

export type Marketing = {
    title: string;
    subTitle?: string;
    buttonText?: string;
    image: string;
    url?: string;
};

type FeatureResponse = {
    'mobile-banners': { image: string; url?: string }[];
    'desktop-banners': { image: string; url?: string }[];
    merchants: MerchantIndex[];
    searches: Search[];
    categories: CategoryIndex[];
    'mobile-marketings': Marketing[];
    'desktop-marketings': Marketing[];
};

export const getFeatured = async (): Promise<FeatureResponse> => {
    if ((window as any).FEATURED_INFO_API_CACHE) {
        return (window as any).FEATURED_INFO_API_CACHE;
    }
    return json as any;
};
