import React, { FC } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import {
    Image,
    MerchantCoverPhoto,
    MerchantFavouriteButton,
    MerchantInfo,
} from 'components';
import type { TakeawayMethod } from 'apis/merchant-algolia.d';
import { useNavigate } from 'react-router-dom';
import { generateUrl } from 'utils/i18n';
import { useTranslation } from 'react-i18next';
import assetsPath from 'utils/assets-path';

export interface MerchantProps {
    objectId: string;
    merchantImage?: string;
    merchantProductImages?: string[];
    merchantLogo?: string;
    location: string;
    category?: string;
    title: string;
    hashTags?: string[];
    isBookmark: boolean;
    numberOfFavourites: number;
    status: string;
    cardType?: 'small' | 'big';
    isFAndB?: boolean;
    isFeature?: boolean;
    isConscience?: boolean;
    isKindness?: boolean;
    isBrand?: boolean;
    isEnvironment?: boolean;
    isIngredient?: boolean;
    isLocalStore?: boolean;
    isLocalDesign?: boolean;
    isLocalManufacture?: boolean;
    isLocalMaterial?: boolean;
    isOctopus?: boolean;
    isTapAndGo?: boolean;
    isAlipay?: boolean;
    isWechatPay?: boolean;
    isBocPay?: boolean;
    isPayme?: boolean;
    isClosed: boolean;
    openriceRating?: number;
    openriceReviewCount?: number;
    igFollowerCount?: number;
    takeawayMethods: TakeawayMethod[];
}

interface Props extends MerchantProps {
    to: string;
    className?: string;
    onClick?: () => void;
    bookmarkBtnOnClick: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}

const useStyles = createUseStyles((theme) => ({
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: theme.borderRadius.size8,
        overflow: 'hidden',
        cursor: 'pointer',
        height: '100%',
        '&.urgency': {
            border: `2px solid ${theme.color.bgPrimary}`,
        },
    },
    imageContainer: {
        height: (props: Props) => (props.cardType === 'small' ? 120 : 160),
        position: 'relative',
    },
    infoContainer: {
        padding: (props: Props) =>
            props.cardType === 'small'
                ? theme.spacing.spacing8
                : theme.spacing.spacing16,
        flex: 1.0,
        background: theme.color.bgPrimaryOp5,
        display: 'flex',
    },
    logoContainer: {
        borderRadius: theme.borderRadius.size8,
        overflow: 'hidden',
        height: 48,
        width: 48,
    },
    truncate: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    bookmarkBtn: {
        position: 'absolute',
        top: theme.spacing.spacing12,
        right: theme.spacing.spacing12,
    },

    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.fontSize.size24,
        fontWeight: theme.fontWeight.bold,
        color: theme.color.textPrimary,
        marginBottom: theme.spacing.spacing4,
        extend: 'truncate',
    },
    labelContainer: {
        position: 'absolute',
        top: 0,
        left: 0,

        '& .hashtag-container': {
            display: 'inline-block',
            lineHeight: theme.lineHeight.size16,
            marginBottom: theme.spacing.spacing4,
            paddingBottom: theme.spacing.spacing4,
            paddingTop: theme.spacing.spacing2,
            paddingRight: theme.spacing.spacing8,
            paddingLeft: theme.spacing.spacing16,
            background: theme.color.bgPrimary,
            border: `2px solid ${theme.color.bgPrimary}`,
            borderRadius: `0 ${theme.borderRadius.size4} ${theme.borderRadius.size4} 0`,
            '&.urgency': {
                borderRadius: `0 0 ${theme.borderRadius.size8} 0`,
                borderLeft: 0,
                borderTop: 0,
                background: theme.color.bgThird,
                paddingRight: theme.spacing.spacing12 - theme.spacing.spacing2,
                lineHeight: 'unset',
                paddingBottom: theme.spacing.spacing8 - theme.spacing.spacing2,
                paddingTop: theme.spacing.spacing8,
            },
        },
        '& .hashtag': {
            fontSize: theme.fontSize.size12,
            lineHeight: '12px',
            fontWeight: '900',
            color: theme.color.btnThird,
        },
    },
}));

const MerchantCard: FC<Props> = (props) => {
    // hooks
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme, ...props });
    const { t } = useTranslation();
    const navigation = useNavigate();
    const {
        cardType,
        objectId,
        className,
        to,
        merchantImage,
        merchantLogo,
        merchantProductImages,
        bookmarkBtnOnClick,
        title,
        isBookmark,
        onClick,
        numberOfFavourites,
        igFollowerCount,
        hashTags,
        status,
    } = props;

    const isUrgency = hashTags?.includes('urgency');
    const actualHashTags = (hashTags || []).filter((tag) => tag !== 'urgency');

    // UI event function
    const cardOnClick = () => {
        if (onClick) {
            onClick();
        }
        navigation(generateUrl(to));
    };

    // compute value

    // render function
    return (
        <div
            onClick={cardOnClick}
            role="button"
            tabIndex={0}
            className={`${classes.cardContainer} ${className || ''} ${
                isUrgency ? 'urgency' : ''
            }`}
        >
            <div className={classes.imageContainer}>
                <MerchantCoverPhoto
                    merchantImage={merchantImage}
                    merchantProductImages={merchantProductImages}
                />

                <div className={classes.bookmarkBtn}>
                    <MerchantFavouriteButton
                        onClicked={(event) => {
                            if (bookmarkBtnOnClick) {
                                bookmarkBtnOnClick(event);
                            }
                            event.stopPropagation();
                        }}
                        size="compact"
                        isFavourited={isBookmark}
                        numberOfFavourites={numberOfFavourites}
                    />
                </div>

                <div className={classes.labelContainer}>
                    {isUrgency && (
                        <div className="urgency-tag">
                            <div className="hashtag-container urgency">
                                <span className="hashtag urgency">
                                    <Image
                                        width="66"
                                        height="20"
                                        src={assetsPath('merchant-urgency')}
                                    />
                                </span>
                            </div>
                        </div>
                    )}

                    <div>
                        {(actualHashTags || []).map((hashTag) => (
                            <div key={`mcard-${objectId}-${hashTag}`}>
                                <div className="hashtag-container">
                                    <span className="hashtag">{hashTag}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <MerchantInfo
                className={classes.infoContainer}
                title={title}
                merchantLogo={merchantLogo}
                igFollowerCount={igFollowerCount}
                cardType={cardType}
                isVerify={status === 'verified'}
            />
        </div>
    );
};

MerchantCard.defaultProps = {};

export default MerchantCard;
