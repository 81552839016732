import React, { FC, useState, useEffect } from 'react';
import { createUseStyles, DefaultTheme, useTheme } from 'react-jss';
import { Image, LocaleLink } from 'components';
import type { CategoryIndex, LocationIndex } from 'apis/merchant-algolia.d';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';
import assetsPath from 'utils/assets-path';
import urlEncode from 'utils/url-encode';

type MenuObject = CategoryIndex | LocationIndex;

type Props = {
    className?: string;
    parentMenu: MenuObject;
    menus: MenuObject[];
};

type SubMenuFeed = {
    id: string;
    title: string;
    type: string;
    icon?: string;
};

type MenuFeed = {
    id: string;
    title: string;
    icon?: string;
    type: string;
    subMenus: SubMenuFeed[];
};

const useStyles = createUseStyles((theme) => ({
    menuContainer: {
        display: 'flex',
        flexDirection: 'row',
        background: theme.color.bgSecondary,
        filter: [
            [
                'drop-shadow(0px 2px 2px rgba(44, 45, 37, 0.05))',
                'drop-shadow(0px 8px 24px rgba(44, 45, 37, 0.25))',
            ],
        ],
    },
    leftMenuContainer: {
        width: 273,
        paddingLeft: theme.spacing.spacing24,
        paddingTop: theme.spacing.spacing12,
        paddingRight: theme.spacing.spacing8,
        paddingBottom: theme.spacing.spacing12,
        background: theme.color.bgPrimaryOp5,
        '& $menuItem': {
            marginBottom: theme.spacing.spacing4,
            '&:last-child': {
                marginBottom: 0,
            },
        },
    },
    rightMenuContainer: {
        flex: 1.0,
        background: theme.color.bgSecondary,
        paddingTop: theme.spacing.spacing32,
        paddingBottom: theme.spacing.spacing32,
        paddingLeft: theme.spacing.spacing64,
    },
    menuItem: {},
    allCatBtnContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: theme.spacing.spacing24,
    },
    allCatBtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    allCatBtnTitle: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.bold,
        marginRight: theme.spacing.spacing8,
    },
    subCatContainer: {
        width: 316,
    },
    subCatBtn: {
        display: 'block',
        paddingRight: theme.spacing.spacing16,
        marginBottom: theme.spacing.spacing12,
    },
    subCatTitle: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
    },
}));

const MegaMenu: FC<Props> = (props) => {
    // hooks
    const theme = useTheme();
    const { t } = useTranslation();
    const classes = useStyles({ theme });

    const { className, menus, parentMenu } = props;
    const [currentSelectedIndex, setCurrentSelectedIndex] = useState<number>(0);
    const [categoryFeed, setCategoryFeed] = useState<MenuFeed[]>([]);

    useEffect(() => {
        const secondLevelMenus = _.filter(menus, (c: MenuObject) => {
            return (
                (c.type === 'categories_2nd' &&
                    c.parent === parentMenu?.objectID) ||
                c.type === 'area'
            );
        });

        setCategoryFeed(
            secondLevelMenus.map((secondLevelMenu: MenuObject) => {
                return {
                    id: secondLevelMenu.objectID,
                    title: secondLevelMenu.name,
                    icon: secondLevelMenu.icon,
                    type: secondLevelMenu.type,
                    subMenus: _.chain(menus)
                        .filter(
                            (c: MenuObject) =>
                                (c.type === 'categories_3rd' ||
                                    c.type === 'district') &&
                                c.parent === secondLevelMenu.objectID,
                        )
                        .map((c: MenuObject) => ({
                            id: c.objectID,
                            title: c.name,
                            icon: c.icon,
                            type: c.type,
                        }))
                        .value(),
                };
            }),
        );
    }, [menus]);

    const currentSelectedCategory = categoryFeed[currentSelectedIndex];

    // UI event function
    const menuItemOnMouseEnter = (index: number) => {
        setCurrentSelectedIndex(index);
    };

    // logic function

    // render function
    return (
        <div className={`${classes.menuContainer} ${className}`}>
            <div className={classes.leftMenuContainer}>
                {_.uniqBy(categoryFeed, (c) => c.id).map((category, index) => {
                    return (
                        <MenuItem
                            key={`mega-meun-${parentMenu?.objectID}-${category.id}`}
                            className={classes.menuItem}
                            iconAsset={
                                category.icon
                                    ? assetsPath(category.icon)
                                    : undefined
                            }
                            itemTitle={category.title}
                            isSelected={index === currentSelectedIndex}
                            onMouseEnter={() => menuItemOnMouseEnter(index)}
                        />
                    );
                })}
            </div>
            <div className={classes.rightMenuContainer}>
                <div className={classes.allCatBtnContainer}>
                    <LocaleLink
                        className={classes.allCatBtn}
                        to={getLink(currentSelectedCategory)}
                    >
                        <p className={classes.allCatBtnTitle}>
                            {t('menu.all-categoies', {
                                category: currentSelectedCategory?.title,
                            })}
                        </p>
                        <Image
                            src={assetsPath('arrow-chevron-right')}
                            width="16"
                            height="16"
                        />
                    </LocaleLink>
                </div>
                <div className={classes.subCatContainer}>
                    <div className="columns is-gapless is-multiline">
                        {currentSelectedCategory?.subMenus.map(
                            (subCategory: SubMenuFeed) => {
                                return (
                                    <div
                                        className="column is-half"
                                        key={`mega-meun-${parentMenu?.objectID}-${subCategory?.id}`}
                                    >
                                        <LocaleLink
                                            className={classes.subCatBtn}
                                            to={getLink(
                                                subCategory,
                                                currentSelectedCategory,
                                            )}
                                        >
                                            <p className={classes.subCatTitle}>
                                                {subCategory.title}
                                            </p>
                                        </LocaleLink>
                                    </div>
                                );
                            },
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

// Left menu component
type MenuItemProps = {
    iconAsset?: string;
    itemTitle: string;
    isSelected: boolean;
    className?: string;
    onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
};

const useMenuItemStyles = createUseStyles((theme) => ({
    itemContainer: {
        paddingTop: theme.spacing.spacing4,
        paddingBottom: theme.spacing.spacing4,
        paddingLeft: theme.spacing.spacing12,
        paddingRight: theme.spacing.spacing8,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: 'transparent',
        borderRadius: theme.borderRadius.size8,
    },
    itemContainerIsSelected: {
        background: theme.color.btnThird,
    },
    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        flex: 1.0,
        marginLeft: theme.spacing.spacing12,
        marginRight: theme.spacing.spacing8,
    },
}));

const MenuItem: FC<MenuItemProps> = (props) => {
    const { iconAsset, itemTitle, isSelected, className, onMouseEnter } = props;
    const theme = useTheme<DefaultTheme>();
    const classes = useMenuItemStyles({ theme });

    return (
        <a
            className={`${classes.itemContainer} ${className} ${
                isSelected === true ? classes.itemContainerIsSelected : ''
            }`}
            onMouseEnter={onMouseEnter}
        >
            <Image src={iconAsset} width="20" height="20" />
            <p className={classes.title}>{itemTitle}</p>
            <Image
                src={assetsPath('arrow-chevron-right')}
                width="16"
                height="16"
            />
        </a>
    );
};

const getLink = (menu?: MenuFeed | SubMenuFeed, parent?: MenuFeed) => {
    if (!menu) {
        return '';
    }
    if (['area', 'district'].includes(menu.type)) {
        if (menu.type === 'area') {
            return `/district/${urlEncode(menu.id)}`;
        }
        return `/district/${urlEncode(parent?.id as string)}/${urlEncode(
            menu.id,
        )}`;
    }

    if (parent == null) {
        return `/${urlEncode(menu.id)}`;
    }
    return `/${urlEncode(parent.id)}/${urlEncode(menu.id)}`;
};

export default MegaMenu;
