import React, { useCallback, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Image } from 'components';
import _ from 'lodash';
import assetsPath from 'utils/assets-path';
import { openMap } from 'utils/map-selection';
import Collapse from '@mui/material/Collapse';

const useStyles = createUseStyles((theme) => ({
    branchTitle: {
        marginTop: theme.spacing.spacing16,
        marginBottom: theme.spacing.spacing16,
        fontSize: theme.fontSize.size12,
        lineHeight: theme.lineHeight.size16,
        color: theme.color.textPrimary,
        fontWeight: theme.fontWeight.bold,
    },
    openingHour: {
        whiteSpace: 'pre-wrap',
    },
    moreBranch: {
        cursor: 'pointer',
        color: theme.color.textSecondary,
        fontWeight: theme.fontWeight.bold,
        fontSize: theme.fontSize.size12,
        lineHeight: theme.lineHeight.size16,
        marginTop: theme.spacing.spacing8,
        '& path': {
            stroke: theme.color.textSecondary,
        },
    },
}));

const detailsIconMap: { [key: string]: string } = {
    addresses: assetsPath('address'),
    opening_hours: assetsPath('clock'),
};

type Props = {
    openingHours?: string[];
    addresses?: string[];
    isExpanded: boolean;
    onClickMoreBtn?: () => void;
};
const MerchantAddressOpeningHours = (props: Props) => {
    const { openingHours, addresses, isExpanded, onClickMoreBtn } = props;
    const toggleExpand = useCallback(() => {
        if (onClickMoreBtn) {
            onClickMoreBtn();
        }
    }, [onClickMoreBtn]);
    const { t } = useTranslation();

    const classes = useStyles({ theme: useTheme() });
    if (
        !addresses ||
        !openingHours ||
        _.isEmpty(openingHours) ||
        _.isEmpty(addresses)
    ) {
        return null;
    }

    const isMultipleBranch = _.size(addresses) > 1;

    const infoCmpt = (address: string, openingHour: string) => {
        return [
            <div
                key={`merchant-details-address-${address}`}
                className="columns is-mobile is-variable is-8-mobile is-4-desktop"
            >
                <div className="column is-narrow">
                    <Image
                        src={detailsIconMap.addresses}
                        height="24"
                        width="24"
                    />
                </div>
                <div className="column">
                    <a
                        onClick={() => openMap(address)}
                        role="button"
                        tabIndex={0}
                    >
                        {address}
                    </a>
                </div>
                <div
                    className="column is-hidden-desktop is-narrow"
                    onClick={() => openMap(address)}
                    role="button"
                    tabIndex={0}
                >
                    <Image
                        src={assetsPath('arrow-chevron-right')}
                        height="24"
                        width="24"
                    />
                </div>
            </div>,
            openingHour ? (
                <div
                    key={`merchant-details-opening-hour-${openingHour}`}
                    className="columns is-mobile is-variable is-8-mobile is-4-desktop"
                >
                    <div className="column is-narrow">
                        <Image
                            src={detailsIconMap.opening_hours}
                            height="24"
                            width="24"
                        />
                    </div>
                    <div className={`column ${classes.openingHour}`}>
                        {openingHour}
                    </div>
                </div>
            ) : null,
        ];
    };

    const branchTitle = (count: number) => (
        <div className={classes.branchTitle}>
            <div
                key={`address-opening-hours-branch-title-${count}`}
                className={`columns is-mobile is-variable `}
            >
                <div className="column">
                    {t('merchant.branch', {
                        num: count,
                    })}
                </div>
            </div>
        </div>
    );

    return (
        <>
            {isMultipleBranch ? branchTitle(1) : null}
            {infoCmpt(addresses[0], openingHours[0])}
            <Collapse in={isExpanded}>
                {isMultipleBranch
                    ? addresses.slice(1).map((address, idx) => {
                          const openingHour = _.get(openingHours, idx);
                          return [
                              branchTitle(idx + 2),
                              infoCmpt(address, openingHour),
                          ];
                      })
                    : null}
            </Collapse>
            {isMultipleBranch && isExpanded === false ? (
                <div className={classes.moreBranch}>
                    <div
                        className={`columns is-gapless is-mobile`}
                        onClick={toggleExpand}
                        role="button"
                        tabIndex={0}
                    >
                        <div className="column is-narrow">
                            {t('merchant.more-branch-dialog-title', {
                                count: addresses.length,
                            })}
                        </div>
                        <div className="column">
                            {
                                <Image
                                    src={assetsPath('arrow-chevron-right')}
                                    height="16"
                                    width="16"
                                />
                            }
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default MerchantAddressOpeningHours;
