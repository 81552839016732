import React, { useCallback, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import type { TakeawayMethod } from 'apis/merchant-algolia.d';
import { TAKEAWAY_SERVICE_ASSETS } from 'utils/takeway-service-icons';
import _ from 'lodash';

import { Tooltip } from '@mui/material';
import { Image } from 'components';
import * as takeawayUtils from 'utils/takeaway';

type Props = {
    isMobile: boolean;
    merchantId: string;
    takeawayMethods: TakeawayMethod[];
};
const MerchantTakeawayMethods = (props: Props) => {
    const { isMobile, merchantId, takeawayMethods } = props;
    const { t } = useTranslation();
    const classes = useStyles({ theme: useTheme() });

    if (takeawayMethods.length === 0) {
        return null;
    }

    const iconSize = isMobile ? '32' : '28';
    return (
        <div className={classes.infoView}>
            <div className="title">{t('merchant.takeaway-method')}</div>
            <div className="columns is-mobile is-variable is-4 is-multiline">
                {takeawayMethods.map((m) => (
                    <Tooltip
                        arrow
                        title={`${t(
                            `takeaway-method.${takeawayUtils.i18nKeyMapping(
                                m,
                            )}`,
                        )}`}
                        key={`merchant-${merchantId}-takeaway-method-${m}`}
                    >
                        <div className={`column is-narrow image-container`}>
                            <Image
                                src={_.get(TAKEAWAY_SERVICE_ASSETS, m)}
                                height={iconSize}
                                width={iconSize}
                            />
                        </div>
                    </Tooltip>
                ))}
            </div>
        </div>
    );
};

const useStyles = createUseStyles((theme) => ({
    infoView: {
        marginBottom: theme.spacing.spacing32,
        color: theme.color.textPrimary,
        [`${theme.mediaQueries.desktop}`]: {
            marginTop: 0,
        },
        '& .title': {
            fontSize: theme.fontSize.size12,
            lineHeight: theme.lineHeight.size16,
            color: theme.color.textPrimary,
            fontWeight: theme.fontWeight.bold,
            marginBottom: theme.spacing.spacing12,
            [`${theme.mediaQueries.mobile}`]: {
                marginBottom: theme.spacing.spacing8,
            },
        },
        '& .image-container': {
            '&  img': {
                borderRadius: 4,
            },
        },
    },
}));

export default MerchantTakeawayMethods;
