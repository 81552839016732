import React, { FC } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { Image } from 'components';
import * as _ from 'lodash';
import { illustrationPath } from 'utils/assets-path';
import { useMediaQuery } from '@mui/material';

type Props = {
    merchantImage?: string;
    merchantProductImages?: string[];
    className?: string;
};

const useStyles = createUseStyles((theme) => ({
    imagesContainer: {
        height: '100%',
        display: 'flex',
        '& > *': {
            flex: 1,
        },
    },
}));

const MerchantCoverPhoto: FC<Props> = (props) => {
    // hooks
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });
    const { merchantProductImages, merchantImage, className } = props;
    const isMobile = useMediaQuery(
        `(max-width:${theme.breakpoints.tablet - 1}px)`,
    );
    // UI event function

    // logic function

    // render function
    let imageCmpt = (
        <Image
            src={
                isMobile
                    ? illustrationPath('merchant-banner-default-mobile.png')
                    : illustrationPath('merchant-banner-default-desktop.png')
            }
            width="100%"
            height="100%"
            layout="fixed-height"
            lazyLoad
        />
    );
    if (merchantImage) {
        imageCmpt = (
            <Image
                src={merchantImage}
                width="100%"
                height="100%"
                layout="fixed-height"
                enableWebp
                lazyLoad
            />
        );
    } else if (!_.isEmpty(merchantProductImages)) {
        const takenImage = _.take(merchantProductImages, 3);
        imageCmpt = (
            <div className={classes.imagesContainer}>
                {takenImage.map((i) => (
                    <Image
                        className="splitted-cover"
                        key={`merchant-product-image-${i}`}
                        src={i}
                        width={`${(100 / takenImage.length).toFixed(2)}%`}
                        height="100%"
                        layout="fixed-height"
                        enableWebp
                        lazyLoad
                    />
                ))}
            </div>
        );
    }
    return (
        <div className={className} style={{ height: '100%' }}>
            {imageCmpt}
        </div>
    );
};

export default MerchantCoverPhoto;
