import React, { ReactNode, useState, useCallback, useEffect } from 'react';
import {
    LokoHeader,
    LokoFooter,
    DrawerMenu,
    SearchBarMobile,
    LoginMenu,
} from 'components';
import queryString from 'query-string';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useFeaturedStore } from 'store/featured';
import { useBasicInfoStore } from 'store/basic-info';
import { useUiStore } from 'store/ui';
import { useUserStore } from 'store/user';
import { Drawer, useMediaQuery, Dialog, Slide } from '@mui/material';
import { SlideProps } from '@mui/material/Slide';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { CategoryIndex } from 'apis/merchant-algolia.d';
import _ from 'lodash';
import { generateUrl } from 'utils/i18n';
import { useNavigate, useLocation } from 'react-router-dom';
import { favouriteButtonSelected, mobileHamburgerBarSelected } from 'utils/gtm';
import { detect } from 'detect-browser';

const browser = detect();

const Transition = React.forwardRef(function Transition(
    props: SlideProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
    children?: ReactNode;
};

const useStyles = createUseStyles((theme) => ({
    drawer: {
        '& .MuiDrawer-paper': {
            width: '80%',
        },
    },
    header: {
        width: '100%',
        position: 'fixed',
        top: 0,
        zIndex: 4,
        background: theme.color.bgSecondary,
    },
    loginMenu: {
        '& .MuiDrawer-paper': {
            width: 400,
        },
    },
}));

const Layout = ({ children }: Props) => {
    const [desktopCollapse, setDesktopCollapse] = useState(false);
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });
    const navigation = useNavigate();

    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [topLevelCategories, setTopLevelCategories] = React.useState<
        CategoryIndex[]
    >([]);

    const { searches } = useFeaturedStore(
        useCallback((state) => ({ searches: state.searches }), []),
    );
    const { isLogined, signOut } = useUserStore(
        useCallback(
            (state) => ({
                isLogined: state.isAuth === true,
                signOut: state.signOut,
            }),
            [],
        ),
    );
    const {
        mobileSearchBarVisible,
        setMobileSearchBarVisible,
        loginMenuVisible,
        setLoginMenuVisible,
        loginInPage,
        setLoginInPage,
    } = useUiStore(useCallback((state) => state, []));
    const { fetchBasicInfo, categories, locations } = useBasicInfoStore(
        useCallback(
            (state) => ({
                categories: Object.values(state.categories),
                locations: Object.values(state.locations),
                fetchBasicInfo: state.fetch,
            }),
            [],
        ),
    );
    const isMobile = useMediaQuery(
        `(max-width:${theme.breakpoints.tablet - 1}px)`,
    );

    useScrollPosition(
        ({ prevPos, currPos }) => {
            if (browser?.name !== 'safari') {
                if (currPos.y >= 0) {
                    if (desktopCollapse !== false) {
                        setDesktopCollapse(false);
                    }

                    return;
                }

                const shouldCollapse = currPos.y < prevPos.y;
                if (shouldCollapse !== desktopCollapse) {
                    setDesktopCollapse(shouldCollapse);
                }
            }
        },
        [desktopCollapse],
        undefined,
        false,
        200,
    );

    //handle query param register=1 when init
    const { search: queryParam } = useLocation();
    useEffect(() => {
        const { register } = queryString.parse(queryParam);
        if (register && !loginMenuVisible) {
            setLoginMenuVisible(true);
            setLoginInPage('create-ac');
        }
    }, []);

    useEffect(() => {
        setTopLevelCategories(
            _.filter(categories, (c) => c.type === 'categories_1st'),
        );
    }, [categories]);

    useEffect(() => {
        if (mobileMenuVisible) {
            setTimeout(() => {
                document
                    .querySelector('.MuiBackdrop-root')
                    ?.addEventListener(
                        'touchstart',
                        () => setMobileMenuVisible(false),
                        false,
                    );
            }, 10);
        }
    }, [mobileMenuVisible]);

    useEffect(() => {
        fetchBasicInfo();
    }, []);

    const onClickMobileMenu = () => {
        mobileHamburgerBarSelected();
        setMobileMenuVisible(true);
    };

    const onClickProfileBtn = () => {
        if (isLogined) {
            navigation(generateUrl('/profile'));
        } else {
            goToLogin();
        }
    };

    const onClickFavouriteBtn = () => {
        if (isLogined) {
            favouriteButtonSelected();
            navigation(generateUrl('/fav-merchants'));
        } else {
            goToLogin();
        }
    };

    const goToLogin = () => {
        setLoginInPage('login');
        setLoginMenuVisible(true);
    };

    const goToRegister = () => {
        setLoginInPage('create-ac');
        setLoginMenuVisible(true);
    };

    return (
        <>
            <div>
                <header className={classes.header}>
                    <LokoHeader
                        isLogined={isLogined}
                        categories={categories}
                        locations={locations}
                        desktopCollapse={desktopCollapse}
                        searches={searches}
                        onClickMobileMenu={onClickMobileMenu}
                        onClickProfileBtn={onClickProfileBtn}
                        onClickFavouriteBtn={onClickFavouriteBtn}
                    />
                </header>
                {children}
                {isMobile === false && (
                    <LokoFooter categories={topLevelCategories} />
                )}
            </div>
            <Drawer
                className={`is-hidden-tablet ${classes.drawer}`}
                anchor="left"
                open={mobileMenuVisible}
                onClose={() => setMobileMenuVisible(false)}
            >
                <DrawerMenu
                    isLogined={isLogined}
                    categories={categories}
                    locations={locations}
                    onClickDismiss={() => setMobileMenuVisible(false)}
                    onRegisterClicked={goToRegister}
                    onSignInClicked={goToLogin}
                    onSignOutOutClicked={signOut}
                />
            </Drawer>
            <SearchBarMobile
                isVisible={mobileSearchBarVisible}
                onCloseClicked={() => setMobileSearchBarVisible(false)}
            />
            {isMobile === true ? (
                <Dialog
                    fullScreen
                    open={loginMenuVisible}
                    onClose={() => setLoginMenuVisible(false)}
                    TransitionComponent={Transition}
                >
                    <LoginMenu
                        defaultPage={loginInPage}
                        onClose={() => setLoginMenuVisible(false)}
                    />
                </Dialog>
            ) : (
                <Drawer
                    className={classes.loginMenu}
                    anchor="right"
                    open={loginMenuVisible}
                    onClose={() => setLoginMenuVisible(false)}
                >
                    <LoginMenu
                        defaultPage={loginInPage}
                        onClose={() => setLoginMenuVisible(false)}
                    />
                </Drawer>
            )}
        </>
    );
};

export default Layout;
