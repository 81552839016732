import { createUseStyles } from 'react-jss';

const globalStyles = createUseStyles((theme) => ({
    '@import':
        'url(https://fonts.googleapis.com/css2?family=Noto+Sans+TC&family=Quicksand&display=swap)',
    '@global': {
        body: {
            fontFamily: ['"Quicksand"', '"Noto Sans TC"', 'sans-serif'],
        },
        h1: {
            fontSize: theme.fontSize.size40,
            lineHeight: theme.lineHeight.size56,
            fontWeight: theme.fontWeight.bold,
        },
        h2: {
            fontSize: theme.fontSize.size24,
            lineHeight: theme.lineHeight.size40,
            fontWeight: theme.fontWeight.bold,
        },
        h3: {
            fontSize: theme.fontSize.size18,
            lineHeight: theme.lineHeight.size32,
            fontWeight: theme.fontWeight.bold,
        },
        a: {
            color: theme.color.textPrimary,
            '&:hover': {
                color: theme.color.textPrimary,
            },
            '&:focus': {
                outline: 'none',
            },
        },
    },
}));

export default globalStyles;
