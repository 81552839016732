import React, { FC, HTMLAttributes } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { Image, RoundedButton } from 'components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';

export interface Prop extends HTMLAttributes<HTMLDivElement> {
    title: string;
    subTitle: string;
    image: string;
    imageMobile: string;
    url: string;
    imageAtLeft?: boolean;
    btnText: string;
}

const useStyles = createUseStyles((theme) => ({
    image: {
        '& img': {
            objectFit: 'contain',
        },
    },
    sectionContainer: {
        overflow: 'hidden',
        padding: theme.spacing.spacing32,
        paddingBottom: theme.spacing.spacing64,
        [`${theme.mediaQueries.mobile}`]: {
            padding: theme.spacing.spacing16,
        },
        '& .text > .columns': {
            margin: `${theme.spacing.spacing16}px !important`,
        },
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '&.btn-container': {
            marginTop: theme.spacing.spacing16,
        },
    },
    title: {
        textAlign: 'center',
        color: theme.color.textPrimary,
        fontSize: theme.fontSize.size24,
        lineHeight: theme.lineHeight.size32,
        fontWeight: theme.fontWeight.bold,
    },
    subTitle: {
        textAlign: 'center',
        color: theme.color.textPrimary,
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.normal,
    },
}));

const CampaignsCatgoryProducts: FC<Prop> = (props) => {
    // hooks
    const {
        className,
        title,
        subTitle,
        image,
        imageMobile,
        url,
        btnText,
        imageAtLeft,
    } = props;
    const rootRef = React.createRef<HTMLDivElement>();
    const theme = useTheme<DefaultTheme>();
    const isMobile = useMediaQuery(
        `(max-width:${theme.breakpoints.tablet - 1}px)`,
    );
    const classes = useStyles({ theme });

    const { t } = useTranslation();

    // logic function

    // render function
    const cmpts = [
        <div
            className="text column is-half "
            key={`xmas-blog-section-${title}-text`}
        >
            <div className="columns is-flex-direction-column is-variable is-4 ">
                <div className={`${classes.titleContainer} column is-narrow`}>
                    <p className={classes.title}>{title}</p>
                </div>
                <div className={`${classes.titleContainer} column is-narrow`}>
                    <p className={classes.subTitle}>{subTitle}</p>
                </div>
                <div
                    className={`${classes.titleContainer} column is-narrow btn-container`}
                >
                    <RoundedButton to={url} title={btnText} />
                </div>
            </div>
        </div>,
        <div
            className="image column is-half"
            key={`xmas-blog-section-${title}-image`}
        >
            <Image
                src={isMobile ? imageMobile : image}
                className={classes.image}
                width="100%"
                height="338px"
            />
        </div>,
    ];

    return (
        <div
            ref={rootRef}
            className={`columns 
            is-gapless
            is-align-items-center ${classes.sectionContainer} ${
                className != null ? className : ''
            }`}
        >
            {imageAtLeft || isMobile ? cmpts.reverse() : cmpts}
        </div>
    );
};

export default CampaignsCatgoryProducts;
