import { lazy } from 'react';

export const Home = lazy(() => import('./home'));
export const About = lazy(() => import('./about'));
export const NotFound = lazy(() => import('./not-found'));
export const Merchant = lazy(() => import('./merchant'));
export const Merchants = lazy(() => import('./merchants'));
export const Tnc = lazy(() => import('./tnc'));
export const PrivacyPolicy = lazy(() => import('./privacy-policy'));
export const Standard = lazy(() => import('./standard'));
export const AuthTest = lazy(() => import('./auth-test'));
export const ForgetPw = lazy(() => import('./forget-pw'));
export const ResetPw = lazy(() => import('./reset-pw'));
export const Profile = lazy(() => import('./profile'));
export const FavMerchants = lazy(() => import('./fav-merchants'));
export const MerchantForm = lazy(() => import('./merchant-form'));
export const Campaign = lazy(() => import('./campaign'));
export const Blog = lazy(() => import('./blog'));
export const ConsumptionVoucher = lazy(() => import('./consumption-voucher'));
export const Takeaway = lazy(() => import('./takeaway'));
export const DoorToDoor = lazy(() => import('./door-to-door'));
export const Blogs = lazy(() => import('./blogs'));
// export const Lunar2023 = lazy(() => import('./cny2023'));
// export const Vday2023 = lazy(() => import('./vday2023'));
