import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { LinkProps } from 'react-router-dom';
import { LocaleLink, Image } from 'components';
import _ from 'lodash';
import { Tooltip } from '@mui/material';

interface Props extends LinkProps {
    iconSrc?: string;
    title: string;
    href?: string;
    tooltip?: string;
}

const useStyles = createUseStyles((theme) => ({
    btn: {
        height: 40,
        borderWidth: 2,
        borderRadius: 80,
        borderColor: theme.color.borderPrimaryOp4,
        paddingLeft: (props: Props) => {
            if (props.iconSrc != null) {
                return theme.spacing.spacing12;
            }
            return theme.spacing.spacing24;
        },
        paddingRight: theme.spacing.spacing24,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderStyle: 'solid',
    },
    iconContainer: {
        marginRight: theme.spacing.spacing12,
    },
    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        color: theme.color.textPrimary,
        flex: 1.0,
        textAlign: 'center',
    },
}));

const RoundedButton: FC<Props> = (props) => {
    // hooks
    const theme = useTheme();
    const classes = useStyles({ theme, ...props });
    const { iconSrc, title, to, className, tooltip } = props;

    // UI event function

    // logic function

    // render function

    const ButtonElement =
        to.toString().length > 0
            ? LocaleLink
            : (aProps: any) => (
                  <a {..._.omit(aProps, ['to'])}>{aProps.children}</a>
              );

    const txtEle = tooltip ? (
        <Tooltip arrow title={`${tooltip}`}>
            <p className={classes.title}>{title}</p>
        </Tooltip>
    ) : (
        <p className={classes.title}>{title}</p>
    );

    return (
        <ButtonElement
            {..._.omit(props, ['iconSrc', 'title'])}
            className={`${classes.btn} ${className != null ? className : ''}`}
        >
            {iconSrc != null && (
                <div className={classes.iconContainer}>
                    <Image width="24" height="24" src={iconSrc} />
                </div>
            )}

            {txtEle}
        </ButtonElement>
    );
};

export default RoundedButton;
