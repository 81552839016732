import create from 'zustand';
import { getSearchResultSet } from 'apis/merchant-algolia';
import type {
    MerchantIndex,
    LocationIndex,
    CategoryIndex,
    SearchParams,
} from 'apis/merchant-algolia.d';
import { patchEmptyLogoAndCover } from 'utils/merchant';

type State = {
    merchants: MerchantIndex[];
    locations: LocationIndex[];
    categories: CategoryIndex[];
    lastQueryId?: string;
    merchantState: {
        totalHits: number;
        page: number;
        totalPages: number;
        hitsPerPage: number;
    };
    searchKeyword: string | null;
    setSearchKeyword: (keyword: string | null) => void;
    fetchAll: (params: SearchParams) => void;
};

export const useAutoCompleteSearch = create<State>((set, get) => ({
    merchants: [],
    locations: [],
    categories: [],
    lastQueryId: undefined,
    merchantState: {
        totalHits: 0,
        page: 0,
        totalPages: 0,
        hitsPerPage: 0,
    },
    searchKeyword: null,
    setSearchKeyword: (keyword) => {
        set({ ...get(), searchKeyword: keyword });
    },
    fetchAll: async (params) => {
        const {
            merchants,
            categories,
            locations,
            merchantState,
            queryId,
        } = await getSearchResultSet(params);
        set({
            merchants: merchants.map(patchEmptyLogoAndCover),
            categories,
            locations,
            merchantState,
            lastQueryId: queryId,
        });
    },
}));
