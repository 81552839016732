import React, { FC } from 'react';

const ListRefresh: FC = () => {
    // hooks

    // UI event function

    // logic function

    // render function

    return <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>;
};

export default ListRefresh;
