import React, { useState, useEffect } from 'react';

export const useIntersection = (
    element: React.RefObject<HTMLElement>,
    rootMargin = '0px',
) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            },
            { rootMargin },
        );
        if (element.current) {
            observer.observe(element.current);
        }

        return element.current
            ? () => {
                  try {
                      observer.unobserve(element.current as any);
                  } catch (error) {
                      console.debug('ignored');
                  }
              }
            : undefined;
    }, []);

    return isVisible;
};
