import React, { useCallback, useEffect } from 'react';
import 'theme/global-style/bulma.sass';
import globalStyles from 'theme/global-style/global-jss';
import { ThemeProvider, useTheme } from 'react-jss';
import useMetaTags from 'react-metatags-hook';
import { useTranslation } from 'react-i18next';
import { lightConfig } from 'theme/theme-config';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
    onFirebaseAuthStateChanged,
    onFirebaseIdTokenChanged,
    currentUser,
} from 'apis/firebase-auth';
import { LastLocationProvider } from 'hooks/react-router-last-location';
import { I18nRoute } from './i18n-router';
import { useFeaturedStore } from './store/featured';
import { useUserStore } from './store/user';
import { useUserFavouriteStore } from './store/user-favourite';
import SelfRegistrationSuccess from './pages/self-registration-success';

const App = () => {
    const theme = useTheme();
    globalStyles({ theme });
    const { t } = useTranslation();

    useMetaTags(
        {
            title: t('meta-tags.default-title'),
            description: t('meta-tags.default-description'),
            openGraph: {
                title: t('meta-tags.default-title'),
                description: t('meta-tags.default-description'),
                image: `${window.location.hostname}/og-image.jpg`,
            },
        },
        [t],
    );

    const fetchFeatured = useFeaturedStore(
        useCallback((state) => state.fetch, []),
    );
    const { setUser, jwtToken } = useUserStore(
        useCallback(
            (state) => ({
                setUser: state.setUser,
                jwtToken: state.jwtToken,
            }),
            [],
        ),
    );
    const { clearFavourite, fetchFavourite } = useUserFavouriteStore(
        useCallback(
            (state) => ({
                clearFavourite: state.clear,
                fetchFavourite: state.fetch,
            }),
            [],
        ),
    );

    useEffect(() => {
        fetchFeatured();
        console.log('auth init');
        currentUser()?.getIdToken(true);
        onFirebaseAuthStateChanged((user) => {
            console.log(`onAuthStateChanged user`, user);
            setUser(user);
        });
        onFirebaseIdTokenChanged((user) => {
            console.log(`onIdTokenChanged user`, user);
            setUser(user);
        });
    }, []);

    useEffect(() => {
        if (jwtToken) {
            try {
                fetchFavourite();
            } catch (e) {
                console.log('fetch user favourites error', e);
            }
        } else {
            clearFavourite();
        }
    }, [jwtToken]);

    return (
        <BrowserRouter>
            <LastLocationProvider>
                <Routes>
                    <Route path="/*" element={<I18nRoute />} />
                    <Route
                        path="/self-registration-success"
                        element={<SelfRegistrationSuccess />}
                    />
                </Routes>
            </LastLocationProvider>
        </BrowserRouter>
    );
};

const ThemeProviderWrapper = () => {
    return (
        <ThemeProvider theme={lightConfig}>
            <App />
        </ThemeProvider>
    );
};

export default ThemeProviderWrapper;
