import React, { useEffect } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { Image, RoundedButton } from 'components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import { JoinUs } from 'utils/illustration';
import { homePageJoinMerchantSelected } from 'utils/gtm';

const useStyles = createUseStyles((theme) => ({
    join: {
        // width: '100%',
        backgroundColor: theme.color.bgPrimaryOp5,
        display: 'flex',
        flexDirection: 'row',
        height: 334,

        [`${theme.mediaQueries.mobile}`]: {
            height: 496,
            marginRight: theme.spacing.spacing16,
            marginLeft: theme.spacing.spacing16,
            marginBottom: theme.spacing.spacing16,
            borderRadius: theme.borderRadius.size8,
        },
        '& .image': {
            marginRight: theme.spacing.spacing32,
            [`${theme.mediaQueries.mobile}`]: {
                marginRight: 0,
            },
        },
        '& .title': {
            fontSize: theme.fontSize.size24,
            lineHeight: theme.lineHeight.size32,
            fontWeight: theme.fontWeight.bold,
        },
        '& .subtitle': {
            fontSize: theme.fontSize.size16,
            lineHeight: theme.lineHeight.size24,
            fontWeight: theme.fontWeight.normal,
            marginBottom: theme.spacing.spacing16,
        },
        '& .button': {
            backgroundColor: theme.color.btnPrimary,
            border: 'none',
        },
        '&.is-drawer': {
            height: 120,
            marginRight: 0,
            marginLeft: 0,
            marginBottom: 0,
            '& .title': {
                fontSize: theme.fontSize.size16,
                lineHeight: theme.lineHeight.size24,
                fontWeight: theme.fontWeight.bold,
            },
        },
    },
}));

type Props = {
    isDrawer?: boolean;
};
const LokoFooter = (props: Props) => {
    // hooks
    const { isDrawer } = props;

    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });
    const { t } = useTranslation();
    const isMobile = useMediaQuery(
        `(max-width:${theme.breakpoints.tablet - 1}px)`,
    );

    //tracking
    useEffect(() => {
        homePageJoinMerchantSelected();
    }, []);

    // UI event function

    // logic function

    // render function

    return (
        <div
            className={`${classes.join} is-flex ${
                isMobile && !isDrawer
                    ? 'is-flex-direction-column'
                    : 'is-flex-direction-row'
            } is-justify-content-center is-align-items-center ${
                isDrawer ? 'is-drawer' : ''
            }`}
        >
            <div>
                <Image
                    className="image"
                    fill={'x'}
                    src={JoinUs}
                    height={isDrawer ? '120' : '280'}
                    width={isDrawer ? '120' : '280'}
                />
            </div>
            <div
                className={`is-flex is-flex-direction-column is-justify-content-center 
is-align-content-center ${
                    isMobile
                        ? 'is-align-items-center'
                        : 'is-align-items-flex-start'
                }`}
            >
                <p className="title">{t('home.join-us-title')}</p>
                {isDrawer ? null : (
                    <p
                        className={`subtitle ${
                            isMobile ? 'has-text-centered' : ''
                        }`}
                    >
                        {ReactHtmlParser(t('home.join-us-subtitle'))}
                    </p>
                )}
                <div>
                    <RoundedButton
                        className={'button'}
                        title={t('home.join-us-button')}
                        to={'/merchant/join-us/'}
                    />
                </div>
            </div>
        </div>
    );
};

export default LokoFooter;
