import json from './basic-info.json';
import type {
    CategoryIndex,
    LocationIndex,
    FacetsCount,
    MerchantIndex,
} from './merchant-algolia.d';

export const getBasicInfo = async (): Promise<Response> => {
    // if ((window as any).BASIC_INFO_API_CACHE) {
    //     return (window as any).BASIC_INFO_API_CACHE;
    // }
    // return axios.get('/basic-info').then((r) => r.data);
    return json as any;
};

type Response = {
    categories: CategoryIndex[];
    locations: LocationIndex[];
    verifiedMerchants: MerchantIndex[];
    facetsCount: FacetsCount;
    campaigns: { path: string; title: string }[];
};
