import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Image } from 'components';

const useStyles = createUseStyles((theme) => ({
    result: {
        display: 'flex',
        minHeight: 48,
        cursor: 'pointer',
    },
    resultContextContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    resultIconContainer: {
        minWidth: 64,
        maxWidth: 64,
        flexBasis: 'auto',
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
}));

type Props = {
    text: string;
    icon: string;
    iconSize: string;
    onClicked?: () => void;
};
const SearchResult = (props: Props) => {
    const { t } = useTranslation();
    const { text, icon, iconSize, onClicked } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <div
            className={classes.result}
            key={`search-bar-result-${text}`}
            onClick={onClicked}
            role="link"
            tabIndex={0}
        >
            <div className={classes.resultIconContainer}>
                {icon ? (
                    <Image src={icon} width={iconSize} height={iconSize} />
                ) : null}
            </div>
            <div className={classes.resultContextContainer}>{text}</div>
        </div>
    );
};

export default SearchResult;
