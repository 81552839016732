import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { LocaleLink, Image } from 'components';

type Props = {
    categoryTitle: string;
    className?: string;
    to: string;
    icon?: string;
};

const useStyles = createUseStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: theme.color.btnSecondaryOp5,
        borderRadius: theme.borderRadius.size8,
        paddingTop: theme.spacing.spacing8,
        paddingBottom: theme.spacing.spacing8,
        paddingLeft: theme.spacing.spacing8,
        paddingRight: theme.spacing.spacing8,
        height: '100%',
    },
    iconView: {
        width: 48,
        height: 48,
    },
    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        color: theme.color.textPrimary,
        whiteSpace: 'pre-wrap',
        maxWidth: '100%',
        textAlign: 'center',
        [theme.mediaQueries.mobile]: {
            minWidth: 62,
            textOverflow: 'ellipsis',
        },
    },
}));

const CategoryButton = (props: Props) => {
    const { categoryTitle, className, to, icon } = props;
    // hooks
    const theme = useTheme();
    const classes = useStyles({ theme });

    // UI event function

    // logic function

    // render function

    return (
        <LocaleLink
            className={`${classes.buttonContainer} ${
                className != null && className
            }`}
            to={to}
        >
            <div className={classes.iconView}>
                {icon ? <Image src={icon} width="48" height="48" /> : null}
            </div>
            <p className={classes.title}>{categoryTitle}</p>
        </LocaleLink>
    );
};

export default CategoryButton;
