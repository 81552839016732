import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { LocaleLink, Image } from 'components';
import assetsPath from 'utils/assets-path';
import { useTranslation } from 'react-i18next';
import { CategoryIndex } from 'apis/merchant-algolia.d';
import moment from 'moment';
import { lokoSocialMediaSelected } from 'utils/gtm';

type Props = {
    categories: CategoryIndex[];
};

const useStyles = createUseStyles((theme) => ({
    footer: {
        backgroundColor: theme.color.bgPrimaryOp3,
        [`@media (max-width: ${theme.breakpoints.tablet - 1}px)`]: {
            backgroundColor: theme.color.bgPrimaryOp1,
        },
    },
    footerContainer: {
        [`@media (max-width: ${theme.breakpoints.tablet - 1}px)`]: {
            paddingLeft: theme.spacing.spacing16,
            paddingRight: theme.spacing.spacing16,
        },
    },
    linkContainer: {
        paddingTop: theme.spacing.spacing40,
        paddingBottom: theme.spacing.spacing32,
    },
    linkGroupView: {
        width: 160,
        display: 'flex',
        flexDirection: 'column',
        [`@media (max-width: ${theme.breakpoints.tablet - 1}px)`]: {
            marginBottom: theme.spacing.spacing40,
        },
    },
    linkGroupTitle: {
        width: '100%',
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.bold,
        color: theme.color.textPrimary,
        textAlign: 'left',
        marginBottom: theme.spacing.spacing8,
        [`@media (max-width: ${theme.breakpoints.tablet - 1}px)`]: {
            color: theme.color.textThird,
        },
    },
    link: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.normal,
        color: theme.color.textPrimary,
        textAlign: 'left',
        marginBottom: theme.spacing.spacing8,
        [`@media (max-width: ${theme.breakpoints.tablet - 1}px)`]: {
            color: theme.color.textThird,
        },
    },
    socialContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        [`@media (max-width: ${theme.breakpoints.tablet - 1}px)`]: {
            justifyContent: 'flex-start',
        },
    },
    socialGroupView: {
        width: 240,
        display: 'flex',
        flexDirection: 'column',
    },
    socialGroupTitle: {
        extend: 'link',
    },
    socialLink: {
        extend: 'link',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: theme.spacing.spacing12,
        marginRight: theme.spacing.spacing12,
    },
    socialLinkTitle: {
        marginLeft: theme.spacing.spacing8,
    },
    separateLine: {
        height: 1,
        backgroundColor: theme.color.borderPrimaryOp4,
    },
    copyRightContainer: {
        paddingTop: theme.spacing.spacing32,
        paddingBottom: theme.spacing.spacing32,
        display: 'flex',
        flexDirection: 'row',
    },
    copyRightText: {
        fontSize: theme.fontSize.size12,
        lineHeight: theme.lineHeight.size16,
        color: theme.color.textPrimary,
        [`@media (max-width: ${theme.breakpoints.tablet - 1}px)`]: {
            color: theme.color.textThird,
        },
    },
    lawBtn: {
        extend: 'copyRightText',
        textDecorationLine: 'underline',
        marginLeft: theme.spacing.spacing16,
    },
}));

const LokoFooter = (props: Props) => {
    // hooks
    const { categories } = props;
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { t } = useTranslation();

    // UI event function

    // logic function

    // render function

    return (
        <footer className={`${classes.footer}`}>
            <div className={`loko-container ${classes.footerContainer}`}>
                <div className={classes.linkContainer}>
                    <div className={`columns is-gapless`}>
                        <div className={`column is-narrow`}>
                            <div className={classes.linkGroupView}>
                                <p className={classes.linkGroupTitle}>
                                    {t('footer.local-title')}
                                </p>
                                <LocaleLink
                                    to={'/about-us'}
                                    className={classes.link}
                                >
                                    {t('footer.about-us')}
                                </LocaleLink>
                                <LocaleLink
                                    to={'/standard'}
                                    className={classes.link}
                                >
                                    {t('footer.rules')}
                                </LocaleLink>
                                {/* <a className={classes.link}>
                                    {t('footer.event')}
                                </a> */}
                            </div>
                        </div>
                        {/* <div className={`column is-narrow`}>
                            <div className={classes.linkGroupView}>
                                <p className={classes.linkGroupTitle}>
                                    {t('footer.merchant-title')}
                                </p>
                                {categories.map((category) => (
                                    <a
                                        key={`loko-footer-category-${category.objectID}`}
                                        className={classes.link}
                                    >
                                        {category.name}
                                    </a>
                                ))}
                                <a className={classes.link}>
                                    {t('common.location')}
                                </a>
                            </div>
                        </div> */}
                        <div className={`column is-narrow`}>
                            <div className={classes.linkGroupView}>
                                <p className={classes.linkGroupTitle}>
                                    {t('footer.user-title')}
                                </p>
                                <LocaleLink
                                    to={'/profile'}
                                    className={classes.link}
                                >
                                    {t('footer.my-ac')}
                                </LocaleLink>
                                <LocaleLink
                                    to={'/fav-merchants'}
                                    className={classes.link}
                                >
                                    {t('footer.my-fav')}
                                </LocaleLink>
                            </div>
                        </div>
                        <div className={`column is-narrow`}>
                            <div className={classes.linkGroupView}>
                                <p className={classes.linkGroupTitle}>
                                    {t('footer.merchant-title')}
                                </p>
                                <LocaleLink
                                    to={'/merchant/join-us/'}
                                    className={classes.link}
                                >
                                    {t('footer.merchant-join')}
                                </LocaleLink>
                            </div>
                        </div>
                        <div className={`column ${classes.socialContainer}`}>
                            <div className={classes.socialGroupView}>
                                <p className={classes.socialGroupTitle}>
                                    {t('footer.contact-us')}
                                </p>
                                <div className="is-flex">
                                    <a
                                        className={classes.socialLink}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            lokoSocialMediaSelected('Facebook');
                                        }}
                                        href="https://www.facebook.com/loko.hk"
                                    >
                                        <Image
                                            src={assetsPath('facebook')}
                                            width="24"
                                            height="24"
                                        />
                                    </a>
                                    <a
                                        className={classes.socialLink}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            lokoSocialMediaSelected(
                                                'Instagram',
                                            );
                                        }}
                                        href="https://www.instagram.com/loko.hk/"
                                    >
                                        <Image
                                            src={assetsPath('instagram')}
                                            width="24"
                                            height="24"
                                        />
                                    </a>
                                    <a
                                        className={classes.socialLink}
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            lokoSocialMediaSelected('Whatsapp');
                                        }}
                                        href="https://wa.me/85264507502"
                                    >
                                        <Image
                                            src={assetsPath('whatsapp')}
                                            width="24"
                                            height="24"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.separateLine} />
                <div className={classes.copyRightContainer}>
                    <p className={classes.copyRightText}>
                        {t('footer.all-right-reserved', {
                            year: moment().year(),
                        })}
                    </p>
                    <LocaleLink to="/tnc" className={classes.lawBtn}>
                        {t('footer.law')}
                    </LocaleLink>
                    <LocaleLink to="/privacy-policy" className={classes.lawBtn}>
                        {t('footer.privacy')}
                    </LocaleLink>
                </div>
            </div>
        </footer>
    );
};

export default LokoFooter;
