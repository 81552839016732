import React, { FC, useCallback, useState } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { MerchantCard } from 'components';
import { clickedMerchantAtMerchants } from 'apis/aloglia-insight';
import urlEncode from 'utils/url-encode';
import { Grid, WindowScroller, GridCellProps } from 'react-virtualized';

type Props = {
    isMobile: boolean;
    rowCount: number;
    gridWidth: number;
    itemData: any;
};

const useStyles = createUseStyles((theme) => ({
    grid: {
        overflow: 'hidden !important',
    },
}));

const MerchantsGrid: FC<Props> = (props) => {
    const { isMobile, rowCount, gridWidth, itemData } = props;
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });
    const [cssPatched, setCssPatched] = useState(false);

    //Hacky to patch css class
    const patchCss = useCallback(() => {
        if (cssPatched) {
            return;
        }
        console.log('patch sss');
        const el = document.querySelector(
            '#merchants-grid div.ReactVirtualized__Grid__innerScrollContainer',
        );
        if (el) {
            const classlist = el.classList;

            'columns is-variable is-8 is-multiline'
                .split(' ')
                .forEach((cls) => {
                    classlist.add(cls.trim());
                });
            setCssPatched(true);
        }
    }, []);

    // Grid cell component
    const cellRenderer = ({ columnIndex, rowIndex, style }: GridCellProps) => {
        const {
            isAuth,
            displayMerchants,
            setLoginInPage,
            setLoginMenuVisible,
            onFavouriteButtonClicked,
        } = itemData;

        const index = isMobile ? rowIndex : rowIndex * 3 + columnIndex;
        const merchant = displayMerchants[index];

        if (!merchant) {
            return <div className="emptyPlaceholder"></div>;
        }
        return (
            <div
                style={style}
                className="column"
                key={`merchants-card-${merchant.objectId}`}
            >
                <MerchantCard
                    cardType={isMobile ? 'small' : 'big'}
                    onClick={() => {
                        clickedMerchantAtMerchants(
                            merchant.objectId,
                            index + 1,
                        );
                    }}
                    to={`merchant/${urlEncode(merchant.title)}-${urlEncode(
                        merchant.objectId,
                    )}`}
                    bookmarkBtnOnClick={() => {
                        if (isAuth === false) {
                            setLoginInPage('login');
                            setLoginMenuVisible(true);
                        } else {
                            onFavouriteButtonClicked(merchant);
                        }
                    }}
                    {...merchant}
                />
            </div>
        );
    };

    return (
        <WindowScroller>
            {({ height, isScrolling, onChildScroll, scrollTop }) => (
                <Grid
                    className={classes.grid}
                    onSectionRendered={patchCss}
                    autoHeight
                    height={height}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    scrollTop={scrollTop}
                    id="merchants-grid"
                    cellRenderer={cellRenderer}
                    columnCount={isMobile ? 1 : 3} // if mobile, only show 1 coloumn per row, else 3 coloumn
                    rowCount={rowCount}
                    width={gridWidth}
                    autoContainerWidth
                    rowHeight={
                        (isMobile ? 180 : 236) +
                        theme.spacing.spacing8 * 2 + //Padding
                        theme.spacing.spacing2 * 2 //Border Width
                    }
                    columnWidth={
                        isMobile
                            ? gridWidth - theme.spacing.spacing16
                            : gridWidth / 3
                    }
                ></Grid>
            )}
        </WindowScroller>
    );
};

export default MerchantsGrid;
