import React, { useRef, useEffect, useCallback } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import ConstantsConfig from 'constants/constants-config';
import {
    SearchPlaceholderButton,
    LocaleLink,
    MegaMenu,
    Image,
    // RoundedButton,
} from 'components';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import type { Search } from 'apis/featured';
import type { CategoryIndex, LocationIndex } from 'apis/merchant-algolia.d';
import SearchBar from 'components/search-bar';
import BlogIcon from 'assets/blog.svg';
import * as _ from 'lodash';
import assetsPath from 'utils/assets-path';
import { useLocation } from 'react-router-dom';
import { useUiStore } from 'store/ui';
import { logo } from 'utils/i18n';
import { merchantPathBySearch } from 'utils/merchants-path';
import {
    lokoIconSelected,
    searchBoxSelected,
    homePagePopularSearchSelected,
} from 'utils/gtm';

type Props = {
    isLogined: boolean;
    desktopCollapse: boolean;
    searches: Search[];
    onClickMobileMenu?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
    onClickProfileBtn?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
    onClickFavouriteBtn?:
        | React.MouseEventHandler<HTMLAnchorElement>
        | undefined;
    categories: CategoryIndex[];
    locations: LocationIndex[];
};

const DesktopNavHeight = 144;
const MobileNavHeight = 56;

const useBodyPaddingStyles = createUseStyles((theme) => ({
    '@global': {
        body: {
            paddingTop: DesktopNavHeight,
        },
        [`@media (max-width: ${theme.breakpoints.desktop - 1}px)`]: {
            body: {
                paddingTop: MobileNavHeight,
            },
        },
    },
}));

const useDesktopStyles = createUseStyles((theme) => ({
    nav: {
        display: 'flex',
        flexDirection: 'column',
        height: DesktopNavHeight,
        transition: ['height', `${ConstantsConfig.NavHeaderCollapseTime}s`],
    },
    navCollapse: {
        height: 56,
    },
    brandContainer: {
        width: '100%',
        flex: 1.0,
        display: 'flex',
        flexDirection: 'row',
    },
    navItemContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 56,
        alignItems: 'center',
        opacity: 1,
        transition: [
            ['opacity', `${ConstantsConfig.NavHeaderCollapseTime / 2.0}s`],
            ['height', `${ConstantsConfig.NavHeaderCollapseTime}s`],
        ],
    },
    navItemContainerCollapse: {
        opacity: 0,
        height: 0,
    },
    brandIconView: {
        width: 200,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    brandIcon: {
        maxWidth: 180,
        height: 24,
    },
    rightIconView: {
        width: 200,
        height: '100%',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    rightIconContainer: {
        display: 'flex',
        flexDirection: 'row',
        '& $iconBtn': {
            marginRight: theme.spacing.spacing8,
            '&:last-child': {
                marginRight: 0,
            },
        },
    },
    iconBtn: {
        width: 40,
        height: 40,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchView: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1.0,
        display: 'flex',
    },
    searchContainer: {
        width: 600,
        height: 40,
        position: 'relative',
    },
    categoryView: {
        height: '100%',
        marginRight: theme.spacing.spacing36,
        display: 'flex',
        flexDirection: 'row',
        '& $menuWrapper': {
            marginLeft: theme.spacing.spacing12,
            marginRight: theme.spacing.spacing12,
            '&:first-child': {
                marginLeft: 0,
                marginRight: theme.spacing.spacing12,
            },
            '&:last-child': {
                marginLeft: theme.spacing.spacing12,
                marginRight: 0,
            },
        },
    },
    categoryItem: {
        height: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.bold,
        color: theme.color.textPrimary,
    },
    categoryItemHoverLine: {
        display: 'none',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: 3,
        background: theme.color.btnPrimary,
    },
    separateLine: {
        height: 19,
        border: [['1.5px', 'solid', theme.color.borderPrimary]],
        backgroundColor: theme.color.borderPrimary,
    },
    hotSearchTitle: {
        marginLeft: theme.spacing.spacing36,
        marginRight: theme.spacing.spacing24,
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.normal,
        color: theme.color.textPrimaryOp1,
    },
    hotSearchView: {
        extend: 'categoryView',
        marginRight: 0,
        '@global': {
            a: {
                marginLeft: theme.spacing.spacing12,
                marginRight: theme.spacing.spacing12,
                '&:first-child': {
                    marginLeft: 0,
                    marginRight: theme.spacing.spacing12,
                },
                '&:last-child': {
                    marginLeft: theme.spacing.spacing12,
                    marginRight: 0,
                },
            },
        },
    },
    hotSearchItem: {
        extend: 'categoryItem',
        fontWeight: theme.fontWeight.normal,
    },
    menuWrapper: {
        '&:hover $megaMenu': {
            visibility: 'visible',
            opacity: 1,
            transition: [
                ['opacity', `${ConstantsConfig.MegaMenuHiddenTime}s`, 'linear'],
            ],
        },
        '&:hover $categoryItemHoverLine': {
            display: 'block',
        },
    },
    megaMenu: {
        visibility: 'hidden',
        opacity: 0,
        transition: [
            ['visibility', '0s', `${ConstantsConfig.MegaMenuHiddenTime}s`],
            ['opacity', `${ConstantsConfig.MegaMenuHiddenTime}s`, 'linear'],
        ],
        position: 'absolute',
        width: '100%',
        left: 0,
        zIndex: 1,
    },
    joinUsBtn: {
        width: 80,
        backgroundColor: theme.color.btnPrimary,
        borderRadius: 80,
    },
}));

const useMobileStyles = createUseStyles((theme) => ({
    nav: {
        display: 'flex',
        flexDirection: 'row',
        height: MobileNavHeight,
        alignItems: 'center',
        paddingLeft: theme.spacing.spacing8,
        paddingRight: theme.spacing.spacing8,
    },
    menuBtn: {
        display: 'flex',
        width: 40,
        height: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    brandIcon: {
        maxWidth: 140,
        height: 19,
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'flex-start',
        },
        '& img': {
            objectFit: 'contain',
        },
    },
    rightIconView: {
        flex: 1.0,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    joinUsBtn: {
        width: 60,
        backgroundColor: theme.color.btnPrimary,
        borderRadius: 80,
        fontSize: theme.fontSize.size12,
        fontWeight: theme.fontWeight.bold,
        marginTop: theme.spacing.spacing4,
        marginBottom: theme.spacing.spacing4,
    },
}));

const LokoHeader = (props: Props) => {
    const {
        desktopCollapse,
        searches,
        onClickMobileMenu,
        categories,
        locations,
        onClickProfileBtn,
        onClickFavouriteBtn,
        isLogined,
    } = props;

    // hooks
    const { t } = useTranslation();
    const searchBarRef = useRef<HTMLDivElement>(null);
    const theme = useTheme<DefaultTheme>();
    useBodyPaddingStyles({ theme });
    const desktopClasses = useDesktopStyles({ theme });
    const mobileClasses = useMobileStyles({ theme });
    const isMobile = useMediaQuery(
        `(max-width:${theme.breakpoints.tablet - 1}px)`,
    );
    const [showSearch, setShowSearch] = React.useState(false);
    const [topLevelCategories, setTopLevelCategories] = React.useState<
        CategoryIndex[]
    >([]);
    const [showMegaMenu, setShowMegaMenu] = React.useState<boolean>(
        desktopCollapse,
    );
    const location = useLocation();
    const { setMobileSearchBarVisible } = useUiStore(
        useCallback((state) => state, []),
    );

    useEffect(() => {
        setShowMegaMenu(false);
        setTimeout(() => {
            if (desktopCollapse === false) {
                setShowMegaMenu(true);
            }
        }, 100);
    }, [location]);

    useEffect(() => {
        if (desktopCollapse === true && showMegaMenu === true) {
            setShowMegaMenu(false);
        } else if (desktopCollapse === false && showMegaMenu === false) {
            setTimeout(() => {
                if (desktopCollapse === false) {
                    setShowMegaMenu(true);
                }
            }, ConstantsConfig.NavHeaderCollapseTime * 1000);
        }
    }, [desktopCollapse]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const clickedTarget = event.target as HTMLElement;
            if (
                searchBarRef.current &&
                !searchBarRef.current.contains(clickedTarget)
            ) {
                setShowSearch(false);
            }
        };

        if (showSearch) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchBarRef, showSearch]);

    useEffect(() => {
        setTopLevelCategories(
            _.filter(categories, (c) => c.type === 'categories_1st'),
        );
    }, [categories]);

    // logic function

    // UI computed value
    const getDesktopNavClassName = () => {
        let className = `is-hidden-mobile loko-container ${desktopClasses.nav}`;

        if (desktopCollapse) {
            className += ` ${desktopClasses.navCollapse}`;
        }

        return className;
    };

    const getNavItemContainerClassName = () => {
        let className = `${desktopClasses.navItemContainer}`;

        if (desktopCollapse) {
            className += ` ${desktopClasses.navItemContainerCollapse}`;
        }

        return className;
    };

    // render function
    const renderBrandIconView = (isDesktop: boolean) => {
        return (
            <LocaleLink to="/" onClick={lokoIconSelected}>
                <div
                    className={
                        isDesktop
                            ? `${desktopClasses.brandIcon}`
                            : `${mobileClasses.brandIcon}`
                    }
                >
                    {logo()}
                </div>
            </LocaleLink>
        );
    };

    const renderIconView = () => {
        return (
            <div className={desktopClasses.rightIconContainer}>
                {location.pathname !== '/' && (
                    <a
                        className={`${desktopClasses.iconBtn} is-hidden-tablet`}
                        onClick={() => setMobileSearchBarVisible(true)}
                        tabIndex={0}
                        role="button"
                    >
                        <Image
                            src={assetsPath('magnifier')}
                            width="24"
                            height="24"
                        />
                    </a>
                )}

                {!isMobile && isLogined === true && (
                    <a
                        className={desktopClasses.iconBtn}
                        onClick={onClickFavouriteBtn}
                        role="button"
                        tabIndex={0}
                    >
                        <Image
                            src={assetsPath('heart-button-off')}
                            width="24"
                            height="24"
                        />
                    </a>
                )}
                {!isMobile && (
                    <a
                        className={desktopClasses.iconBtn}
                        onClick={onClickProfileBtn}
                        role="button"
                        tabIndex={0}
                    >
                        <Image
                            src={assetsPath('profile')}
                            width="24"
                            height="24"
                        />
                    </a>
                )}

                <LocaleLink className={desktopClasses.iconBtn} to={'/blogs'}>
                    <Image src={BlogIcon} width="32" height="32" />
                </LocaleLink>

                {location.pathname !== '/merchant/join-us/' && (
                    <LocaleLink
                        className={`${
                            isMobile
                                ? mobileClasses.joinUsBtn
                                : desktopClasses.joinUsBtn
                        } is-flex is-justify-content-center is-align-items-center`}
                        to={'/merchant/join-us/'}
                    >
                        {t('home.join-us-button')}
                    </LocaleLink>
                )}
            </div>
        );
    };

    return (
        <>
            <nav className={getDesktopNavClassName()}>
                <div className={`${desktopClasses.brandContainer}`}>
                    <div className={desktopClasses.brandIconView}>
                        {renderBrandIconView(true)}
                    </div>
                    <div className={`${desktopClasses.searchView}`}>
                        <div className={desktopClasses.searchContainer}>
                            <SearchPlaceholderButton
                                onClick={() => {
                                    setShowSearch(true);
                                    searchBoxSelected();
                                }}
                            />
                            <SearchBar
                                isVisible={showSearch}
                                componentRef={searchBarRef}
                            />
                        </div>
                    </div>
                    <div className={desktopClasses.rightIconView}>
                        {renderIconView()}
                    </div>
                </div>
                <div className={getNavItemContainerClassName()}>
                    <div className={desktopClasses.categoryView}>
                        {topLevelCategories.map((topLevelCat) => (
                            <div
                                className={desktopClasses.menuWrapper}
                                key={`desktop-nav-item-${topLevelCat.objectID}`}
                            >
                                <a className={desktopClasses.categoryItem}>
                                    {topLevelCat.name}
                                    <div
                                        className={
                                            desktopClasses.categoryItemHoverLine
                                        }
                                    />
                                </a>
                                {showMegaMenu === true && (
                                    <MegaMenu
                                        className={desktopClasses.megaMenu}
                                        parentMenu={topLevelCat}
                                        menus={categories}
                                    />
                                )}
                            </div>
                        ))}
                        <div className={desktopClasses.menuWrapper}>
                            <a className={desktopClasses.categoryItem}>
                                {t('common.location')}
                                <div
                                    className={
                                        desktopClasses.categoryItemHoverLine
                                    }
                                />
                            </a>
                            {showMegaMenu === true && (
                                <MegaMenu
                                    className={desktopClasses.megaMenu}
                                    parentMenu={{
                                        type: 'area',
                                        name: '地區',
                                        parent: undefined,
                                        keywords: [],
                                        objectID: '地區',
                                        icon: 'location',
                                    }}
                                    menus={locations}
                                />
                            )}
                        </div>
                    </div>
                    <div className={desktopClasses.separateLine} />
                    <p className={desktopClasses.hotSearchTitle}>
                        <Trans i18nKey="header.hottest-search"></Trans>
                    </p>
                    <div className={desktopClasses.hotSearchView}>
                        {searches?.map((s) =>
                            renderSearchLink(s, desktopClasses),
                        )}
                    </div>
                </div>
            </nav>
            <nav className={`is-hidden-tablet ${mobileClasses.nav}`}>
                <a
                    className={mobileClasses.menuBtn}
                    role="button"
                    onClick={onClickMobileMenu}
                    tabIndex={-1}
                >
                    <Image src={assetsPath('menu')} width="24" height="24" />
                </a>
                {renderBrandIconView(false)}
                <div className={mobileClasses.rightIconView}>
                    {renderIconView()}
                </div>
            </nav>
        </>
    );
};

export default LokoHeader;

function renderSearchLink(search: Search, desktopClasses: any) {
    const merchantPath = merchantPathBySearch(search);

    return (
        <LocaleLink
            key={search.id}
            className={desktopClasses.hotSearchItem}
            onClick={() => {
                homePagePopularSearchSelected(search.name);
            }}
            to={merchantPath}
        >
            {search.name}
        </LocaleLink>
    );
}
