import React, { FC, useState, useEffect } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { Image, LocaleLink } from 'components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import assetsPath from 'utils/assets-path';

type Props = {
    title: string;
    thumbnail: string;
    description: string;
    time: number;
    link: string;
};

const useStyles = createUseStyles((theme) => ({
    cardContainer: {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: theme.color.borderPrimaryOp4,
        borderRadius: theme.borderRadius.size8,
        padding: theme.spacing.spacing16,
        color: theme.color.textPrimary,
    },
    thumbnail: {
        borderRadius: theme.borderRadius.size4,
        overflow: 'hidden',
    },
    title: {
        marginTop: theme.spacing.spacing16,
        overflow: 'hidden',
        WebkitLineClamp: 1,
        display: '-webkit-box',
        boxOrient: 'vertical',
    },
    content: {
        marginTop: theme.spacing.spacing4,
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        overflow: 'hidden',
        WebkitLineClamp: 2,
        display: '-webkit-box',
        boxOrient: 'vertical',
        minHeight: 48,
    },
    date: {
        fontSize: theme.fontSize.size12,
        lineHeight: theme.lineHeight.size16,
        color: theme.color.textPrimaryOp1,
    },
    detailBtn: {
        marginTop: theme.spacing.spacing8,
        display: 'flex',
        flexDirection: 'row',
        fontSize: theme.fontSize.size12,
        fontWeight: theme.fontWeight.bold,
        lineHeight: theme.lineHeight.size16,
        color: theme.color.textSecondary,
        alignItems: 'center',
        width: 'fit-content',
        '&:hover': {
            color: theme.color.textSecondary,
        },
    },
}));

const BlogCard: FC<Props> = (props) => {
    const { title, thumbnail, description, time, link } = props;
    // hooks
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });
    const { t } = useTranslation();
    const rootRef = React.createRef<HTMLDivElement>();

    // UI event function

    // logic function

    // computed value
    const getBlogDate = () => {
        if (time == null) {
            return '';
        }

        return t('campaign.date', {
            year: moment(time).local().get('year').toString(),
            month: (moment(time).local().get('month') + 1).toString(),
            day: moment(time).local().get('date').toString(),
        });
    };

    // render function

    return (
        <div className={classes.cardContainer} ref={rootRef}>
            <Image
                width="100%"
                height="120"
                layout="fixed-height"
                src={thumbnail}
                lazyLoad
                threshold={30}
                className={classes.thumbnail}
            />
            <h3 className={classes.title}>{title}</h3>
            <div className={classes.content}>{description}</div>
            <div className={classes.date}>{getBlogDate()}</div>
            <LocaleLink
                className={classes.detailBtn}
                to={link.startsWith('http') ? link : `/blog/${link}`}
            >
                {t('home.blog-detail')}
                <Image
                    src={assetsPath('arrow-chevron-right')}
                    height="16"
                    width="16"
                    stroke={theme.color.textSecondary}
                />
            </LocaleLink>
        </div>
    );
};

export default BlogCard;
