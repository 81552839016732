import React, { FC, useState, HTMLAttributes, useCallback } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { SnapScroll, MerchantCard } from 'components';
import urlEncode from 'utils/url-encode';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, CircularProgress } from '@mui/material';
import { MerchantIndex } from 'apis/merchant-algolia.d';
import { merchantIndexToMerchantCardProps } from 'utils/merchant';
import { useUserStore } from 'store/user';
import { useUiStore } from 'store/ui';
import { clickedMerchantCard } from 'apis/aloglia-insight';
import { useUserFavouriteStore } from 'store/user-favourite';
import { merchantAddFavouriteSuccessful } from 'utils/gtm';
import { useIntersection } from 'components/use-intersection';

export interface MerchantsSnapScrollProps
    extends HTMLAttributes<HTMLDivElement> {
    title: string;
    subTitle: string;
    merchants: MerchantIndex[];
}

const useStyles = createUseStyles((theme) => ({
    sectionContainer: {
        overflow: 'hidden',
        padding: theme.spacing.spacing32,
        paddingBottom: theme.spacing.spacing64,
        [`${theme.mediaQueries.mobile}`]: {
            padding: theme.spacing.spacing16,
        },
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: theme.spacing.spacing32,
    },
    title: {
        textAlign: 'center',
        color: theme.color.textFive,
        fontSize: theme.fontSize.size24,
        lineHeight: theme.lineHeight.size32,
        fontWeight: theme.fontWeight.bold,
        maxWidth: 368,
    },
    subTitle: {
        textAlign: 'center',
        color: theme.color.textPrimary,
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.normal,
        maxWidth: 368,
    },

    featuredMerchantsMerchantCard: {
        width: 240,
        marginRight: theme.spacing.spacing16,
        flexShrink: 0,
        scrollSnapAlign: 'start',
        scrollSnapStop: 'always',
    },

    contextContainer: {
        minHeight: 240,
    },
}));

const CampaignsCatgoryProducts: FC<MerchantsSnapScrollProps> = (props) => {
    // hooks
    const { className, title, subTitle, merchants } = props;
    const rootRef = React.createRef<HTMLDivElement>();
    const inViewPort = useIntersection(rootRef, '-50px');
    const theme = useTheme<DefaultTheme>();
    const classes = useStyles({ theme });
    const [loadedAll, setLoadedAll] = useState(false);

    const isMobile = useMediaQuery(
        `(max-width:${theme.breakpoints.tablet - 1}px)`,
    );

    const { t } = useTranslation();

    const {
        userFavouriteMerchantIds,
        addFavourites,
        removeFavourites,
    } = useUserFavouriteStore(
        useCallback(
            (state) => ({
                userFavouriteMerchantIds: state.userFavouriteMerchantIds,
                addFavourites: state.add,
                removeFavourites: state.remove,
            }),
            [],
        ),
    );

    const { isAuth } = useUserStore(
        useCallback((state) => ({ isAuth: state.isAuth }), []),
    );

    const { setLoginMenuVisible, setLoginInPage } = useUiStore(
        useCallback((state) => state, []),
    );

    const [isHideControlBtn, setIsHideControlBtn] = useState(true);

    // UI event function
    const onFavouriteButtonClicked = useCallback((merchant: any) => {
        if (merchant.isBookmark) {
            removeFavourites([merchant.objectId]);
        } else {
            addFavourites([merchant.objectId]);
            merchantAddFavouriteSuccessful(merchant.title, merchant.objectId);
        }
    }, []);

    const [merchantsToDisplay, setMerchantsToDisplay] = useState<
        MerchantIndex[]
    >([]);
    React.useEffect(() => {
        if (loadedAll || merchants.length === 0) {
            return;
        }
        if (inViewPort) {
            console.log('load all');
            setMerchantsToDisplay(merchants);
            setLoadedAll(true);
        } else {
            setMerchantsToDisplay(merchants.slice(0, 1));
        }
    }, [inViewPort, merchants]);

    // logic function

    // render function

    // render function
    let contextEle = (
        <div
            className={`columns 
            is-gapless is-align-items-center is-justify-content-center has-text-centered`}
        >
            <div className="column">
                <CircularProgress />
            </div>
        </div>
    );
    if (merchants && merchants.length > 0) {
        contextEle = (
            <SnapScroll
                padding={isMobile ? theme.spacing.spacing16 : 0}
                hideControlBtn={isHideControlBtn}
            >
                {merchantsToDisplay.map((m) => {
                    const merchant = merchantIndexToMerchantCardProps({
                        merchantIndex: m,
                        isBookmark:
                            userFavouriteMerchantIds?.includes(m.objectID) ||
                            false,
                    });
                    return (
                        <div
                            key={`snap-scroll-mc-${title}-${merchant.objectId}`}
                            className={classes.featuredMerchantsMerchantCard}
                        >
                            <MerchantCard
                                onClick={() => {
                                    clickedMerchantCard(merchant.objectId);
                                }}
                                to={`/merchant/${urlEncode(merchant.title)}-${
                                    merchant.objectId
                                }`}
                                bookmarkBtnOnClick={() => {
                                    if (isAuth === false) {
                                        setLoginInPage('login');
                                        setLoginMenuVisible(true);
                                    } else {
                                        onFavouriteButtonClicked(merchant);
                                    }
                                }}
                                {...merchant}
                            />
                        </div>
                    );
                })}
            </SnapScroll>
        );
    }
    return (
        <div
            ref={rootRef}
            className={`${classes.sectionContainer} ${
                className != null ? className : ''
            }`}
            onMouseEnter={() => setIsHideControlBtn(false)}
            onMouseLeave={() => setIsHideControlBtn(true)}
        >
            <div className={classes.titleContainer}>
                <p className={classes.title}>{title}</p>
            </div>
            <div className={classes.titleContainer}>
                <p className={classes.subTitle}>{subTitle}</p>
            </div>
            <div className={classes.contextContainer}>{contextEle}</div>
        </div>
    );
};

export default CampaignsCatgoryProducts;
