import create from 'zustand';
import { getBasicInfo } from 'apis/basic-info';
import type {
    CategoryIndex,
    LocationIndex,
    FacetsCount,
    MerchantIndex,
} from 'apis/merchant-algolia.d';
import * as _ from 'lodash';

type State = {
    inited: boolean;
    categories: { [key: string]: CategoryIndex };
    locations: { [key: string]: LocationIndex };
    verifiedMerchants: MerchantIndex[];
    facetsCount?: FacetsCount;
    campaigns?: { path: string; title: string }[];
    fetch: () => void;
};

export const useBasicInfoStore = create<State>((set, get) => ({
    inited: false,
    categories: {},
    locations: {},
    facetsCount: undefined,
    verifiedMerchants: [],
    fetch: async () => {
        const { inited } = get();
        if (inited) {
            return;
        }
        const result = await getBasicInfo();
        set({
            ...{
                categories: _.keyBy(result.categories, (c) => c.objectID),
                locations: _.keyBy(result.locations, (l) => l.objectID),
            },
            facetsCount: result.facetsCount,
            verifiedMerchants: _.compact(result.verifiedMerchants),
            campaigns: result.campaigns,
            inited: true,
        });
    },
}));
