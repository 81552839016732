import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { LocaleLink, Image } from 'components';
import { LinkProps } from 'react-router-dom';
import _ from 'lodash';
import assetsPath from 'utils/assets-path';

interface Props extends LinkProps {
    iconSrc: string | null;
    categoryTitle: string;
    subTitle?: string;
    showRightArrow?: boolean;
    normalTitle?: boolean;
}

const useStyles = createUseStyles((theme) => ({
    btn: {
        padding: theme.spacing.spacing12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: theme.color.btnSecondaryOp5,
        borderRadius: theme.borderRadius.size8,
    },
    title: {
        fontSize: (props: Props) => {
            if (props.normalTitle != null && props.normalTitle === true) {
                return theme.fontSize.size16;
            }
            return theme.fontSize.size18;
        },
        fontWeight: (props: Props) => {
            if (props.normalTitle != null && props.normalTitle === true) {
                return theme.fontWeight.normal;
            }
            return theme.fontWeight.bold;
        },
        lineHeight: (props: Props) => {
            if (props.normalTitle != null && props.normalTitle === true) {
                return theme.lineHeight.size24;
            }
            return theme.lineHeight.size32;
        },
        color: theme.color.textPrimary,
        marginLeft: theme.spacing.spacing16,
        flex: 1.0,
    },
    subTitle: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        color: theme.color.textPrimaryOp1,
        marginLeft: theme.spacing.spacing16,
    },
    rightArrow: {
        marginLeft: theme.spacing.spacing8,
    },
}));

const DrawerCategoryBtn: FC<Props> = (props) => {
    const {
        iconSrc,
        categoryTitle,
        to,
        className,
        subTitle,
        showRightArrow,
    } = props;
    // hooks
    const theme = useTheme();
    const classes = useStyles({ theme, ...props });

    // UI event function

    // logic function

    // render function

    const ButtonElement = to
        ? LocaleLink
        : (aProps: any) => <a {..._.omit(aProps, ['to'])}>{aProps.children}</a>;

    return (
        <ButtonElement
            {..._.omit(props, [
                'iconSrc',
                'categoryTitle',
                'subTitle',
                'showRightArrow',
                'normalTitle',
            ])}
            className={`${classes.btn} ${className}`}
        >
            {iconSrc ? <Image width="32" height="32" src={iconSrc} /> : null}
            <p className={classes.title}>{categoryTitle}</p>
            {subTitle != null && <p className={classes.subTitle}>{subTitle}</p>}
            {showRightArrow != null && showRightArrow === true && (
                <div className={classes.rightArrow}>
                    <Image
                        width="24"
                        height="24"
                        src={assetsPath('arrow-chevron-right')}
                    />
                </div>
            )}
        </ButtonElement>
    );
};

DrawerCategoryBtn.defaultProps = {
    showRightArrow: false,
    normalTitle: false,
};

export default DrawerCategoryBtn;
