export const openMap = (address: string) => {
    if (
        /* if we're on iOS, open in Apple Maps */
        navigator.platform.indexOf('iPhone') !== -1 ||
        navigator.platform.indexOf('iPad') !== -1 ||
        navigator.platform.indexOf('iPod') !== -1
    )
        window.open(`comgooglemaps://?saddr=${address}`);
    /* else use Google */ else
        window.open(`maps://maps.google.com/search/${address}`);
};
