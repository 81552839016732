export const i18nKeyMapping = (service: string) => {
    return {
        美容: 'beauty',
        美甲: 'nail-art',
        髮型: 'hair-style',
        按摩: 'massage',
        攝影: 'photography',
        其他: 'others',
    }[service];
};
