import React, { FC, HTMLAttributes, useState } from 'react';
import { createUseStyles, useTheme, DefaultTheme } from 'react-jss';
import { CategoryIndex } from 'apis/merchant-algolia.d';
import { CategoryButton, SnapScroll } from 'components';
import urlEncode from 'utils/url-encode';
import assetsPath from 'utils/assets-path';
import { useWindowWidth } from '@react-hook/window-size';
import _ from 'lodash';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useMediaQuery } from '@mui/material';

interface CategoryColumnViewProps extends HTMLAttributes<HTMLDivElement> {
    categories: CategoryIndex[];
}

type ScreenWidthProps = {
    screenWidth: number;
};

const useCategoryColumnViewStyles = createUseStyles((theme) => ({
    categoryBtn: {
        width: (props: ScreenWidthProps) => `${props.screenWidth * 0.24}px`,
    },
    categoryBtnContainer: {
        width: 88,
        marginRight: theme.spacing.spacing8,
        flexShrink: 0,
        scrollSnapAlign: 'start',
        scrollSnapStop: 'always',
    },
    topBtn: {
        marginBottom: theme.spacing.spacing8,
    },
    secColumns: {
        marginTop: theme.spacing.spacing4,
    },
}));

const CategoryColumnView: FC<CategoryColumnViewProps> = (props) => {
    const { categories } = props;
    // hooks
    const theme = useTheme<DefaultTheme>();
    const screenWidth = useWindowWidth();
    const screenWidthProps: ScreenWidthProps = {
        screenWidth,
    };
    const classes = useCategoryColumnViewStyles({ theme, ...screenWidthProps });
    const isMobile = useMediaQuery(
        `(max-width:${theme.breakpoints.tablet - 1}px)`,
    );
    const [isHideControlBtn, setIsHideControlBtn] = useState(true);

    // UI event function

    // logic function

    // render function
    const renderColumns = React.useCallback(() => {
        const columnsArray: JSX.Element[][] = [];

        let columns: JSX.Element[] = [];
        for (let i = 0; i < categories.length; i += 2) {
            const cat = categories[i];

            const catBtn = (
                <div
                    key={`cat-btn-${cat.objectID}`}
                    className={`column is-narrow`}
                >
                    <CategoryButton
                        to={`/${urlEncode(`${cat.parent}`)}/${urlEncode(
                            cat.objectID,
                        )}`}
                        className={`${classes.categoryBtn}`}
                        icon={cat.icon ? assetsPath(cat.icon) : undefined}
                        categoryTitle={cat.name}
                    />
                </div>
            );

            columns.push(catBtn);
        }
        columnsArray.push(columns);

        if (categories.length > 1) {
            columns = [];
            for (let i = 1; i < categories.length; i += 2) {
                const cat = categories[i];

                const catBtn = (
                    <div
                        key={`cat-btn-${cat.objectID}`}
                        className={`column is-narrow`}
                    >
                        <CategoryButton
                            to={`/${urlEncode(`${cat.parent}`)}/${urlEncode(
                                cat.objectID,
                            )}`}
                            className={`${classes.categoryBtn}`}
                            icon={cat.icon ? assetsPath(cat.icon) : undefined}
                            categoryTitle={cat.name}
                        />
                    </div>
                );

                columns.push(catBtn);
            }
            columnsArray.push(columns);
        }

        return columnsArray;
    }, [categories, classes.categoryBtn]);

    if (isMobile === true) {
        return (
            <div {..._.omit(props, 'categories', 'value', 'index')}>
                {renderColumns().map((columns, index) => {
                    const key = `mobile-cat-btn-${index}`;
                    return (
                        <div
                            className={`${index === 1 && classes.secColumns}`}
                            key={key}
                        >
                            <div
                                className={`columns is-mobile is-variable is-4`}
                            >
                                {columns.map((column) => {
                                    return column;
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
    return (
        <div
            {..._.omit(props, 'categories', 'value', 'index')}
            onMouseEnter={() => setIsHideControlBtn(false)}
            onMouseLeave={() => setIsHideControlBtn(true)}
        >
            <SnapScroll padding={0} hideControlBtn={isHideControlBtn}>
                {categories.map((cat) => {
                    return (
                        <div
                            className={classes.categoryBtnContainer}
                            key={`desktop-cat-btn-${cat.objectID}`}
                        >
                            <CategoryButton
                                to={`/${urlEncode(`${cat.parent}`)}/${urlEncode(
                                    cat.objectID,
                                )}`}
                                icon={
                                    cat.icon ? assetsPath(cat.icon) : undefined
                                }
                                categoryTitle={cat.name}
                            />
                        </div>
                    );
                })}
            </SnapScroll>
        </div>
    );
};

interface TabCategoryViewProps extends HTMLAttributes<HTMLDivElement> {
    categories: CategoryIndex[];
    padding: number;
    isFandB?: boolean;
    tabIndex?: number;
    onPressTab?: (index: number) => void;
}

const useTabCategoryViewStyles = createUseStyles((theme) => ({
    container: {
        paddingLeft: (props: TabCategoryViewProps) => props.padding,
        paddingRight: (props: TabCategoryViewProps) => props.padding,
        '&:focus': {
            outline: 'none',
        },
    },
    categoryView: {
        overflowX: 'scroll',
        overflowY: 'hidden',
        marginLeft: (props: TabCategoryViewProps) => -props.padding,
        marginRight: (props: TabCategoryViewProps) => -props.padding,
        paddingLeft: (props: TabCategoryViewProps) => props.padding,
        addingRight: (props: TabCategoryViewProps) => props.padding,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
            width: 0,
            height: 0,
        },
    },
    tabs: {
        height: 32,
        minHeight: [['0px'], '!important'],
        '& .MuiTabs-indicator': {
            backgroundColor: theme.color.bgPrimary,
            height: 4,
        },
        marginBottom: theme.spacing.spacing16,
    },
    tab: {
        minWidth: [['0px'], '!important'],
        minHeight: [['0px'], '!important'],
        marginRight: [[theme.spacing.spacing16], '!important'],
        paddingTop: [[0], '!important'],
        paddingBottom: [[theme.spacing.spacing4], '!important'],
        paddingLeft: [[0], '!important'],
        paddingRight: [[0], '!important'],
        fontFamily: [['inherit'], '!important'],
        fontSize: [[theme.fontSize.size18], '!important'],
        fontWeight: [[theme.fontWeight.bold], '!important'],
        textTransform: [['none'], '!important'],
        color: [[theme.color.textPrimary], '!important'],
        opacity: [[1.0], '!important'],
    },
}));

const a11yProps = (index: any) => {
    return {
        id: `cat-tab-${index}`,
    };
};

const TabCategoryView: FC<TabCategoryViewProps> = (props) => {
    const { categories, isFandB, tabIndex, onPressTab } = props;
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const classes = useTabCategoryViewStyles({ theme, ...props });
    const selectedIndex = tabIndex != null ? tabIndex : value;

    const topLayerCategories =
        isFandB === false
            ? categories.filter((cat) => cat.type === 'categories_1st')
            : categories.filter(
                  (cat) =>
                      cat.type === 'categories_2nd' && cat.parent === '餐飲',
              );

    return (
        <div
            className={classes.container}
            {..._.omit(props, 'categories', 'padding', 'isFandB', 'onPressTab')}
        >
            <Tabs
                className={classes.tabs}
                value={selectedIndex}
                onChange={(event, newValue) => {
                    if (tabIndex == null) {
                        setValue(newValue);
                    }

                    if (onPressTab != null) {
                        onPressTab(newValue);
                    }
                }}
            >
                {topLayerCategories.map((cat) => {
                    return (
                        <Tab
                            className={classes.tab}
                            label={cat.name}
                            key={`top-layer-cat-tab-${cat.objectID}`}
                            {...a11yProps(cat.objectID)}
                        />
                    );
                })}
            </Tabs>
            {topLayerCategories.map((cat, index) => {
                return (
                    <div
                        className={classes.categoryView}
                        role="tabpanel"
                        hidden={selectedIndex !== index}
                        id={`simple-tabpanel-${index}`}
                        key={`top-layer-tabpanel-${cat.objectID}`}
                    >
                        <CategoryColumnView
                            categories={
                                selectedIndex === index
                                    ? categories.filter(
                                          (c) => c.parent === cat.objectID,
                                      )
                                    : []
                            }
                        />
                    </div>
                );
            })}
        </div>
    );
};

TabCategoryView.defaultProps = {
    isFandB: false,
};

export default TabCategoryView;
