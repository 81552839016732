import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Image } from 'components';
import assetsPath from 'utils/assets-path';

const useStyles = createUseStyles((theme) => ({
    btn: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '&.full': {
            height: 40,
            paddingLeft: theme.spacing.spacing12,
            paddingRight: theme.spacing.spacing12,
            paddingTop: theme.spacing.spacing8,
            paddingBottom: theme.spacing.spacing8,
            '& .count': {
                marginLeft: theme.spacing.spacing4,
                fontSize: theme.fontSize.size16,
                lineHeight: 20,
                fontWeight: theme.fontWeight.bold,
                color: theme.color.textPrimary,
            },
        },
        '&.compact': {
            height: 24,
            paddingLeft: theme.spacing.spacing8,
            paddingRight: theme.spacing.spacing8,
            paddingTop: theme.spacing.spacing4,
            paddingBottom: theme.spacing.spacing4,
            '& .count': {
                marginLeft: theme.spacing.spacing4,
                fontSize: theme.fontSize.size12,
                lineHeight: theme.fontSize.size16,
                fontWeight: theme.fontWeight.bold,
                color: theme.color.textPrimary,
            },
        },
    },
}));

type Props = {
    onClicked?: (event: React.MouseEvent<any>) => void;
    size: 'full' | 'compact';
    isFavourited: boolean;
    numberOfFavourites: number;
};
const MerchantFavouriteButton = (props: Props) => {
    const classes = useStyles({ theme: useTheme() });
    const { onClicked, size, isFavourited, numberOfFavourites } = props;
    return (
        <a
            className={`${classes.btn} ${size}`}
            onClick={(event) => {
                if (onClicked) {
                    onClicked(event);
                }

                event.stopPropagation();
            }}
            role="button"
            tabIndex={0}
        >
            <Image
                width="32"
                height="32"
                src={assetsPath(
                    isFavourited ? 'heart-button-on' : 'heart-button-off',
                )}
            />
            {/* {numberOfFavourites > 0 ? (
                <span className="count">{numberOfFavourites}</span>
            ) : null} */}
        </a>
    );
};

export default MerchantFavouriteButton;
