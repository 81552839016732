import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Image } from 'components';
import { useTranslation } from 'react-i18next';
import EmptyFavImg from 'assets/empty-fav.svg';

type Props = {
    className?: string;
};

const useStyles = createUseStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        fontWeight: theme.fontWeight.bold,
        color: theme.color.textPrimary,
        marginBottom: theme.spacing.spacing16,
        marginTop: theme.spacing.spacing20,
    },
    subTitle: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        color: theme.color.textPrimaryOp1,
    },
}));

const FavEmpty: FC<Props> = (props) => {
    const { className } = props;
    // hooks
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { t } = useTranslation();

    // UI event function

    // logic function

    // render function

    return (
        <div
            className={`${classes.container} ${
                className != null ? className : ''
            }`}
        >
            <Image src={EmptyFavImg} width="122" height="122" />
            <p className={classes.title}>{t('profile.fav-empty-title')}</p>
            <p className={classes.subTitle}>
                {t('profile.fav-empty-subtitle')}
            </p>
        </div>
    );
};

export default FavEmpty;
