import React, { FC } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Image } from 'components';
import assetsPath from 'utils/assets-path';
import { useTranslation } from 'react-i18next';

type Props = {
    className?: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
};

const useStyles = createUseStyles((theme) => ({
    btn: {
        width: '100%',
        height: 40,
        backgroundColor: theme.color.bgPrimaryOp5,
        border: `1px solid ${theme.color.borderPrimaryOp4}`,
        borderRadius: '800px',
        display: 'flex',
        paddingLeft: theme.spacing.spacing16,
        justifyContent: 'flex-start',
        alignItems: 'center',
        outline: 'none',
    },
    title: {
        fontSize: theme.fontSize.size16,
        lineHeight: theme.lineHeight.size24,
        color: theme.color.textPrimaryOp1,
        marginLeft: theme.spacing.spacing4,
        flex: 1,
    },
    icon: {
        background: theme.color.bgThird,
        borderTopRightRadius: '800px',
        borderBottomRightRadius: '800px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 56,
    },
}));

const SearchPlaceholderButton: FC<Props> = (props) => {
    const { className, onClick } = props;
    // hooks
    const theme = useTheme();
    const classes = useStyles({ theme });
    const { t } = useTranslation();

    // UI event function

    // logic function

    // render function

    return (
        <a
            onClick={onClick}
            role="button"
            tabIndex={0}
            className={`${classes.btn} ${className}`}
        >
            <p className={classes.title}>{t('home.search-btn-title')}</p>
            <div className={classes.icon}>
                <Image src={assetsPath('magnifier')} width="24" height="24" />
            </div>
        </a>
    );
};

export default SearchPlaceholderButton;
