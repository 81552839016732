import algoliasearch from 'algoliasearch';
import * as _ from 'lodash';
import type {
    SearchParams,
    MerchantIndex,
    CategoryIndex,
    LocationIndex,
    PaymentMethod,
    TakeawayMethod,
    DoorToDoorService,
} from './merchant-algolia.d';

const searchClient = algoliasearch(
    `${process.env.REACT_APP_ALGOLIA_APP_ID || (window as any).ALGOLIA_APP_ID}`,
    `${
        process.env.REACT_APP_ALGOLIA_MERCHANT_API_KEY ||
        (window as any).ALGOLIA_MERCHANT_API_KEY
    }`,
);

const MERCHANT_HIGHLIGHT_ATTRS = [
    'categories_1st',
    'categories_2nd',
    'categories_3rd',
    'description',
    'districts',
    'keywords',
    'name',
    'regions',
    'tags',
];

const newestMerchantIndex = searchClient.initIndex(
    `${process.env.REACT_APP_ALGOLIA_MERCHANT_INDEX_NAME}`,
);
const hottestMerchantIndex = searchClient.initIndex(
    `${process.env.REACT_APP_ALGOLIA_MERCHANT_INDEX_NAME}_hottest`,
);

const merchantIndexMap = {
    newest: newestMerchantIndex,
    hottest: hottestMerchantIndex,
};

export type GetSearchGroupByFacetFilterParam = {
    query?: string;
    facetFilters: string[];
    page?: number;
    hitsPerPage?: number;
};

export const getMerchantsGroupByFacetFilter = ({
    query = '',
    facetFilters = [],
    page = 0,
    hitsPerPage = 10,
}: GetSearchGroupByFacetFilterParam) => {
    return searchClient
        .multipleQueries(
            facetFilters.map((facetFilter) => ({
                indexName: `${process.env.REACT_APP_ALGOLIA_MERCHANT_INDEX_NAME}`,
                query: _.trim(query),
                params: {
                    page,
                    hitsPerPage,
                    attributesToHighlight: MERCHANT_HIGHLIGHT_ATTRS,
                    clickAnalytics: true,
                    facetFilters: [facetFilter],
                },
            })),
        )
        .then((result) => {
            return facetFilters.reduce(
                (
                    acc: {
                        [key: string]: {
                            hits: MerchantIndex[];
                            nbHits: number;
                            page: number;
                            nbPages: number;
                            hitsPerPage: number;
                        };
                    },
                    facet,
                    idx,
                ) => {
                    acc[facet] = _.pick(result.results[idx], [
                        'hits',
                        'nbHits',
                        'page',
                        'nbPages',
                        'hitsPerPage',
                    ]) as any;
                    return acc;
                },
                {},
            );
        });
};

export const getMerchantByObjectIds = async (
    objectIDs: string[],
    { page, hitsPerPage }: { page: number; hitsPerPage: number } = {
        page: 0,
        hitsPerPage: 10,
    },
) => {
    if (objectIDs.length === 0) {
        return Promise.resolve([]);
    }
    return newestMerchantIndex
        .search('', {
            getRankingInfo: true,
            analytics: false,
            enableABTest: false,
            hitsPerPage,
            enableRules: false,
            facetFilters: [objectIDs.map((oid) => `objectID:${oid}`)],
            page,
            facets: ['*'],
        })
        .then((r) => r.hits as MerchantIndex[]);
};

export const getMerchants = async (
    {
        query,
        page = 0,
        categories = [],
        locations = [],
        tags = [],
        consumptionVoucherMethod = [],
        takeawayMethod = [],
        doorToDoorService = [],
        sorting = 'newest',
    }: SearchParams,
    objectIds?: string[],
) => {
    const params = {
        hitsPerPage: 12,
        page,
    };

    const facetFilters = _.concat(
        generateFacetFilters({
            categories,
            locations,
            tags,
            consumptionVoucherMethod,
            takeawayMethod,
            doorToDoorService,
        }),
        objectIds ? [objectIds.map((oid) => `objectID:${oid}`)] : [],
    ).filter((x) => !_.isEmpty(x));

    return merchantIndexMap[sorting]
        .search(_.trim(query) || '', {
            ...params,
            attributesToHighlight: MERCHANT_HIGHLIGHT_ATTRS,
            facetFilters,
            clickAnalytics: true,
        })
        .then((r) => {
            return {
                queryId: r.queryID,
                merchants: r.hits as MerchantIndex[],
                merchantState: {
                    totalHits: r.nbHits,
                    page: r.page,
                    totalPages: r.nbPages,
                    hitsPerPage: r.hitsPerPage,
                },
            };
        });
};

export const getSearchResultSet = async ({
    query,
    page = 0,
    categories = [],
    locations = [],
    tags = [],
}: SearchParams) => {
    const params = {
        hitsPerPage: 3,
        page,
    };

    const result = await searchClient.multipleQueries([
        {
            indexName: `${process.env.REACT_APP_ALGOLIA_MERCHANT_INDEX_NAME}`,
            query: _.trim(query),
            params: {
                ...params,
                attributesToHighlight: MERCHANT_HIGHLIGHT_ATTRS,
                clickAnalytics: true,
                facetFilters: generateFacetFilters({
                    categories,
                    locations,
                    tags,
                }),
            },
        },
        {
            indexName: `${process.env.REACT_APP_ALGOLIA_LOCATION_INDEX_NAME}`,
            query: _.trim(query),
            params: {
                ...params,
                attributesToHighlight: ['name'],
            },
        },
        {
            indexName: `${process.env.REACT_APP_ALGOLIA_CATEGORY_INDEX_NAME}`,
            query: _.trim(query),
            params: {
                ...params,
                attributesToHighlight: ['name'],
                facetFilters: generateFacetFilters({
                    categories,
                    locations: [],
                    tags: [],
                }),
            },
        },
    ]);
    const merchantResult = result.results[0];
    return {
        merchants: result.results[0].hits as MerchantIndex[],
        locations: result.results[1].hits as LocationIndex[],
        categories: result.results[2].hits as CategoryIndex[],
        queryId: merchantResult.queryID,
        merchantState: {
            totalHits: merchantResult.nbHits,
            page: merchantResult.page,
            totalPages: merchantResult.nbPages,
            hitsPerPage: merchantResult.hitsPerPage,
        },
    };
};

function generateFacetFilters({
    categories = [],
    locations = [],
    tags = [],
    consumptionVoucherMethod = [],
    takeawayMethod = [],
    doorToDoorService = [],
}: {
    categories: string[];
    locations: string[];
    tags: string[];
    consumptionVoucherMethod?: PaymentMethod[];
    takeawayMethod?: TakeawayMethod[];
    doorToDoorService?: DoorToDoorService[];
}) {
    return [
        _.flatMap(categories, (c) => [
            `categories_1st:${c}`,
            `categories_2nd:${c}`,
            `categories_3rd:${c}`,
        ]),
        _.flatMap(locations, (l) => [`areas:${l}`, `districts:${l}`]),
        _.flatMap(tags, (t) => [`tags:${t}`]),
        _.flatMap(consumptionVoucherMethod, (m) => [`payment_methods:${m}`]),
        _.flatMap(takeawayMethod, (m) => [`takeaway_methods:${m}`]),
        _.flatMap(doorToDoorService, (m) => [`door_to_door_services:${m}`]),
    ];
    // .concat([]);
}
